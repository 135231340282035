import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Collapse,
  Chip,
  Button,
  DialogActions,
  ButtonGroup,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { ExpandLess, ExpandMore, SatelliteAlt } from "@mui/icons-material";
import { Colors } from "@template/style";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import SelectLabel from "components/atoms/SelectLabel";
import DateRangePicker from "components/atoms/DatePickerCustom/DateRangePicker";
import TimeRangePicker from "components/atoms/TimePickerCustom/TimeRangePicker";
import {
  getAllReportItem,
  getFuelAll,
  getListMaster,
  getListReportType,
} from "@api/itemReport";
import { apiGetAprrovalList } from "@api/flowApproval";
import {
  LIST_FUEL,
  LIST_WORK_STATUS_TLOG,
  screenIdSupport,
  TEMPLATE_EDIT_TYPE,
  IMAGE_EXIST_TYPE,
  LEDGER_TYPE,
} from "@shared-constants";
import {
  getListContainerApi,
  getListContainerDefectApi,
} from "@api/itemReport";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import formatDateToString from "@utils/DateFormat";
import { Validation } from "@validation";
import { useSelector } from "react-redux";
import { getProjectLocationIdStore } from "selector/projectSelector";
import SelectDialog from "./SelectDialog";
import { cloneDeep } from "lodash";
import ModalSearchUserByLocation from "components/molecules/ModalSearchUserByLocation";
import { issueType } from "@utils/template/riskyId";
import { getListProject } from "@api/project";
import LabelRequired from "components/atoms/LabelRequired";
import DatePickerCustom from "components/atoms/DatePickerCustom";
import {
  getM1,
  getM2,
  getM4,
  getM5,
  getM6,
  getM7,
  getM8,
  getM9,
  getM10,
  getM11,
  getM12,
  getM15,
  getM17,
  getM18,
} from "@api/master";
import { getUserInfo } from "@utils/index";
import { getListUserByLocationId } from "@api/User";
import { getListTemplate } from "@api/template";
import TimePickerCustom from "components/atoms/TimePickerCustom";
import { getListTlgtMaster } from "@api/settings";
import SearchDialogDetail from "./SearchDialogDetail";
import { IExcelTemplateSearchForm } from "./SearchDialogDetail/ExcelTemplateDetail";

interface IProps {
  open: boolean;
  data?: IWorkSearchForm;
  screenId: string;
  onGetTemplateInfo: Function;
  onClose: Function;
  onSearch: Function;
}

interface Imultiple {
  id: string;
  name: string;
}

interface IworkStatus {
  id: string;
  name: string;
  isSelected: boolean;
}

interface IDateTime {
  time_ac: Date | null;
  returning_time: Date | null;
}

export interface IWorkSearchForm {
  report_type: string;
  report_type_name: string;
  project_id: string;
  project_id_name: string;
  applied_at_s: string;
  applied_at_e: string;
  approved_at_s: string;
  approved_at_e: string;
  updated_at_s: string;
  updated_at_e: string;
  work_status: Array<IworkStatus>;
  approver: Array<Imultiple>;
  author: Array<Imultiple>;
  template_id: string;
  approval_flow: string;
  approval_flow_name: string;
  next_user: string;
  image_exist: string;
  // 庫内商品事故報告書項目
  place_found_issue: string;
  incident_date_s: string;
  incident_date_e: string;
  issue_place_floor: Array<Imultiple>;
  issue_place_span: Array<Imultiple>;
  is_product_issue: boolean;
  is_packaging_issue: boolean;
  responsible_person: string;
  person_found_issue: string;
  product_type: string;
  product_name: string;
  amount_of_product: string;
  product_id: string;
  product_packaging_status: string;
  reason_cause_issue: string;
  warehouse_place_floor: Array<Imultiple>;
  warehouse_place_span: Array<Imultiple>;
  bad_place: string;
  // 日次フォークリフト点検簿
  vehicles_frame_no: string;
  vehicles_workplace: string;
  vehicles_model: string;
  vehicles_fuel_type: string;
  vehicles_name: string;
  driver: string;
  year_month: string;
  check_user: string;
  memo: string;
  //InspectionReport
  date_of_delivery_s: string;
  date_of_delivery_e: string;
  container_no: string;
  container_size: string;
  delivery_time: string;
  carrier_name: string;
  unit_class: string;
  delivery_location: string;
  invoice_no: string;
  seal_no: string;
  berth_number: string;
  sealed: boolean | undefined;
  product_class: string;
  model_name: string;
  defective_products: Array<Imultiple>;
  person_worker: string;
  container_status: Array<Imultiple>;
  // LoadingReport
  loading_date: string;
  warehouse: string;
  load_by: string;
  invoice_no_lr: string;
  booking_no: string;
  commodity: string;
  container_type: string;
  container_no_lr: string;
  seal_no_lr: string;
  remark: string;
  model_name_lr: string;
  qty: string;
  total_qty: string;
  reserve_status: boolean | undefined;
  //NGR(Incident Damage Report)
  ngr_date_s: string;
  ngr_date_e: string;
  ngr_time_s: string;
  ngr_time_e: string;
  ngr_warehouse: Array<Imultiple>;
  ngr_area: Array<Imultiple>;
  ngr_reported_by: string;
  ngr_customer: Array<Imultiple>;
  ngr_commodity: Array<Imultiple>;
  ngr_model_name: string;
  ngr_quantity: string;
  ngr_serial_no: string;
  ngr_damage_location: Array<Imultiple>;
  ngr_cause: string;
  ngr_qa_authorizer: Array<Imultiple>;
  ngr_occurred_company: Array<Imultiple>;
  ngr_invoice_no: string;
  ngr_affiliation_of_employee: Array<Imultiple>;
  ngr_experience_of_employee_years: string;
  ngr_experience_of_employee_months: string;
  ngr_system_transaction_no: string;
  ngr_waste_claim_category: Array<Imultiple>;
  ngr_accident_rank: Array<Imultiple>;
  ngr_cpar_no: string;
  ngr_nonconformity_detail: Array<Imultiple>;
  ngr_nonconformity_overview: string;
  ngr_occurance_process: string;
  ngr_process_of_root_cause: string;
  ngr_responsibility: string;
  ngr_department_of_pic: string;
  ngr_process_in_logistics: string;
  ngr_responsibility_in_process: string;
  ngr_classification_of_nonconformity: string;
  ngr_classification_of_cause: string;
  ngr_cause_overview: string;
  ngr_root_cause: string;
  ngr_logistics_equipments: Array<Imultiple>;
  ngr_corrective_action_improvement: string;
  ngr_completion_improvement_date_s: string;
  ngr_completion_improvement_date_e: string;
  ngr_scrap_unit: string;
  ngr_repair_unit: string;
  ngr_no_problem_unit: string;
  ngr_final_payer: Array<Imultiple>;
  ngr_expected_payment_month: Array<Imultiple>;
  ngr_est_acc: Array<Imultiple>;
  ngr_amount_cost_est: string;
  ngr_closing_date_s: string;
  ngr_closing_date_e: string;
  ngr_amount_cost_act: string;
  ngr_actual_payment_month: Array<Imultiple>;
  ngr_insurance_sub_con_amount: string;
  ngr_received_invoice_no: string;
  ngr_remark: string;
  ngr_final_tlgt_amount: string;
  // AC Receiving Problem Tag
  date_ac: string;
  time_ac: string;
  customer_ac: string;
  commodity_ac: string;
  model_name_ac: string;
  quantity_ac: string;
  tl_serial_no: string;
  reported_by: string;
  defect: string;
  damage_location_ac: string;
  ctc_qa_judgement: string;
  ctc_qa_inspector_by: string;
  ctc_checked_date: string;
  ctc_ma_repair_by: string;
  ctc_ma_inspector_by: string;
  ctc_inspection_date: string;
  repaired_date: string;
  repaired_ac_receive_by: string;
  returning_date: string;
  returning_time: string;
  // 外装破損商品連絡書
  edr_ledger_type: string | undefined;
  edr_date_s: string;
  edr_date_e: string;
  edr_arrival_date_s: string;
  edr_arrival_date_e: string;
  edr_location: string;
  edr_written_by: string;
  edr_checked_by: string;
  edr_jan_code: string;
  edr_supplier_code: string;
  edr_supplier_name: string;
  edr_product_name: string;
  edr_product_type: string;
  edr_total: string;
  edr_status: string;
  edr_purchasing: boolean | undefined;
  edr_order_customer_count: string;
  edr_order_replenishment_count: string;
  edr_non_purchasing: boolean | undefined;
  edr_non_purchasing_count: string;
  edr_item_check_user: string;
  edr_store_check_user: string;
  // ユーザー作成帳票
  attached_file_exist: string;
  excel_template: IExcelTemplateSearchForm;
}

let LIST_WORK_STATUS = [...LIST_WORK_STATUS_TLOG];
LIST_WORK_STATUS[0].isSelected = false;

export const initialStateForm: IWorkSearchForm = {
  report_type: "",
  report_type_name: "",
  project_id: "",
  project_id_name: "",
  applied_at_s: "",
  applied_at_e: "",
  approved_at_s: "",
  approved_at_e: "",
  updated_at_s: "",
  updated_at_e: "",
  work_status: cloneDeep(LIST_WORK_STATUS),
  approver: [],
  author: [],
  template_id: "",
  approval_flow: "",
  approval_flow_name: "",
  next_user: "",
  image_exist: IMAGE_EXIST_TYPE.not_designation, // 写真添付有無 初期値: 指定なし
  place_found_issue: "",
  incident_date_s: "",
  incident_date_e: "",
  issue_place_floor: [],
  issue_place_span: [],
  is_product_issue: false,
  is_packaging_issue: false,
  responsible_person: "",
  person_found_issue: "",
  product_type: "",
  product_name: "",
  amount_of_product: "",
  product_id: "",
  product_packaging_status: "",
  reason_cause_issue: "",
  warehouse_place_floor: [],
  warehouse_place_span: [],
  bad_place: "",
  vehicles_frame_no: "",
  vehicles_workplace: "",
  vehicles_model: "",
  vehicles_fuel_type: "",
  vehicles_name: "",
  driver: "",
  year_month: "",
  check_user: "",
  memo: "",
  date_of_delivery_s: "",
  date_of_delivery_e: "",
  container_no: "",
  container_size: "",
  delivery_time: "",
  carrier_name: "",
  unit_class: "",
  delivery_location: "",
  invoice_no: "",
  seal_no: "",
  berth_number: "",
  sealed: undefined,
  product_class: "",
  model_name: "",
  defective_products: [],
  person_worker: "",
  container_status: [],
  loading_date: "",
  warehouse: "",
  load_by: "",
  invoice_no_lr: "",
  booking_no: "",
  commodity: "",
  container_type: "",
  container_no_lr: "",
  seal_no_lr: "",
  remark: "",
  model_name_lr: "",
  qty: "",
  total_qty: "",
  reserve_status: undefined,
  ngr_date_s: "",
  ngr_date_e: "",
  ngr_time_s: "",
  ngr_time_e: "",
  ngr_warehouse: [],
  ngr_area: [],
  ngr_reported_by: "",
  ngr_customer: [],
  ngr_commodity: [],
  ngr_model_name: "",
  ngr_quantity: "",
  ngr_serial_no: "",
  ngr_damage_location: [],
  ngr_cause: "",
  ngr_qa_authorizer: [],
  ngr_occurred_company: [],
  ngr_invoice_no: "",
  ngr_experience_of_employee_years: "",
  ngr_experience_of_employee_months: "",
  ngr_affiliation_of_employee: [],
  ngr_system_transaction_no: "",
  ngr_waste_claim_category: [],
  ngr_accident_rank: [],
  ngr_cpar_no: "",
  ngr_nonconformity_detail: [],
  ngr_nonconformity_overview: "",
  ngr_occurance_process: "",
  ngr_process_of_root_cause: "",
  ngr_responsibility: "",
  ngr_department_of_pic: "",
  ngr_process_in_logistics: "",
  ngr_responsibility_in_process: "",
  ngr_classification_of_nonconformity: "",
  ngr_classification_of_cause: "",
  ngr_cause_overview: "",
  ngr_root_cause: "",
  ngr_logistics_equipments: [],
  ngr_corrective_action_improvement: "",
  ngr_completion_improvement_date_s: "",
  ngr_completion_improvement_date_e: "",
  ngr_scrap_unit: "",
  ngr_repair_unit: "",
  ngr_no_problem_unit: "",
  ngr_final_payer: [],
  ngr_expected_payment_month: [],
  ngr_est_acc: [],
  ngr_amount_cost_est: "",
  ngr_closing_date_s: "",
  ngr_closing_date_e: "",
  ngr_amount_cost_act: "",
  ngr_actual_payment_month: [],
  ngr_insurance_sub_con_amount: "",
  ngr_received_invoice_no: "",
  ngr_remark: "",
  ngr_final_tlgt_amount: "",
  date_ac: "",
  time_ac: "",
  customer_ac: "",
  commodity_ac: "",
  model_name_ac: "",
  quantity_ac: "",
  tl_serial_no: "",
  reported_by: "",
  defect: "",
  damage_location_ac: "",
  ctc_qa_judgement: "",
  ctc_qa_inspector_by: "",
  ctc_checked_date: "",
  ctc_ma_repair_by: "",
  ctc_ma_inspector_by: "",
  ctc_inspection_date: "",
  repaired_date: "",
  repaired_ac_receive_by: "",
  returning_date: "",
  returning_time: "",
  edr_ledger_type: undefined,
  edr_date_s: "",
  edr_date_e: "",
  edr_arrival_date_s: "",
  edr_arrival_date_e: "",
  edr_location: "",
  edr_written_by: "",
  edr_checked_by: "",
  edr_jan_code: "",
  edr_supplier_code: "",
  edr_supplier_name: "",
  edr_product_name: "",
  edr_product_type: "",
  edr_total: "",
  edr_status: "",
  edr_purchasing: undefined,
  edr_order_customer_count: "",
  edr_order_replenishment_count: "",
  edr_non_purchasing: undefined,
  edr_non_purchasing_count: "",
  edr_item_check_user: "",
  edr_store_check_user: "",
  attached_file_exist: IMAGE_EXIST_TYPE.not_designation, // ファイル添付有無 初期値: 指定なし
  excel_template: {},
};

const SearchDialog: React.FC<IProps> = ({
  open,
  data,
  screenId,
  onGetTemplateInfo = () => {},
  onClose = () => {},
  onSearch = () => {},
}: IProps) => {
  const user_info = getUserInfo();
  const yyyyMMddString = formatDateToString(new Date(), "YMD") + " ";
  //1～12月
  const twelveMonths = Array.from({ length: 12 }, (_, index) => ({
    SK: String(index + 1),
    name: String(index + 1),
  }));

  //勤続年数 0～50　未選択がSK=0になるため、重複防止でSK+1する
  const ExperienceYearsOfEmployee = Array.from({ length: 51 }, (_, index) => ({
    SK: String(index + 1),
    name: String(index),
  }));

  //勤続月数 0～11 未選択がSK=0になるため、重複防止でSK+1する
  const ExperienceMonthsOfEmployee = Array.from({ length: 12 }, (_, index) => ({
    SK: String(index + 1),
    name: String(index),
  }));

  const [fetchData, setFetchData] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [listReportType, setListReportType] = useState<any[]>([]);
  const [listAllProject, setListAllProject] = useState<any[]>([]);
  const [listProject, setListProject] = useState<any[]>([]);
  const [listTemplate, setListTemplate] = useState<any[]>([]);
  const [approvalFlowList, setApprovalFlowList] = useState<Type.FlowInfo[]>([]);
  const [allFlowList, setAllFlowList] = useState<{
    [key: string]: Type.FlowInfo[];
  }>({});
  const [listOccurrenceLocation, setListOccurrenceLocation] = useState<any[]>(
    [],
  );
  const [listContainerStatus, setContainerStatus] = useState<any[]>([]);
  const [defectList, setDefectList] = useState<any[]>([]);
  const [listSpanMaster, setListSpanMaster] = useState<any[]>([]);
  const [listWorkplace, setListWorkplace] = useState<any[]>([]);
  const [listFuel, setListFuel] = useState<any[]>([]);
  const [listUser, setListUser] = useState<any[]>([]);
  const [openSelectDialog, setOpenSelectDialog] = useState(false);
  const [selectDialogField, setSelectDialogField] =
    useState<keyof IWorkSearchForm>();
  const [selectDialiogData, setSelectDialiogData] = useState<any[]>([]);
  const [currentData, setCurrentData] = useState<any>([]);
  const [nameAttribute, setNameAttribute] = useState<string>("");
  const [openMemberSearch, setOpenMemberSearch] = useState(false);
  const [stateForm, setStateForm] = useState<IWorkSearchForm>(
    cloneDeep(initialStateForm),
  );
  const [listWarehouse, setListWarehouse] = useState<any>([]);
  const [listArea, setListArea] = useState<any>([]);
  const [listCustomer, setListCustomer] = useState<any>([]);
  const [listCommodity, setListCommodity] = useState<any>([]);
  const [listDamageLocation, setListDamageLocation] = useState<any>([]);
  const [listCause, setListCause] = useState<any>([]);
  const [listQAAuthorizer, setListQAAuthorizer] = useState<any>([]);
  const [listOccurredCompany, setListOccurredCompany] = useState<any>([]);
  const [listAffiliationOfEmployee, setListAffiliationOfEmployee] =
    useState<any>([]);
  const [listWasteClaim, setListWasteClaim] = useState<any>([]);
  const [listAccidentRank, setListAccidentRank] = useState<any>([]);
  const [listNonconformityDetail, setListNonconformityDetail] = useState<any>(
    [],
  );
  const [listLogisticsEquipments, setListLogisticsEquipments] = useState<any>(
    [],
  );
  const [listFinalPayer, setListFinalPayer] = useState<any>([]);
  const [listEstAcc, setListEstAcc] = useState<any>([]);

  const [formError, setFormError] = useState<IWorkSearchForm>(
    cloneDeep(initialStateForm),
  );
  const location_id = useSelector(getProjectLocationIdStore);
  const ListDetailTitle = {
    [TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT]: "ユーザー作成帳票",
  };

  const [dateStartTime, setDateStartTime] = useState<Date | null>(null);
  const [dateEndTime, setDateEndTime] = useState<Date | null>(null);
  const [dateTime, setDateTime] = useState<IDateTime>({
    time_ac: null,
    returning_time: null,
  });
  const [defaultCalendarMonth, setDefaultCalendarMonth] = useState<any>();

  useEffect(() => {
    if (!fetchData) {
      getListData();
      setFetchData(true);
    }
  }, [data, fetchData]);

  useEffect(() => {
    setStateForm(data ?? cloneDeep(initialStateForm));
  }, [data]);

  const getListData = useCallback(async () => {
    try {
      LoadingOverlayController.show();
      // 帳票
      // const res_project = await getListProjectData();
      // if (res_project && res_project.length > 0) {
      //   setListAllProject(() => res_project);
      // }

      // 帳票種類
      const res_report = await getListReportType();
      // if (res_report?.data) {
      //   if (res_project && res_project.length > 0) {
      //     // 帳票が存在しない帳票種類を除く
      //     const list_report: any[] = [];
      //     res_report.data.filter((item: any) => {
      //       if (filterListProject(item.SK, res_project)?.length > 0) {
      //         list_report.push(item);
      //       }
      //     });
      //     setListReportType(list_report);
      //   } else {
      //     setListReportType(res_report.data);
      //   }
      // }
      setListReportType(res_report.data);

      // アカウント
      // const res_users = await getMemberListApi();
      // setListUser(res_users?.users.data);
      const res_users = await getListUserByLocationId();
      if (res_users && res_users.length > 0) {
        setListUser(res_users);
      }

      // テンプレート
      const res_template = await getListTemplate({
        template_type: "TEMPLATE_WORK",
      });
      if (res_template.data && res_template.data.length > 0) {
        setListTemplate(res_template.data);
      }
    } finally {
      LoadingOverlayController.hide();
    }
  }, [location_id]);

  const getListProjectData = async (
    lastKeyword?: string,
    sort_by?: string,
    data?: any,
    report_type_id?: string,
  ) => {
    const params = {
      keyword: "",
      sort_type: "update_time_latest_sort",
      last_key: lastKeyword ?? "",
      sort_by: sort_by,
      not_ignore_user_made: true,
      report_type_id: report_type_id,
    };

    const res = await getListProject(params);
    let list_project = res?.data;
    if (data) {
      list_project = [...data, ...res?.data];
    }
    if (res?.last_key) {
      list_project = await getListProjectData(
        res?.last_key?.SK,
        res?.last_key?.sort_by,
        list_project,
        report_type_id,
      );
    }

    return list_project;
  };

  const getFlowList = useCallback(
    async (template_id: string) => {
      if (template_id) {
        if (Object.keys(allFlowList).find((v) => v == template_id)) {
          setApprovalFlowList(allFlowList[template_id]);
        } else {
          try {
            LoadingOverlayController.show();
            let flow_type = "";
            const approvalFlows = await apiGetAprrovalList({
              template_id,
              flow_type,
            });
            if (approvalFlows.length > 0) {
              setApprovalFlowList(approvalFlows);
              setAllFlowList({ ...allFlowList, [template_id]: approvalFlows });
            } else {
              setApprovalFlowList([]);
              setAllFlowList({ ...allFlowList, [template_id]: [] });
            }
          } finally {
            LoadingOverlayController.hide();
          }
        }
      }
    },
    [allFlowList],
  );

  useEffect(() => {
    getMasterByScreenId();
  }, [screenId]);

  const getMasterByScreenId = async () => {
    switch (screenIdSupport[screenId]) {
      case TEMPLATE_EDIT_TYPE.RISKY_REPORT:
        if (listOccurrenceLocation.length == 0) await getRiskyReportMaster();
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT:
        if (listFuel.length == 0) await getTemplateForkliftMaster();
        break;
      case TEMPLATE_EDIT_TYPE.INSPECTION_REPORT:
        if (listContainerStatus.length == 0) await getInspectionReportMaster();
        break;
      case TEMPLATE_EDIT_TYPE.TEMPLATE_INCIDENT_DAMAGE_REPORT:
        if (listWarehouse.length == 0) await getNGRMaster();
        break;
    }
  };

  const getRiskyReportMaster = async () => {
    try {
      LoadingOverlayController.show();
      // フロア、スパン
      const res_report_item = await getAllReportItem(location_id, {
        master: JSON.stringify(["occurrenceLocation", "spanMaster"]),
      });
      setListOccurrenceLocation(res_report_item["occurrenceLocation"]);
      setListSpanMaster(res_report_item["spanMaster"]);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getTemplateForkliftMaster = async () => {
    try {
      LoadingOverlayController.show();
      // 燃料
      await getFuelAll().then((res) => {
        if (res?.data) {
          setListFuel(res.data.length > 0 ? res.data : LIST_FUEL);
        }
      });

      // 使用場所
      await getListMaster("workplace", location_id).then((res) => {
        if (res?.data) {
          setListWorkplace(res.data);
        }
      });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getInspectionReportMaster = async () => {
    try {
      LoadingOverlayController.show();
      //コンテナの状態:InspectionReport
      const res_container = await getListContainerApi();
      if (res_container?.data) {
        setContainerStatus(res_container?.data);
      }
      //不適合内容:InspectionReport
      const res_defect = await getListContainerDefectApi();
      if (res_defect?.data) {
        setDefectList(res_defect?.data);
      }
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getNGRMaster = async () => {
    try {
      LoadingOverlayController.show();
      //Warehouse
      const res_warehouse = await getM1();
      if (res_warehouse?.data) {
        setListWarehouse(res_warehouse?.data);
      }

      //Area
      const res_area = await getM2();
      if (res_area?.data) {
        setListArea(res_area?.data);
      }

      //Customer (Shipper)
      const res_customer = await getM4();
      if (res_customer?.data) {
        setListCustomer(res_customer?.data);
      }

      //Commodity
      const res_commodity = await getM5();
      if (res_commodity?.data) {
        setListCommodity(res_commodity?.data);
      }

      //Damage Location
      const res_damage_location = await getM6();
      if (res_damage_location?.data) {
        setListDamageLocation(res_damage_location?.data);
      }

      //Cause (Site Opinion)
      const res_cause = await getM7();
      if (res_cause?.data) {
        setListCause(res_cause?.data);
      }

      //QA Authorizer
      const res_qa_authorizer = await getM8();
      if (res_qa_authorizer?.data) {
        setListQAAuthorizer(res_qa_authorizer?.data);
      }

      //Occurred Company
      const res_occurred_company = await getM9();
      if (res_occurred_company?.data) {
        setListOccurredCompany(res_occurred_company?.data);
      }

      //Employee's Affiliation
      const res_affiliation_of_employee = await getM10();
      if (res_affiliation_of_employee?.data) {
        setListAffiliationOfEmployee(res_affiliation_of_employee?.data);
      }
      //Waste /Claim
      const res_waste_claim = await getM11();
      if (res_waste_claim?.data) {
        setListWasteClaim(res_waste_claim?.data);
      }

      //Accident Rank
      const res_accident_rank = await getM12();
      if (res_accident_rank?.data) {
        setListAccidentRank(res_accident_rank?.data);
      }

      //Nonconformity Detail
      const res_nonconformity_detail = await getListTlgtMaster(
        "nonconformity_detail",
      );
      if (res_nonconformity_detail?.data) {
        setListNonconformityDetail(res_nonconformity_detail?.data);
      }

      //Logistics Equipments
      const res_logistics_equipments = await getM15();
      if (res_logistics_equipments?.data) {
        setListLogisticsEquipments(res_logistics_equipments?.data);
      }

      //Final Payer
      const res_final_payer = await getM17();
      if (res_final_payer?.data) {
        setListFinalPayer(res_final_payer?.data);
      }

      //Est/Acc
      const res_est_acc = await getM18();
      if (res_est_acc?.data) {
        setListEstAcc(res_est_acc?.data);
      }
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const StackChip = (props: { children?: React.ReactNode }) => {
    return (
      <Stack direction={"row"} sx={{ gap: 1, flexWrap: "wrap" }} spacing={0}>
        {props.children}
      </Stack>
    );
  };

  const renderMenuItem = useCallback(
    (
      list: Array<any>,
      name?: string,
      getName: (item: any) => string = () => "",
      addEmpty: boolean = true,
      value: string = "SK",
      key: string = "SK",
    ) => {
      if (addEmpty) {
        list = [{}, ...list];
      }
      return list.map((item: any, index: number) =>
        index == 0 && addEmpty ? (
          <MenuItem value={""} key={index}>
            <em>未選択</em>
          </MenuItem>
        ) : (
          <MenuItem
            value={item[value]}
            key={item[key]}
            className="IgnoreExtractRuleTarget"
          >
            {name ? item[name] : getName(item)}
          </MenuItem>
        ),
      );
    },
    [],
  );

  const renderMultiSelect = useCallback(
    (field: keyof IWorkSearchForm) => {
      if (typeof stateForm[field] !== "object") return null;
      return (stateForm[field] as Array<Imultiple>).map((item, index) => (
        <Chip
          onDelete={() => {
            let cloneData = [...(stateForm[field] as Array<Imultiple>)];
            cloneData.splice(index, 1);
            setStateForm({ ...stateForm, [field]: cloneData });
          }}
          label={item.name}
          key={item.id}
        />
      ));
    },
    [stateForm],
  );

  const onChangeText =
    (field: keyof IWorkSearchForm) =>
    (newText: string | boolean | undefined) => {
      let newStateForm = { ...stateForm, [field]: newText };
      setStateForm(newStateForm);
    };

  const onChangeBoolean = (field: keyof IWorkSearchForm, value: boolean) => {
    let newStateForm = { ...stateForm };
    if (stateForm[field] === value) {
      //同じボタンを続けて押した場合は、値を外す
      newStateForm = { ...stateForm, [field]: undefined };
    } else {
      newStateForm = { ...stateForm, [field]: value };
    }
    setStateForm(newStateForm);
  };

  const onChangeSelect =
    (field: string) =>
    (
      list: any[],
      value: string,
      name?: string,
      key: string = "SK",
      getName: (item: any) => string = () => "",
    ) => {
      const field_name = field + "_name";
      const selected = list.find((item) => item && item[key] === value);
      var value_name = "";
      if (selected) {
        value_name = name ? selected[name] : getName(selected);
      }
      setStateForm({
        ...stateForm,
        [field]: value,
        [field_name]: value_name,
      });
    };

  const onChangeTime = (
    newValue: Date | null,
    field: keyof IWorkSearchForm,
  ) => {
    const time = formatDateToString(newValue, "HHmm");
    onBlurTime(time, field);
    if (field.includes("_s")) {
      setDateStartTime(newValue);
    } else if (field.includes("_e")) {
      setDateEndTime(newValue);
    }
    setStateForm({ ...stateForm, [field]: time });
  };

  const onBlurTime = (
    newValue: string | null,
    field: keyof IWorkSearchForm,
  ) => {
    let mess = "";
    if (!newValue) {
      newValue = "";
    } else {
      mess = validator(field, newValue);
      setFormError({ ...formError, [field]: mess });
    }

    if (mess.length === 0) {
      // 時間範囲のチェック
      const field_s: keyof IWorkSearchForm = field.replace("_e", "_s") as any;
      const field_e: keyof IWorkSearchForm = field.replace("_s", "_e") as any;
      const value_s = stateForm[field_s] ? stateForm[field_s] : "00:00";
      const value_e = stateForm[field_e] ? stateForm[field_e] : "23:59";
      const start = field.includes("_s")
        ? new Date(yyyyMMddString + newValue)
        : new Date(yyyyMMddString + value_s);
      const end = field.includes("_e")
        ? new Date(yyyyMMddString + newValue)
        : new Date(yyyyMMddString + value_e);
      let mess_start = "";
      let mess_end = "";
      if (start instanceof Date && end instanceof Date) {
        if (start && end) {
          mess_start = Validation.validateDateRange(start, end);
          if (mess_start) {
            mess_end = mess_start;
          }
        }
        setFormError((prev) => {
          return {
            ...prev,
            [field_s]: mess_start,
            [field_e]: mess_end,
          };
        });
      }
    }
  };

  const onChangeDate = (
    newValue: string | null,
    field: keyof IWorkSearchForm,
  ) => {
    let displayValue = "";
    if (!newValue) {
      newValue = "";
    } else {
      // YMDだと二桁の日が直接入力不可だが、バリデーションには使う
      newValue = formatDateToString(newValue, "YMD");
      displayValue = formatDateToString(newValue, "YMd");
    }

    let mess = "";
    let newStateForm = { ...stateForm, [field]: displayValue };
    if (newValue) {
      mess = validator(field, newValue);
    }
    setFormError({ ...formError, [field]: mess });

    if (mess.length === 0) {
      // if (field == "applied_at_s") {
      //   // 日付範囲を月初・月末に整形
      //   const field_e: keyof IWorkSearchForm = field.replace("_s", "_e") as any;
      //   var start_dt = getDateRange(newValue, field);
      //   var end_dt = getDateRange(newValue, field_e);
      //   newStateForm = {
      //     ...newStateForm,
      //     [field]: formatDateToString(start_dt, "YMd"),
      //     [field_e]: formatDateToString(end_dt, "YMd"),
      //   };
      // } else {
      // 日付範囲のチェック
      const field_s: keyof IWorkSearchForm = field.replace("_e", "_s") as any;
      const field_e: keyof IWorkSearchForm = field.replace("_s", "_e") as any;
      const start = field.includes("_s") ? newValue : stateForm[field_s];
      const end = field.includes("_e") ? newValue : stateForm[field_e];
      let mess_start = "";
      let mess_end = "";

      if (typeof start == "string" && typeof end == "string") {
        if (start && end) {
          mess_start = Validation.validateDateRange(
            new Date(start),
            new Date(end),
          );
          if (
            !mess_start &&
            (field == "applied_at_s" || field == "applied_at_e")
          ) {
            // 申請日は一カ月以内の範囲で指定
            mess_start = Validation.validateDateRangeLimit(
              new Date(start),
              new Date(end),
              "month",
              1,
              "1カ月",
            );
          }
          if (mess_start) {
            mess_end = mess_start;
            // isError = true;
          }
        }
        setFormError((prev) => {
          return {
            ...prev,
            [field_s]: mess_start,
            [field_e]: mess_end,
          };
        });

        if (field == "applied_at_s" || field == "applied_at_e") {
          if (start && end) {
          } else if (start || end) {
            // 入力済み日付と同月をカレンダーポップアップのデフォルト表示にする
            setDefaultCalendarMonth(new Date(start ? start : end));
          } else {
            setDefaultCalendarMonth(undefined);
          }
        }
      }
      // }
    }
    setStateForm(newStateForm);
  };

  const onChangeDateYm =
    (field: keyof IWorkSearchForm) => (value: string | null) => {
      if (!value) {
        value = "";
      } else {
        value = formatDateToString(value, "YM");
      }

      let mess = "";
      if (value) {
        mess = validator(field, value);
      }
      setFormError({ ...formError, [field]: mess });
      setStateForm((prev) => ({ ...prev, [field]: value }));
    };

  const onChangeWorkStatus = useCallback(
    (index: number, isSelected: boolean) => {
      let cloneData = cloneDeep(stateForm.work_status);
      cloneData[index].isSelected = isSelected;
      setStateForm({ ...stateForm, work_status: cloneData });
    },
    [stateForm],
  );

  const onChangeTimeAC =
    (field: string) => (newValue: Date | null | string) => {
      const time = formatDateToString(newValue, "HHmm");
      //TimeComponentsに表示させるためのDate型へのキャスト
      setDateTime((prev) => ({ ...prev, [field]: newValue }));
      setStateForm((prev) => ({ ...prev, [field]: time }));
    };

  const validator = (field: keyof IWorkSearchForm, value: string) => {
    let mess: string = "";

    switch (field) {
      case "ngr_date_s":
        mess = Validation.validateDate(value, "Date(YYYY/MM/DD)", false);
        break;
      case "ngr_date_e":
        mess = Validation.validateDate(value, "Date(YYYY/MM/DD)", false);
        break;
      case "ngr_closing_date_s":
        mess = Validation.validateDate(value, "Closing Date", false);
        break;
      case "ngr_closing_date_e":
        mess = Validation.validateDate(value, "Closing Date", false);
        break;
      case "ngr_completion_improvement_date_s":
        mess = Validation.validateDate(
          value,
          "Completion Improvement Date",
          false,
        );
        break;
      case "ngr_completion_improvement_date_e":
        mess = Validation.validateDate(
          value,
          "Completion Improvement Date",
          false,
        );
        break;
      case "applied_at_s":
        mess = Validation.validateDate(value, "申請日開始", false);
        break;
      case "applied_at_e":
        mess = Validation.validateDate(value, "申請日終了", false);
        break;
      case "approved_at_s":
        mess = Validation.validateDate(value, "最終承認日開始", false);
        break;
      case "approved_at_e":
        mess = Validation.validateDate(value, "最終承認日終了", false);
        break;
      case "updated_at_s":
        mess = Validation.validateDate(value, "最終更新日開始", false);
        break;
      case "updated_at_e":
        mess = Validation.validateDate(value, "最終更新日終了", false);
        break;
      case "incident_date_s":
        mess = Validation.validateDate(value, "発生年月日開始", false);
        break;
      case "incident_date_e":
        mess = Validation.validateDate(value, "発生年月日終了", false);
        break;
      case "date_of_delivery_s":
        mess = Validation.validateDate(value, "搬入年月日開始", false);
        break;
      case "date_of_delivery_e":
        mess = Validation.validateDate(value, "搬入年月日終了", false);
        break;
      case "year_month":
        mess = Validation.validateDate(value, "実施年月", false);
        break;
      case "ngr_time_s":
      case "ngr_time_e":
        mess = Validation.validate({
          type: "time",
          name: "時間",
          value: value,
          required: false,
        });
        break;
      case "edr_date_s":
        mess = Validation.validateDate(value, "日付開始", false);
        break;
      case "edr_date_e":
        mess = Validation.validateDate(value, "日付終了", false);
        break;
      case "edr_arrival_date_s":
        mess = Validation.validateDate(value, "入荷日終了", false);
        break;
      case "edr_arrival_date_e":
        mess = Validation.validateDate(value, "入荷日終了", false);
        break;
    }
    return mess;
  };

  const ITEM_BUTTON_SELECT: { [key: string]: string } = {
    approver: "full_name",
    author: "full_name",
    issue_place_floor: "occurrence_location_name",
    warehouse_place_floor: "occurrence_location_name",
    issue_place_span: "span_name",
    warehouse_place_span: "span_name",
    container_status: "container_status_name",
    defective_products: "container_defect_detail_name",
    ngr_warehouse: "name",
    ngr_area: "name",
    ngr_customer: "name",
    ngr_commodity: "name",
    ngr_damage_location: "name",
    ngr_qa_authorizer: "name",
    ngr_occurred_company: "name",
    ngr_affiliation_of_employee: "name",
    ngr_waste_claim_category: "name",
    ngr_accident_rank: "name",
    ngr_nonconformity_detail: "name",
    ngr_logistics_equipments: "name",
    ngr_final_payer: "name",
    ngr_expected_payment_month: "name",
    ngr_est_acc: "name",
    ngr_actual_payment_month: "name",
  };

  const handleClickSelect = (field: keyof IWorkSearchForm, list: any[]) => {
    if (typeof stateForm[field] == "object") {
      setCurrentData(stateForm[field]);
      setSelectDialiogData(list);
      setNameAttribute(ITEM_BUTTON_SELECT[field]);
      setSelectDialogField(field);
      setOpenSelectDialog(true);
    }
  };

  const handleChoose = (data: Array<Imultiple>) => {
    if (selectDialogField) {
      setStateForm({ ...stateForm, [selectDialogField]: data });
    }
  };

  const disabledSubmit = useMemo(() => {
    return (
      // initStateとformErrorの初期値が同値であり、initStateで
      // image_existを指定なしで初期値に設定しているため
      // someの判定回避を目的として!(d in IMAGE_EXIST_TYPE)を追加。
      Object.values(formError).some(
        (d) => typeof d == "string" && d.length > 0 && !(d in IMAGE_EXIST_TYPE),
      ) ||
      stateForm.report_type == "" ||
      stateForm.project_id == "" ||
      stateForm.applied_at_s == "" ||
      stateForm.applied_at_e == ""
    );
  }, [stateForm, formError]);

  const getDateRange = (date: string, key: string) => {
    const arrValue = date.split("-");
    var dt = null;
    dt = new Date(Number(arrValue[0]), Number(arrValue[1]) - 1, 1);
    if (key == "applied_at_e") {
      dt = new Date(dt);
      dt.setMonth(dt.getMonth() + 1);
      dt.setDate(0);
    }

    return dt;
  };

  const handleClear = () => {
    let newStateForm = cloneDeep(initialStateForm);
    newStateForm = {
      ...newStateForm,
      ["applied_at_s"]: stateForm.applied_at_s,
      ["applied_at_e"]: stateForm.applied_at_e,
    };

    let newFormError = cloneDeep(initialStateForm);
    newFormError = {
      ...newFormError,
      ["applied_at_s"]: formError.applied_at_s,
    };
    setStateForm(newStateForm);
    setFormError(newFormError);
    setListProject([]);
    setApprovalFlowList([]);
    setDateTime({ time_ac: null, returning_time: null });
  };

  const templateInfo = useMemo(() => {
    const selected_project = listProject.find(
      (item) => item.PK == stateForm.project_id,
    );
    return listTemplate.find(
      (template) => template.SK === selected_project?.template_project_id,
    );
  }, [listProject, listTemplate, stateForm.project_id]);

  const getScreenId = (templateProjectId: string) => {
    let response = "";
    const templateInfo = listTemplate.find(
      (template) => template.SK === templateProjectId,
    );
    if (templateInfo) {
      response = templateInfo.screen_id;
    }
    return response;
  };

  const filterListProject = async (report_type_id: string) => {
    try {
      LoadingOverlayController.show();
      // 帳票
      let res_project = await getListProjectData("", "", "", report_type_id);
      if (res_project && res_project.length > 0) {
        setListAllProject(() => res_project);
      }

      let existExteriorDamaged = false;
      return res_project
        .filter((item: any) => {
          const screen_id = getScreenId(item.template_project_id);
          if (
            screenIdSupport[screen_id] ==
              TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_TC_REPORT ||
            screenIdSupport[screen_id] ==
              TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_DC_REPORT
          ) {
            if (
              item.report_type_id == report_type_id &&
              !existExteriorDamaged
            ) {
              // 外装破損商品連絡書は1件だけ帳票に表示する
              existExteriorDamaged = true;
              return true;
            } else {
              return false;
            }
          }
          return item.report_type_id == report_type_id;
        })
        .map((item: any) => {
          const screen_id = getScreenId(item.template_project_id);
          if (
            screenIdSupport[screen_id] ==
              TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_TC_REPORT ||
            screenIdSupport[screen_id] ==
              TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_DC_REPORT
          ) {
            item.project_name = "外装破損商品連絡書";
          }
          return item;
        });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  return (
    <Dialog
      maxWidth="md"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
      onClose={() => onClose()}
    >
      <ModalSearchUserByLocation
        open={openMemberSearch}
        onClose={() => {
          setSelectDialogField(undefined);
          setOpenMemberSearch(false);
        }}
        onChoose={(users) => {
          const selected = users.map((user) => {
            let id = "";
            if (user.PK.includes("CONTRACT#")) {
              id = user.SK;
            } else {
              id = user.PK;
            }
            return {
              id: id,
              name: user.full_name,
            };
          });
          handleChoose(selected);
        }}
      />
      <SelectDialog
        open={openSelectDialog}
        data={selectDialiogData}
        selectedData={currentData}
        nameAttribute={nameAttribute}
        onClose={() => {
          setSelectDialogField(undefined);
          setOpenSelectDialog(false);
        }}
        onChoose={handleChoose}
      />

      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="body1">帳票検索条件</Typography>
        {/* <IconButton
          onClick={() => onClose()}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Close />
        </IconButton> */}
      </DialogTitle>

      <DialogContent dividers>
        <Stack>
          <SelectLabel
            label={<LabelRequired title="帳票種類" />}
            value={stateForm.report_type}
            isIgnoreExtractRuleLabel={false}
            isIgnoreExtractRuleSelect={true}
            onChange={async (e) => {
              onChangeSelect("report_type")(
                listReportType,
                e.target.value,
                undefined,
                undefined,
                (item) => item.report_type_code + ":" + item.report_type_name,
              );
              const filteredList = await filterListProject(e.target.value);
              setListProject(filteredList);
            }}
          >
            {renderMenuItem(
              listReportType,
              undefined,
              (item) => item.report_type_code + ":" + item.report_type_name,
            )}
          </SelectLabel>
          <SelectLabel
            label={<LabelRequired title="帳票" />}
            value={stateForm.project_id}
            onChange={async (e) => {
              onChangeSelect("project_id")(
                listProject,
                e.target.value,
                "project_name",
                "PK",
              );
              const selected_project = listProject.find(
                (item) => item.PK == e.target.value,
              );
              await getFlowList(selected_project?.template_project_id);
              onGetTemplateInfo(
                getScreenId(selected_project?.template_project_id),
                selected_project?.template_project_id,
              );
            }}
          >
            {renderMenuItem(
              listProject,
              undefined,
              (item) =>
                "【" +
                item.report_number +
                ":" +
                item.report_name +
                "】" +
                item.project_name,
              true,
              "PK",
              "PK",
            )}
          </SelectLabel>
          {/* <Box flexDirection={{ xs: "column", md: "row" }} display={"flex"}>
            <DatePickerCustom
              label={<LabelRequired title="申請月" />}
              value={stateForm.applied_at_s ? stateForm.applied_at_s : null}
              onChange={(v) => {
                onChangeDate(v, "applied_at_s");
              }}
              onBlur={() => {
                // 直接入力時
                let newStateForm = {
                  ...stateForm,
                  ["applied_at_s"]: formatDateToString(
                    stateForm.applied_at_s,
                    "YMD",
                  ),
                  ["applied_at_e"]: formatDateToString(
                    stateForm.applied_at_e,
                    "YMD",
                  ),
                };
                setStateForm(newStateForm);
              }}
              onAccept={(v) => {
                // カレンダー選択時
                var day = formatDateToString(v, "YMD");
                var start_dt = getDateRange(day, "applied_at_s");
                var end_dt = getDateRange(day, "applied_at_e");
                let newStateForm = {
                  ...stateForm,
                  ["applied_at_s"]: formatDateToString(start_dt, "YMD"),
                  ["applied_at_e"]: formatDateToString(end_dt, "YMD"),
                };
                setStateForm(newStateForm);
              }}
              openTo="year"
              views={["year", "month"]}
              inputFormat="yyyy/MM"
              mask="____/__"
              helperText={formError.applied_at_s}
              error={formError.applied_at_s.length > 0}
            />
          </Box> */}
          <DateRangePicker
            label={<LabelRequired title="申請日" />}
            value={[stateForm.applied_at_s, stateForm.applied_at_e]}
            onChange={[
              (v) => {
                onChangeDate(v, "applied_at_s");
              },
              (v) => {
                onChangeDate(v, "applied_at_e");
              },
            ]}
            onBlur={[
              () => {
                onChangeDate(
                  formatDateToString(stateForm.applied_at_s, "YMD"),
                  "applied_at_s",
                );
              },
              () => {
                onChangeDate(
                  formatDateToString(stateForm.applied_at_e, "YMD"),
                  "applied_at_e",
                );
              },
            ]}
            onAccept={[
              (v) => {
                onChangeDate(formatDateToString(v, "YMD"), "applied_at_s");
              },
              (v) => {
                onChangeDate(formatDateToString(v, "YMD"), "applied_at_e");
              },
            ]}
            helperText={[formError.applied_at_s, formError.applied_at_e]}
            error={[
              formError.applied_at_s.length > 0,
              formError.applied_at_e.length > 0,
            ]}
            defaultCalendarMonth={defaultCalendarMonth}
          />
          <DateRangePicker
            label="最終承認日"
            value={[stateForm.approved_at_s, stateForm.approved_at_e]}
            onChange={[
              (v) => {
                onChangeDate(v, "approved_at_s");
              },
              (v) => {
                onChangeDate(v, "approved_at_e");
              },
            ]}
            onBlur={[
              () => {
                onChangeText("approved_at_s")(
                  formatDateToString(stateForm.approved_at_s, "YMD"),
                );
              },
              () => {
                onChangeText("approved_at_e")(
                  formatDateToString(stateForm.approved_at_e, "YMD"),
                );
              },
            ]}
            onAccept={[
              (v) => {
                onChangeText("approved_at_s")(formatDateToString(v, "YMD"));
              },
              (v) => {
                onChangeText("approved_at_e")(formatDateToString(v, "YMD"));
              },
            ]}
            helperText={[formError.approved_at_s, formError.approved_at_e]}
            error={[
              formError.approved_at_s.length > 0,
              formError.approved_at_e.length > 0,
            ]}
          />
          <DateRangePicker
            label="最終更新日"
            value={[stateForm.updated_at_s, stateForm.updated_at_e]}
            onChange={[
              (v) => {
                onChangeDate(v, "updated_at_s");
              },
              (v) => {
                onChangeDate(v, "updated_at_e");
              },
            ]}
            onBlur={[
              () => {
                onChangeText("updated_at_s")(
                  formatDateToString(stateForm.updated_at_s, "YMD"),
                );
              },
              () => {
                onChangeText("updated_at_e")(
                  formatDateToString(stateForm.updated_at_e, "YMD"),
                );
              },
            ]}
            onAccept={[
              (v) => {
                onChangeText("updated_at_s")(formatDateToString(v, "YMD"));
              },
              (v) => {
                onChangeText("updated_at_e")(formatDateToString(v, "YMD"));
              },
            ]}
            helperText={[formError.updated_at_s, formError.updated_at_e]}
            error={[
              formError.updated_at_s.length > 0,
              formError.updated_at_e.length > 0,
            ]}
          />
          <FormControl>
            <InputLabel>帳票状況</InputLabel>
            <Stack
              direction={{ xs: "column", md: "row" }}
              sx={{ gap: 1 }}
              spacing={0}
            >
              {stateForm.work_status.map((item, index) => {
                return (
                  <CheckboxLabel
                    label={item?.name}
                    checked={item?.isSelected}
                    onChange={(_, checked) => {
                      onChangeWorkStatus(index, checked);
                    }}
                    key={item?.id}
                  />
                );
              })}
            </Stack>
          </FormControl>
          <FormControl>
            <InputLabel>承認者</InputLabel>
            <StackChip>
              {renderMultiSelect("approver")}
              <Button
                size="small"
                onClick={() => {
                  setOpenMemberSearch(true);
                  setSelectDialogField("approver");
                }}
              >
                追加
              </Button>
            </StackChip>
          </FormControl>
          <FormControl>
            <InputLabel>申請者</InputLabel>
            <StackChip>
              {renderMultiSelect("author")}
              <Button
                size="small"
                onClick={() => {
                  setOpenMemberSearch(true);
                  setSelectDialogField("author");
                }}
              >
                追加
              </Button>
            </StackChip>
          </FormControl>
          <TextField
            label="帳票番号"
            value={stateForm.template_id}
            onChange={(e) => {
              onChangeText("template_id")(e.target.value);
            }}
          />
          <SelectLabel
            label="承認フロー"
            value={stateForm.approval_flow}
            onChange={(e) => {
              onChangeSelect("approval_flow")(
                approvalFlowList,
                e.target.value,
                "flow_name",
              );
            }}
          >
            {renderMenuItem(approvalFlowList, "flow_name")}
          </SelectLabel>
          <SelectLabel
            label="ネクストプロセス"
            value={stateForm.next_user}
            onChange={(e) => {
              onChangeSelect("next_user")(
                listUser,
                e.target.value,
                "full_name",
              );
            }}
          >
            {renderMenuItem(listUser, "full_name")}
          </SelectLabel>
          <FormControl>
            <InputLabel>写真添付有無</InputLabel>
            <RadioGroup
              name="search-type-radio-group"
              value={stateForm.image_exist}
              row
              onChange={(_, value) => {
                setStateForm({
                  ...stateForm,
                  image_exist: value,
                });
              }}
            >
              <FormControlLabel
                value={IMAGE_EXIST_TYPE.not_designation}
                label="指定なし"
                control={<Radio />}
              />
              <FormControlLabel
                value={IMAGE_EXIST_TYPE.not_attached}
                label="写真添付なし"
                control={<Radio />}
              />
              <FormControlLabel
                value={IMAGE_EXIST_TYPE.attached}
                label="写真添付あり"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
          {screenIdSupport[screenId] ==
            TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT && (
            <FormControl>
              <InputLabel>ファイル添付有無</InputLabel>
              <RadioGroup
                name="search-type-radio-group"
                value={stateForm.attached_file_exist}
                row
                onChange={(_, value) => {
                  setStateForm({
                    ...stateForm,
                    attached_file_exist: value,
                  });
                }}
              >
                <FormControlLabel
                  value={IMAGE_EXIST_TYPE.not_designation}
                  label="指定なし"
                  control={<Radio />}
                />
                <FormControlLabel
                  value={IMAGE_EXIST_TYPE.not_attached}
                  label="ファイル添付なし"
                  control={<Radio />}
                />
                <FormControlLabel
                  value={IMAGE_EXIST_TYPE.attached}
                  label="ファイル添付あり"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          )}
          {/* テンプレートごとの詳細検索 要修正*/}
          {ListDetailTitle[screenIdSupport[screenId]] && (
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>
                  {ListDetailTitle[screenIdSupport[screenId]]}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <SearchDialogDetail
                  screenId={screenId}
                  stateForm={stateForm}
                  templateInfo={templateInfo}
                  setStateForm={setStateForm}
                  setFormError={setFormError}
                />
              </Collapse>
            </div>
          )}
          {screenIdSupport[screenId] == TEMPLATE_EDIT_TYPE.RISKY_REPORT ? (
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>庫内商品事故報告書項目</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <Stack>
                  <TextField
                    label="発行元"
                    value={stateForm.place_found_issue}
                    onChange={(e) => {
                      onChangeText("place_found_issue")(e.target.value);
                    }}
                  />
                  <DateRangePicker
                    label="発生年月日"
                    value={[
                      stateForm.incident_date_s,
                      stateForm.incident_date_e,
                    ]}
                    onChange={[
                      (v) => {
                        onChangeDate(v, "incident_date_s");
                      },
                      (v) => {
                        onChangeDate(v, "incident_date_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onChangeText("incident_date_s")(
                          formatDateToString(stateForm.incident_date_s, "YMD"),
                        );
                      },
                      () => {
                        onChangeText("incident_date_e")(
                          formatDateToString(stateForm.incident_date_e, "YMD"),
                        );
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onChangeText("incident_date_s")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                      (v) => {
                        onChangeText("incident_date_e")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                    ]}
                    helperText={[
                      formError.incident_date_s,
                      formError.incident_date_e,
                    ]}
                    error={[
                      formError.incident_date_s.length > 0,
                      formError.incident_date_e.length > 0,
                    ]}
                  />
                  <FormControl>
                    <InputLabel>発生場所フロア</InputLabel>
                    <StackChip>
                      {renderMultiSelect("issue_place_floor")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "issue_place_floor",
                            listOccurrenceLocation,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>発生場所スパン</InputLabel>
                    <StackChip>
                      {renderMultiSelect("issue_place_span")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect("issue_place_span", listSpanMaster)
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>事故区分</InputLabel>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      sx={{ gap: 1 }}
                      spacing={0}
                    >
                      <CheckboxLabel
                        label={issueType.product}
                        checked={stateForm.is_product_issue}
                        onChange={(_, checked) => {
                          onChangeText("is_product_issue")(checked);
                        }}
                      />
                      <CheckboxLabel
                        label={issueType.packaging}
                        checked={stateForm.is_packaging_issue}
                        onChange={(_, checked) => {
                          onChangeText("is_packaging_issue")(checked);
                        }}
                      />
                    </Stack>
                  </FormControl>
                  <TextField
                    label="事故当事者"
                    value={stateForm.responsible_person}
                    onChange={(e) => {
                      onChangeText("responsible_person")(e.target.value);
                    }}
                  />
                  <TextField
                    label="発見者氏名"
                    value={stateForm.person_found_issue}
                    onChange={(e) => {
                      onChangeText("person_found_issue")(e.target.value);
                    }}
                  />
                  <TextField
                    label="分類"
                    value={stateForm.product_type}
                    onChange={(e) => {
                      onChangeText("product_type")(e.target.value);
                    }}
                  />
                  <TextField
                    label="商品名"
                    value={stateForm.product_name}
                    onChange={(e) => {
                      onChangeText("product_name")(e.target.value);
                    }}
                  />
                  <TextField
                    label="数量"
                    sx={{ width: 120 }}
                    value={stateForm.amount_of_product}
                    onChange={(e) => {
                      onChangeText("amount_of_product")(e.target.value);
                    }}
                  />
                  <TextField
                    label="機番"
                    value={stateForm.product_id}
                    onChange={(e) => {
                      onChangeText("product_id")(e.target.value);
                    }}
                  />
                  <TextField
                    label="荷姿状態"
                    value={stateForm.product_packaging_status}
                    onChange={(e) => {
                      onChangeText("product_packaging_status")(e.target.value);
                    }}
                  />
                  <TextField
                    label="事故発生理由"
                    value={stateForm.reason_cause_issue}
                    onChange={(e) => {
                      onChangeText("reason_cause_issue")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>格納場所フロア</InputLabel>
                    <StackChip>
                      {renderMultiSelect("warehouse_place_floor")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "warehouse_place_floor",
                            listOccurrenceLocation,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>格納場所スパン</InputLabel>
                    <StackChip>
                      {renderMultiSelect("warehouse_place_span")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "warehouse_place_span",
                            listSpanMaster,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="不良ロケ"
                    value={stateForm.bad_place}
                    onChange={(e) => {
                      onChangeText("bad_place")(e.target.value);
                    }}
                  />
                </Stack>
              </Collapse>
            </div>
          ) : screenIdSupport[screenId] ==
            TEMPLATE_EDIT_TYPE.TEMPLATE_FORKLIFT_REPORT ? (
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>
                  日次フォークリフト点検簿
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <Stack>
                  <TextField
                    label="車体番号"
                    value={stateForm.vehicles_frame_no}
                    onChange={(e) => {
                      onChangeText("vehicles_frame_no")(e.target.value);
                    }}
                  />
                  <SelectLabel
                    label="事業場名"
                    value={stateForm.vehicles_workplace}
                    onChange={(e) => {
                      onChangeText("vehicles_workplace")(e.target.value);
                    }}
                  >
                    {renderMenuItem(
                      listWorkplace,
                      "workplace_name",
                      undefined,
                      true,
                      "workplace_name",
                    )}
                  </SelectLabel>
                  <TextField
                    label="車種"
                    value={stateForm.vehicles_model}
                    onChange={(e) => {
                      onChangeText("vehicles_model")(e.target.value);
                    }}
                  />
                  <SelectLabel
                    label="燃料"
                    value={stateForm.vehicles_fuel_type}
                    onChange={(e) => {
                      onChangeText("vehicles_fuel_type")(e.target.value);
                    }}
                  >
                    {renderMenuItem(
                      listFuel,
                      "fuel_name",
                      undefined,
                      true,
                      "fuel_name",
                    )}
                  </SelectLabel>
                  <TextField
                    label="車両番号"
                    value={stateForm.vehicles_name}
                    onChange={(e) => {
                      onChangeText("vehicles_name")(e.target.value);
                    }}
                  />
                  <TextField
                    label="担当者名"
                    value={stateForm.driver}
                    onChange={(e) => {
                      onChangeText("driver")(e.target.value);
                    }}
                  />
                  <DatePickerCustom
                    label="実施年月"
                    value={stateForm.year_month}
                    onChange={onChangeDateYm("year_month")}
                    openTo="year"
                    views={["year", "month"]}
                    inputFormat="yyyy/MM"
                    mask="____/__"
                    helperText={formError.year_month}
                    error={formError.year_month.length > 0}
                  />
                  <TextField
                    label="点検実施者名"
                    value={stateForm.check_user}
                    onChange={(e) => {
                      onChangeText("check_user")(e.target.value);
                    }}
                  />
                  <TextField
                    label="備考"
                    value={stateForm.memo}
                    onChange={(e) => {
                      onChangeText("memo")(e.target.value);
                    }}
                  />
                </Stack>
              </Collapse>
            </div>
          ) : screenIdSupport[screenId] ==
            TEMPLATE_EDIT_TYPE.INSPECTION_REPORT ? (
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>InspectionReport</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <Stack>
                  <TextField
                    label="商品分類(複数検索する場合は,で区切ってください)"
                    value={stateForm.product_class}
                    onChange={(e) => {
                      onChangeText("product_class")(e.target.value);
                    }}
                  />
                  <DateRangePicker
                    label="搬入年月日"
                    value={[
                      stateForm.date_of_delivery_s,
                      stateForm.date_of_delivery_e,
                    ]}
                    onChange={[
                      (v) => {
                        onChangeDate(v, "date_of_delivery_s");
                      },
                      (v) => {
                        onChangeDate(v, "date_of_delivery_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onChangeText("date_of_delivery_s")(
                          formatDateToString(
                            stateForm.date_of_delivery_s,
                            "YMD",
                          ),
                        );
                      },
                      () => {
                        onChangeText("date_of_delivery_e")(
                          formatDateToString(
                            stateForm.date_of_delivery_e,
                            "YMD",
                          ),
                        );
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onChangeText("date_of_delivery_s")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                      (v) => {
                        onChangeText("date_of_delivery_e")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                    ]}
                    helperText={[
                      formError.date_of_delivery_s,
                      formError.date_of_delivery_e,
                    ]}
                    error={[
                      formError.date_of_delivery_s.length > 0,
                      formError.date_of_delivery_e.length > 0,
                    ]}
                  />
                  <TextField
                    label="コンテナ番号"
                    value={stateForm.container_no}
                    onChange={(e) => {
                      onChangeText("container_no")(e.target.value);
                    }}
                  />
                  <TextField
                    label="コンテナサイズ"
                    value={stateForm.container_size}
                    onChange={(e) => {
                      onChangeText("container_size")(e.target.value);
                    }}
                  />
                  <TextField
                    label="納入時間"
                    value={stateForm.delivery_time}
                    onChange={(e) => {
                      onChangeText("delivery_time")(e.target.value);
                    }}
                  />
                  <TextField
                    label="搬入者名"
                    value={stateForm.carrier_name}
                    onChange={(e) => {
                      onChangeText("carrier_name")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Unit区分"
                    value={stateForm.unit_class}
                    onChange={(e) => {
                      onChangeText("unit_class")(e.target.value);
                    }}
                  />
                  <TextField
                    label="搬入場所"
                    value={stateForm.delivery_location}
                    onChange={(e) => {
                      onChangeText("delivery_location")(e.target.value);
                    }}
                  />
                  <TextField
                    label="インボイスナンバー"
                    value={stateForm.invoice_no}
                    onChange={(e) => {
                      onChangeText("invoice_no")(e.target.value);
                    }}
                  />
                  <TextField
                    label="シールNo."
                    value={stateForm.seal_no}
                    onChange={(e) => {
                      onChangeText("seal_no")(e.target.value);
                    }}
                  />
                  <TextField
                    label="バース番号"
                    value={stateForm.berth_number}
                    onChange={(e) => {
                      onChangeText("berth_number")(e.target.value);
                    }}
                  />
                  <InputLabel>封印</InputLabel>
                  <ButtonGroup sx={{ ml: 1 }}>
                    <Button
                      variant={stateForm.sealed ? "contained" : "outlined"}
                      onClick={() => {
                        onChangeBoolean("sealed", true);
                      }}
                    >
                      有
                    </Button>
                    <Button
                      variant={
                        stateForm.sealed === false ? "contained" : "outlined"
                      }
                      onClick={() => {
                        onChangeBoolean("sealed", false);
                      }}
                    >
                      無
                    </Button>
                  </ButtonGroup>
                  <TextField
                    label="機種名"
                    value={stateForm.model_name}
                    onChange={(e) => {
                      onChangeText("model_name")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>不適合内容</InputLabel>
                    <StackChip>
                      {renderMultiSelect("defective_products")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect("defective_products", defectList)
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>

                  <TextField
                    label="作業者名"
                    value={stateForm.person_worker}
                    onChange={(e) => {
                      onChangeText("person_worker")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>コンテナの状態</InputLabel>
                    <StackChip>
                      {renderMultiSelect("container_status")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "container_status",
                            listContainerStatus,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                </Stack>
              </Collapse>
            </div>
          ) : screenIdSupport[screenId] == TEMPLATE_EDIT_TYPE.LOADING_REPORT ? (
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>LoadingReport</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <Stack>
                  <FormControl>
                    <DatePickerCustom
                      label={"Loading Date(YYYY/MM/DD)"}
                      value={stateForm.loading_date || null}
                      onChange={(v) => {
                        onChangeDate(v, "loading_date");
                      }}
                    />
                  </FormControl>
                  <TextField
                    label="Warehouse"
                    value={stateForm.warehouse}
                    onChange={(e) => {
                      onChangeText("warehouse")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Load by"
                    value={stateForm.load_by}
                    onChange={(e) => {
                      onChangeText("load_by")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Invoice No."
                    value={stateForm.invoice_no_lr}
                    onChange={(e) => {
                      onChangeText("invoice_no_lr")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Booking No."
                    value={stateForm.booking_no}
                    onChange={(e) => {
                      onChangeText("booking_no")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Commodity"
                    value={stateForm.commodity}
                    onChange={(e) => {
                      onChangeText("commodity")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Container Type"
                    value={stateForm.container_type}
                    onChange={(e) => {
                      onChangeText("container_type")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Container No."
                    value={stateForm.container_no_lr}
                    onChange={(e) => {
                      onChangeText("container_no_lr")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Seal No."
                    value={stateForm.seal_no_lr}
                    onChange={(e) => {
                      onChangeText("seal_no_lr")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Remark"
                    value={stateForm.remark}
                    onChange={(e) => {
                      onChangeText("remark")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Model Name"
                    value={stateForm.model_name_lr}
                    onChange={(e) => {
                      onChangeText("model_name_lr")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Quantity"
                    value={stateForm.qty}
                    onChange={(e) => {
                      onChangeText("qty")(e.target.value);
                    }}
                  />

                  <TextField
                    label="Total Quantity"
                    value={stateForm.total_qty}
                    onChange={(e) => {
                      onChangeText("total_qty")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Reserve Status</InputLabel>
                    <ButtonGroup>
                      <Button
                        variant={
                          stateForm.reserve_status ? "contained" : "outlined"
                        }
                        onClick={() => {
                          onChangeBoolean("reserve_status", true);
                        }}
                      >
                        OK
                      </Button>
                      <Button
                        variant={
                          stateForm.reserve_status === false
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          onChangeBoolean("reserve_status", false);
                        }}
                      >
                        NG
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Stack>
              </Collapse>
            </div>
          ) : screenIdSupport[screenId] ==
            TEMPLATE_EDIT_TYPE.TEMPLATE_INCIDENT_DAMAGE_REPORT ? (
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>
                  Nonconformity Goods Report (NGR)
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <Stack>
                  <DateRangePicker
                    label="Date(YYYY/MM/DD)"
                    value={[stateForm.ngr_date_s, stateForm.ngr_date_e]}
                    onChange={[
                      (v) => {
                        onChangeDate(v, "ngr_date_s");
                      },
                      (v) => {
                        onChangeDate(v, "ngr_date_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onChangeText("ngr_date_s")(
                          formatDateToString(stateForm.ngr_date_s, "YMD"),
                        );
                      },
                      () => {
                        onChangeText("ngr_date_e")(
                          formatDateToString(stateForm.ngr_date_e, "YMD"),
                        );
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onChangeText("ngr_date_s")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                      (v) => {
                        onChangeText("ngr_date_e")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                    ]}
                    helperText={[formError.ngr_date_s, formError.ngr_date_e]}
                    error={[
                      formError.ngr_date_s.length > 0,
                      formError.ngr_date_e.length > 0,
                    ]}
                  />

                  <TimeRangePicker
                    label="Time"
                    value={[
                      formatDateToString(dateStartTime),
                      formatDateToString(dateEndTime),
                    ]}
                    onChange={[
                      (v) => {
                        onChangeTime(v, "ngr_time_s");
                      },
                      (v) => {
                        onChangeTime(v, "ngr_time_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onBlurTime(stateForm.ngr_time_s, "ngr_time_s");
                      },
                      () => {
                        onBlurTime(stateForm.ngr_time_e, "ngr_time_e");
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onBlurTime(formatDateToString(v, "HHmm"), "ngr_time_s");
                      },
                      (v) => {
                        onBlurTime(formatDateToString(v, "HHmm"), "ngr_time_e");
                      },
                    ]}
                    helperText={[formError.ngr_time_s, formError.ngr_time_e]}
                    error={[
                      formError.ngr_time_s.length > 0,
                      formError.ngr_time_e.length > 0,
                    ]}
                  />

                  <FormControl>
                    <InputLabel>Warehouse</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_warehouse")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect("ngr_warehouse", listWarehouse)
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Area</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_area")}
                      <Button
                        size="small"
                        onClick={() => handleClickSelect("ngr_area", listArea)}
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Reported by"
                    value={stateForm.ngr_reported_by}
                    onChange={(e) => {
                      onChangeText("ngr_reported_by")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Customer (Shipper)</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_customer")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect("ngr_customer", listCustomer)
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Commodity</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_commodity")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect("ngr_commodity", listCommodity)
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Model Name"
                    value={stateForm.ngr_model_name}
                    onChange={(e) => {
                      onChangeText("ngr_model_name")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Quantity"
                    value={stateForm.ngr_quantity}
                    onChange={(e) => {
                      onChangeText("ngr_quantity")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Serial No."
                    value={stateForm.ngr_serial_no}
                    onChange={(e) => {
                      onChangeText("ngr_serial_no")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Damage Location</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_damage_location")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_damage_location",
                            listDamageLocation,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Cause (Site Opinion)"
                    value={stateForm.ngr_cause}
                    onChange={(e) => {
                      onChangeText("ngr_cause")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>QA Authorizer</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_qa_authorizer")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_qa_authorizer",
                            listQAAuthorizer,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Occurred Company</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_occurred_company")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_occurred_company",
                            listOccurredCompany,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Invoice No."
                    value={stateForm.ngr_invoice_no}
                    onChange={(e) => {
                      onChangeText("ngr_invoice_no")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Employee&lsquo;s Affiliation</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_affiliation_of_employee")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_affiliation_of_employee",
                            listAffiliationOfEmployee,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Employee&lsquo;s Experiences</InputLabel>
                  </FormControl>

                  <SelectLabel
                    label="Years"
                    value={stateForm.ngr_experience_of_employee_years}
                    onChange={(e) => {
                      onChangeText("ngr_experience_of_employee_years")(
                        e.target.value,
                      );
                    }}
                  >
                    {renderMenuItem(
                      ExperienceYearsOfEmployee,
                      "name",
                      (item) => item.name,
                      true,
                      "name",
                      "SK",
                    )}
                  </SelectLabel>

                  <SelectLabel
                    label="Months"
                    value={stateForm.ngr_experience_of_employee_months}
                    onChange={(e) => {
                      onChangeText("ngr_experience_of_employee_months")(
                        e.target.value,
                      );
                    }}
                  >
                    {renderMenuItem(
                      ExperienceMonthsOfEmployee,
                      undefined,
                      (item) => item.name,
                      true,
                      "name",
                      "SK",
                    )}
                  </SelectLabel>
                  <TextField
                    label="System Transaction No."
                    value={stateForm.ngr_system_transaction_no}
                    onChange={(e) => {
                      onChangeText("ngr_system_transaction_no")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Waste /Claim</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_waste_claim_category")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_waste_claim_category",
                            listWasteClaim,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Accident Rank</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_accident_rank")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_accident_rank",
                            listAccidentRank,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="CPAR No."
                    value={stateForm.ngr_cpar_no}
                    onChange={(e) => {
                      onChangeText("ngr_cpar_no")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Nonconformity Detail</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_nonconformity_detail")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_nonconformity_detail",
                            listNonconformityDetail,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Nonconformity Overview"
                    value={stateForm.ngr_nonconformity_overview}
                    onChange={(e) => {
                      onChangeText("ngr_nonconformity_overview")(
                        e.target.value,
                      );
                    }}
                  />
                  <TextField
                    label="Occurance Process"
                    value={stateForm.ngr_occurance_process}
                    onChange={(e) => {
                      onChangeText("ngr_occurance_process")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Process of Root Cause"
                    value={stateForm.ngr_process_of_root_cause}
                    onChange={(e) => {
                      onChangeText("ngr_process_of_root_cause")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Responsibility"
                    value={stateForm.ngr_responsibility}
                    onChange={(e) => {
                      onChangeText("ngr_responsibility")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Department of PIC"
                    value={stateForm.ngr_department_of_pic}
                    onChange={(e) => {
                      onChangeText("ngr_department_of_pic")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Process in Logistics"
                    value={stateForm.ngr_process_in_logistics}
                    onChange={(e) => {
                      onChangeText("ngr_process_in_logistics")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Responsibility in Process"
                    value={stateForm.ngr_responsibility_in_process}
                    onChange={(e) => {
                      onChangeText("ngr_responsibility_in_process")(
                        e.target.value,
                      );
                    }}
                  />
                  <TextField
                    label="Classification of Nonconformity"
                    value={stateForm.ngr_classification_of_nonconformity}
                    onChange={(e) => {
                      onChangeText("ngr_classification_of_nonconformity")(
                        e.target.value,
                      );
                    }}
                  />
                  <TextField
                    label="Classification of Cause"
                    value={stateForm.ngr_classification_of_cause}
                    onChange={(e) => {
                      onChangeText("ngr_classification_of_cause")(
                        e.target.value,
                      );
                    }}
                  />
                  <TextField
                    label="Cause Overview"
                    value={stateForm.ngr_cause_overview}
                    onChange={(e) => {
                      onChangeText("ngr_cause_overview")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Root Cause"
                    value={stateForm.ngr_root_cause}
                    onChange={(e) => {
                      onChangeText("ngr_root_cause")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Logistics Equipments</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_logistics_equipments")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_logistics_equipments",
                            listLogisticsEquipments,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Corrective Action /Improvement"
                    value={stateForm.ngr_corrective_action_improvement}
                    onChange={(e) => {
                      onChangeText("ngr_corrective_action_improvement")(
                        e.target.value,
                      );
                    }}
                  />
                  <DateRangePicker
                    label="Completion Improvement Date"
                    value={[
                      stateForm.ngr_completion_improvement_date_s,
                      stateForm.ngr_completion_improvement_date_e,
                    ]}
                    onChange={[
                      (v) => {
                        onChangeDate(v, "ngr_completion_improvement_date_s");
                      },
                      (v) => {
                        onChangeDate(v, "ngr_completion_improvement_date_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onChangeText("ngr_completion_improvement_date_s")(
                          formatDateToString(
                            stateForm.ngr_completion_improvement_date_s,
                            "YMD",
                          ),
                        );
                      },
                      () => {
                        onChangeText("ngr_completion_improvement_date_e")(
                          formatDateToString(
                            stateForm.ngr_completion_improvement_date_e,
                            "YMD",
                          ),
                        );
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onChangeText("ngr_completion_improvement_date_s")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                      (v) => {
                        onChangeText("ngr_completion_improvement_date_e")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                    ]}
                    helperText={[
                      formError.ngr_completion_improvement_date_s,
                      formError.ngr_completion_improvement_date_e,
                    ]}
                    error={[
                      formError.ngr_completion_improvement_date_s.length > 0,
                      formError.ngr_completion_improvement_date_e.length > 0,
                    ]}
                  />
                  <TextField
                    label="Scrap Unit"
                    value={stateForm.ngr_scrap_unit}
                    onChange={(e) => {
                      onChangeText("ngr_scrap_unit")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Repair Unit"
                    value={stateForm.ngr_repair_unit}
                    onChange={(e) => {
                      onChangeText("ngr_repair_unit")(e.target.value);
                    }}
                  />
                  <TextField
                    label="No Problem Unit"
                    value={stateForm.ngr_no_problem_unit}
                    onChange={(e) => {
                      onChangeText("ngr_no_problem_unit")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>Final Payer</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_final_payer")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect("ngr_final_payer", listFinalPayer)
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Expected Payment Month</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_expected_payment_month")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_expected_payment_month",
                            twelveMonths,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <FormControl>
                    <InputLabel>Est/Acc</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_est_acc")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect("ngr_est_acc", listEstAcc)
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Amount Cost (Est)"
                    value={stateForm.ngr_amount_cost_est}
                    onChange={(e) => {
                      onChangeText("ngr_amount_cost_est")(e.target.value);
                    }}
                  />
                  <DateRangePicker
                    label="Closing Date"
                    value={[
                      stateForm.ngr_closing_date_s,
                      stateForm.ngr_closing_date_e,
                    ]}
                    onChange={[
                      (v) => {
                        onChangeDate(v, "ngr_closing_date_s");
                      },
                      (v) => {
                        onChangeDate(v, "ngr_closing_date_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onChangeText("ngr_closing_date_s")(
                          formatDateToString(
                            stateForm.ngr_closing_date_s,
                            "YMD",
                          ),
                        );
                      },
                      () => {
                        onChangeText("ngr_closing_date_e")(
                          formatDateToString(
                            stateForm.ngr_closing_date_e,
                            "YMD",
                          ),
                        );
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onChangeText("ngr_closing_date_s")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                      (v) => {
                        onChangeText("ngr_closing_date_e")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                    ]}
                    helperText={[
                      formError.ngr_closing_date_s,
                      formError.ngr_closing_date_e,
                    ]}
                    error={[
                      formError.ngr_closing_date_s.length > 0,
                      formError.ngr_closing_date_e.length > 0,
                    ]}
                  />
                  <TextField
                    label="Amount Cost (Act)"
                    value={stateForm.ngr_amount_cost_act}
                    onChange={(e) => {
                      onChangeText("ngr_amount_cost_act")(e.target.value);
                    }}
                  />

                  <FormControl>
                    <InputLabel>Actual Payment Month</InputLabel>
                    <StackChip>
                      {renderMultiSelect("ngr_actual_payment_month")}
                      <Button
                        size="small"
                        onClick={() =>
                          handleClickSelect(
                            "ngr_actual_payment_month",
                            twelveMonths,
                          )
                        }
                      >
                        追加
                      </Button>
                    </StackChip>
                  </FormControl>
                  <TextField
                    label="Insurance ,Sub-con Amount"
                    value={stateForm.ngr_insurance_sub_con_amount}
                    onChange={(e) => {
                      onChangeText("ngr_insurance_sub_con_amount")(
                        e.target.value,
                      );
                    }}
                  />
                  <TextField
                    label="Received Invoice No."
                    value={stateForm.ngr_received_invoice_no}
                    onChange={(e) => {
                      onChangeText("ngr_received_invoice_no")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Remark"
                    value={stateForm.ngr_remark}
                    onChange={(e) => {
                      onChangeText("ngr_remark")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Final TLGT Amount"
                    value={stateForm.ngr_final_tlgt_amount}
                    onChange={(e) => {
                      onChangeText("ngr_final_tlgt_amount")(e.target.value);
                    }}
                  />
                </Stack>
              </Collapse>
            </div>
          ) : screenIdSupport[screenId] ==
            TEMPLATE_EDIT_TYPE.TEMPLATE_RECEIVING_PROBLEM_TAG ? (
            //ここ
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>
                  AC Receiving Problem Tag
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <Stack>
                  <FormControl>
                    <DatePickerCustom
                      label={"Date(YYYY/MM/DD)"}
                      value={stateForm.date_ac || null}
                      onChange={(v) => {
                        onChangeText("date_ac")(
                          formatDateToString(v, "YMD_sl"),
                        );
                      }}
                    />
                  </FormControl>
                  <TimePickerCustom
                    label="Time"
                    value={dateTime.time_ac}
                    onChange={(e) => {
                      onChangeTimeAC("time_ac")(e);
                    }}
                  />
                  <TextField
                    label="Customer"
                    value={stateForm.customer_ac}
                    onChange={(e) => {
                      onChangeText("customer_ac")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Commodity"
                    value={stateForm.commodity_ac}
                    onChange={(e) => {
                      onChangeText("commodity_ac")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Model Name"
                    value={stateForm.model_name_ac}
                    onChange={(e) => {
                      onChangeText("model_name_ac")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Quantity"
                    value={stateForm.quantity_ac}
                    onChange={(e) => {
                      onChangeText("quantity_ac")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Serial No."
                    value={stateForm.tl_serial_no}
                    onChange={(e) => {
                      onChangeText("tl_serial_no")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Reported by"
                    value={stateForm.reported_by}
                    onChange={(e) => {
                      onChangeText("reported_by")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Defect"
                    value={stateForm.defect}
                    onChange={(e) => {
                      onChangeText("defect")(e.target.value);
                    }}
                  />
                  <TextField
                    label="Damage Location"
                    value={stateForm.damage_location_ac}
                    onChange={(e) => {
                      onChangeText("damage_location_ac")(e.target.value);
                    }}
                  />
                  <TextField
                    label="CTC QA Judgement"
                    value={stateForm.ctc_qa_judgement}
                    onChange={(e) => {
                      onChangeText("ctc_qa_judgement")(e.target.value);
                    }}
                  />
                  <TextField
                    label="CTC QA Inspector By"
                    value={stateForm.ctc_qa_inspector_by}
                    onChange={(e) => {
                      onChangeText("ctc_qa_inspector_by")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <DatePickerCustom
                      label={"CTC Checked Date(YYYY/MM/DD)"}
                      value={stateForm.ctc_checked_date || null}
                      onChange={(v) => {
                        onChangeText("ctc_checked_date")(
                          formatDateToString(v, "YMD_sl"),
                        );
                      }}
                    />
                  </FormControl>
                  <TextField
                    label="CTC MA Repair by"
                    value={stateForm.ctc_ma_repair_by}
                    onChange={(e) => {
                      onChangeText("ctc_ma_repair_by")(e.target.value);
                    }}
                  />
                  <TextField
                    label="CTC MA Inspector by"
                    value={stateForm.ctc_ma_inspector_by}
                    onChange={(e) => {
                      onChangeText("ctc_ma_inspector_by")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <DatePickerCustom
                      label={"CTC Inspection Date(YYYY/MM/DD)"}
                      value={stateForm.ctc_inspection_date || null}
                      onChange={(v) => {
                        onChangeText("ctc_inspection_date")(
                          formatDateToString(v, "YMD_sl"),
                        );
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <DatePickerCustom
                      label={"Repaired Date(YYYY/MM/DD)"}
                      value={stateForm.repaired_date || null}
                      onChange={(v) => {
                        onChangeText("repaired_date")(
                          formatDateToString(v, "YMD_sl"),
                        );
                      }}
                    />
                  </FormControl>
                  <TextField
                    label="Repaired AC Received by"
                    value={stateForm.repaired_ac_receive_by}
                    onChange={(e) => {
                      onChangeText("repaired_ac_receive_by")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <DatePickerCustom
                      label={"Returning Date(YYYY/MM/DD)"}
                      value={stateForm.returning_date || null}
                      onChange={(v) => {
                        onChangeText("returning_date")(
                          formatDateToString(v, "YMD_sl"),
                        );
                      }}
                    />
                  </FormControl>
                  <TimePickerCustom
                    label="Returning Time"
                    value={dateTime.returning_time}
                    onChange={(e) => {
                      onChangeTimeAC("returning_time")(e);
                    }}
                  />
                </Stack>
              </Collapse>
            </div>
          ) : screenIdSupport[screenId] ==
              TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_TC_REPORT ||
            screenIdSupport[screenId] ==
              TEMPLATE_EDIT_TYPE.EXTERIOR_DAMAGED_DC_REPORT ? (
            <div>
              <Box sx={styles.dialogHeader}>
                <Typography color={"white"}>外装破損商品連絡書</Typography>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setOpenReport(!openReport);
                  }}
                  sx={{ color: "white" }}
                >
                  {openReport ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>
              <Collapse in={openReport} timeout="auto" unmountOnExit>
                <Stack>
                  <FormControl sx={{ mt: 2 }}>
                    <InputLabel>帳票種類</InputLabel>
                    <RadioGroup
                      name="search-type-radio-group"
                      value={
                        stateForm.edr_ledger_type === undefined
                          ? "all"
                          : stateForm.edr_ledger_type
                      }
                      row
                      onChange={(_, value) => {
                        onChangeText("edr_ledger_type")(value);
                      }}
                    >
                      <FormControlLabel
                        value={LEDGER_TYPE.all}
                        label="すべて"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value={LEDGER_TYPE.tc}
                        label="TC"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value={LEDGER_TYPE.dc}
                        label="DC"
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </FormControl>
                  <DateRangePicker
                    label="日付"
                    value={[stateForm.edr_date_s, stateForm.edr_date_e]}
                    onChange={[
                      (v) => {
                        onChangeDate(v, "edr_date_s");
                      },
                      (v) => {
                        onChangeDate(v, "edr_date_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onChangeText("edr_date_s")(
                          formatDateToString(stateForm.edr_date_s, "YMD"),
                        );
                      },
                      () => {
                        onChangeText("edr_date_e")(
                          formatDateToString(stateForm.edr_date_e, "YMD"),
                        );
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onChangeText("edr_date_s")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                      (v) => {
                        onChangeText("edr_date_e")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                    ]}
                    helperText={[formError.edr_date_s, formError.edr_date_e]}
                    error={[
                      formError.edr_date_s.length > 0,
                      formError.edr_date_e.length > 0,
                    ]}
                  />
                  <DateRangePicker
                    label="入荷日"
                    value={[
                      stateForm.edr_arrival_date_s,
                      stateForm.edr_arrival_date_e,
                    ]}
                    onChange={[
                      (v) => {
                        onChangeDate(v, "edr_arrival_date_s");
                      },
                      (v) => {
                        onChangeDate(v, "edr_arrival_date_e");
                      },
                    ]}
                    onBlur={[
                      () => {
                        onChangeText("edr_arrival_date_s")(
                          formatDateToString(
                            stateForm.edr_arrival_date_s,
                            "YMD",
                          ),
                        );
                      },
                      () => {
                        onChangeText("edr_arrival_date_e")(
                          formatDateToString(
                            stateForm.edr_arrival_date_e,
                            "YMD",
                          ),
                        );
                      },
                    ]}
                    onAccept={[
                      (v) => {
                        onChangeText("edr_arrival_date_s")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                      (v) => {
                        onChangeText("edr_arrival_date_e")(
                          formatDateToString(v, "YMD"),
                        );
                      },
                    ]}
                    helperText={[
                      formError.edr_arrival_date_s,
                      formError.edr_arrival_date_e,
                    ]}
                    error={[
                      formError.edr_arrival_date_s.length > 0,
                      formError.edr_arrival_date_e.length > 0,
                    ]}
                  />
                  <TextField
                    label="仕入先名"
                    value={stateForm.edr_supplier_name}
                    onChange={(e) => {
                      onChangeText("edr_supplier_name")(e.target.value);
                    }}
                  />
                  <TextField
                    label="仕入先コード"
                    value={stateForm.edr_supplier_code}
                    onChange={(e) => {
                      onChangeText("edr_supplier_code")(e.target.value);
                    }}
                  />
                  <TextField
                    label="外装破損発見場所"
                    value={stateForm.edr_location}
                    onChange={(e) => {
                      onChangeText("edr_location")(e.target.value);
                    }}
                  />
                  <TextField
                    label="庫内記入者"
                    value={stateForm.edr_written_by}
                    onChange={(e) => {
                      onChangeText("edr_written_by")(e.target.value);
                    }}
                  />
                  <TextField
                    label="確認者"
                    value={stateForm.edr_checked_by}
                    onChange={(e) => {
                      onChangeText("edr_checked_by")(e.target.value);
                    }}
                  />
                  <TextField
                    label="JANコード"
                    value={stateForm.edr_jan_code}
                    onChange={(e) => {
                      onChangeText("edr_jan_code")(e.target.value);
                    }}
                  />
                  <TextField
                    label="商品名"
                    value={stateForm.edr_product_name}
                    onChange={(e) => {
                      onChangeText("edr_product_name")(e.target.value);
                    }}
                  />
                  <TextField
                    label="型名"
                    value={stateForm.edr_product_type}
                    onChange={(e) => {
                      onChangeText("edr_product_type")(e.target.value);
                    }}
                  />
                  <TextField
                    label="総外装破損数量"
                    value={stateForm.edr_total}
                    onChange={(e) => {
                      onChangeText("edr_total")(e.target.value);
                    }}
                  />
                  <TextField
                    label="外装破損状況"
                    value={stateForm.edr_status}
                    onChange={(e) => {
                      onChangeText("edr_status")(e.target.value);
                    }}
                  />
                  <FormControl>
                    <InputLabel>①仕入れました</InputLabel>
                    <RadioGroup
                      value={
                        stateForm.edr_purchasing === undefined
                          ? ""
                          : stateForm.edr_purchasing
                      }
                      row
                      onChange={(_, value) => {
                        onChangeText("edr_purchasing")(
                          value == "" ? undefined : value == "true",
                        );
                      }}
                    >
                      <FormControlLabel
                        value={""}
                        label="指定無し"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value={true}
                        label="あり"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value={false}
                        label="なし"
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    label="お客様注文商品数量"
                    value={stateForm.edr_order_customer_count}
                    onChange={(e) => {
                      onChangeText("edr_order_customer_count")(e.target.value);
                    }}
                  />
                  <TextField
                    label="補充商品、割当商品数量"
                    value={stateForm.edr_order_replenishment_count}
                    onChange={(e) => {
                      onChangeText("edr_order_replenishment_count")(
                        e.target.value,
                      );
                    }}
                  />
                  <FormControl>
                    <InputLabel>②仕入不可</InputLabel>
                    <RadioGroup
                      value={
                        stateForm.edr_non_purchasing === undefined
                          ? ""
                          : stateForm.edr_non_purchasing
                      }
                      row
                      onChange={(_, value) => {
                        onChangeText("edr_non_purchasing")(
                          value == "" ? undefined : value == "true",
                        );
                      }}
                    >
                      <FormControlLabel
                        value={""}
                        label="指定無し"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value={true}
                        label="あり"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value={false}
                        label="なし"
                        control={<Radio />}
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    label="仕入不可商品数量"
                    value={stateForm.edr_non_purchasing_count}
                    onChange={(e) => {
                      onChangeText("edr_non_purchasing_count")(e.target.value);
                    }}
                  />
                  <TextField
                    label="現品確認"
                    value={stateForm.edr_item_check_user}
                    onChange={(e) => {
                      onChangeText("edr_item_check_user")(e.target.value);
                    }}
                  />
                  <TextField
                    label="格納確認"
                    value={stateForm.edr_store_check_user}
                    onChange={(e) => {
                      onChangeText("edr_store_check_user")(e.target.value);
                    }}
                  />
                </Stack>
              </Collapse>
            </div>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box justifyContent={"center"} display={"flex"} width="100%">
          <Button sx={{ mr: 1 }} onClick={handleClear}>
            クリア
          </Button>
          <Button
            onClick={() => {
              onSearch(stateForm);
              onClose();
            }}
            sx={{ mr: 1 }}
            color="secondary"
            disabled={disabledSubmit}
          >
            検索
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  dialogHeader: {
    bgcolor: Colors.MAIN_GREEN,
    py: 1,
    px: 2,
    display: "flex",
    alignItems: "center",
    borderRadius: 1,
  },
} as const;

export default SearchDialog;
