import { createDateText, getEscapedData } from "./";
import _ from "lodash";
import { User, ManualInput } from "services/models";
import { createObjectURL, getBase64Image, getFileByUrl } from "..";

interface IPersonWorker extends User, ManualInput {}

export type DevanReceptionFormType = {
  risky_id: string; //帳票番号
  SK: string;
  report_number: string; //帳票番号
  report_type_code: string;
  report_name: string;
  date_of_delivery: string; //搬入年月日
  delivery_location: string; //搬入場所
  container_no: string; //コンテナ番号
  container_size: string; //コンテナサイズ
  delivery_time: string; //搬入時間
  carrier_name: string; //搬入者名
  unit_class: string; //Unit区分
  invoice_no: string; //インボイスナンバー
  berth_number: string; //バース番号
  seal_no: string; //シールナンバー
  sealed: boolean | undefined; //封印
  container_status: any[]; //コンテナの状態
  model_info: DevanModelInfo[]; //入荷予定情報
  unpacked_number: string; //開梱対象総台数
  no_abnomality_number: string; //開梱後異常なし
  abnomality_report: string; //異常報告
  abnomality_image: Array<Type.ImageInfoType | ImageInfoTypePreview>; //不適合写真
  person_worker: IPersonWorker | null; // 作業者
  person_worker_other: string; // 作業者手入力
  bool_attention: boolean; //注意事項
  bool_persons: boolean; //2人作業
  devan_schedule_id: string; //コンテナ情報のSK
  bool_seal_no: boolean; //シールナンバー確認
  unexpected_list?: DevanModelInfo[]; //予定外情報
  // unit_abnomality_report: string; //memoを含めた異常報告
};

export type DevanModelInfo = {
  SK: string;
  product_class: string; //商品分類
  model_name: string; //機種名
  count: string; //数量カウント
  good_products_number: string; //良品数
  defective_products_number: string; //不適合数
  total_number: string; //合計
  defective_products: DefectiveProducts[]; //不適合内容
  number_of_moves: string; //搬入数
  count_type: keyof typeof CountType;
  good_products_pallet: PalletCount[]; //良品数パレットカウント
  defective_products_pallet: PalletCount[]; //不適合数パレットカウント
  unexpected?: boolean; //予定外か？(true:予定外入荷/false:予定入荷)
  memo?: string; //備考欄
};

export type DefectiveProducts = {
  SK: string; //種類
  defect: string; //名称
  count: string; //数量
};

export const CountType = {
  UNIT: "UNIT",
  PALLET: "PALLET",
};

export type PalletCount = {
  count_pallet: string; //数量
  pallet: string; //パレット箱数
  total: string; //合計
};

export interface ImageInfoTypePreview extends Type.ImageInfoType {
  base64?: string;
}

export const getInfoFromTemplate = (htmlString: string) => {
  let imageProductExample: string = "";
  let pageImageExample: string = "";
  let addPageExample: string = "";
  let addAbnomalReportPageExample: string = "";
  const imageProductExampleArray = htmlString.split("image_product_example");
  if (imageProductExampleArray.length >= 3) {
    imageProductExample = imageProductExampleArray[1];
  }
  const pageImageExampleArray = htmlString.split("page_image_example");
  if (pageImageExampleArray.length >= 3) {
    pageImageExample = pageImageExampleArray[1];
  }
  const addPageExampleArray = htmlString.split("add_page_example");
  if (addPageExampleArray.length >= 3) {
    addPageExample = addPageExampleArray[1];
  }
  const addAbnomalReportPageExampleArray = htmlString.split(
    "abnomality_report_example",
  );
  if (addAbnomalReportPageExampleArray.length >= 3) {
    addAbnomalReportPageExample = addAbnomalReportPageExampleArray[1];
  }

  return {
    imageProductExample,
    pageImageExample,
    addPageExample,
    addAbnomalReportPageExample,
  };
};

export const makeNewHtml = async (
  newHtml: string,
  data: DevanReceptionFormType,
  addPageExample: string,
  addAbnomalReportPageExample: string,
) => {
  data = getEscapedData(data);
  let {
    risky_id, //帳票番号
    date_of_delivery, //搬入年月日
    delivery_location, //搬入場所
    container_no, //コンテナ番号
    container_size, //コンテナサイズ
    delivery_time, //搬入時間
    carrier_name, //搬入者名
    unit_class, //Unit区分
    invoice_no, //インボイスナンバー
    berth_number, //バース番号
    seal_no, //シールナンバー
    sealed, //封印
    container_status, //コンテナの状態
    model_info, //入荷予定情報
    unpacked_number, //開梱対象総台数
    no_abnomality_number, //開梱後異常なし
    abnomality_report, //異常報告
    bool_attention, //注意事項表示
    bool_persons, //2人作業表示
    unexpected_list, //予定外
    // unit_abnomality_report,//異常報告
  } = data;

  const unit_model_list = unexpected_list
    ? [...model_info, ...unexpected_list]
    : model_info;

  // model_infoが4つ以上なら追加ページ
  let model_infos: DevanModelInfo[][] = [];
  for (var i = 0; i < unit_model_list.length; i += 4) {
    let arr = [
      unit_model_list[i],
      unit_model_list[i + 1],
      unit_model_list[i + 2],
      unit_model_list[i + 3],
    ];
    model_infos.push(arr);
  }
  let addPageExampleClone = addPageExample;
  let addAbnomalReportPageExampleClone = addAbnomalReportPageExample;

  if (risky_id) {
    newHtml = newHtml.replace("<!-- {risky_id} -->", risky_id ?? "");
    addPageExampleClone = addPageExampleClone.replace(
      '<span class="hide">{risky_id}</span>',
      risky_id,
    );
  }

  if (model_infos.length == 0) {
    newHtml = newHtml.replace("<!-- {current_page} -->", "1");
    newHtml = newHtml.replace("<!-- {total_page} -->", "1");
  }

  let product_classes: any[] = [];
  unit_model_list.forEach((item, index) => {
    if (!product_classes.includes(item.product_class) && item.product_class) {
      product_classes.push(item.product_class);
    }
  });

  if (product_classes) {
    newHtml = newHtml.replace(
      "<!-- {product_classes} -->",
      product_classes.join(", "),
    );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{product_classes}",
    product_classes.join(", ") ?? "",
  );

  if (date_of_delivery) {
    if (date_of_delivery.split("/").length > 1) {
      newHtml = newHtml.replace(
        "<!-- {year} -->",
        date_of_delivery.split("/")[0],
      );
      newHtml = newHtml.replace(
        "<!-- {month} -->",
        date_of_delivery.split("/")[1],
      );
      newHtml = newHtml.replace(
        "<!-- {day} -->",
        date_of_delivery.split("/")[2],
      );
    }
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{year}",
    date_of_delivery.split("/")[0] ?? "",
  );
  addPageExampleClone = addPageExampleClone.replace(
    "{month}",
    date_of_delivery.split("/")[1] ?? "",
  );
  addPageExampleClone = addPageExampleClone.replace(
    "{day}",
    date_of_delivery.split("/")[2] ?? "",
  );

  if (delivery_location) {
    newHtml = newHtml.replace(
      "<!-- {delivery_location} -->",
      delivery_location ?? "",
    );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{delivery_location}",
    delivery_location ?? "",
  );

  if (container_no) {
    newHtml = newHtml.replace("<!-- {container_no} -->", container_no ?? "");
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{container_no}",
    container_no ?? "",
  );

  if (container_size) {
    newHtml = newHtml.replace(
      "<!-- {container_size} -->",
      container_size ?? "",
    );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{container_size}",
    container_size ?? "",
  );

  if (delivery_time) {
    newHtml = newHtml.replace("<!-- {delivery_time} -->", delivery_time ?? "");
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{delivery_time}",
    delivery_time ?? "",
  );

  if (carrier_name) {
    newHtml = newHtml.replace("<!-- {carrier_name} -->", carrier_name ?? "");
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{carrier_name}",
    carrier_name ?? "",
  );

  if (unit_class) {
    newHtml = newHtml.replace("<!-- {unit_class} -->", unit_class ?? "");
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{unit_class}",
    unit_class ?? "",
  );

  if (invoice_no) {
    newHtml = newHtml.replace("<!-- {invoice_no} -->", invoice_no ?? "");
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{invoice_no}",
    invoice_no ?? "",
  );

  if (berth_number) {
    newHtml = newHtml.replace("<!-- {berth_number} -->", berth_number ?? "");
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{berth_number}",
    berth_number ?? "",
  );

  if (seal_no) {
    newHtml = newHtml.replace("<!-- {seal_no} -->", seal_no ?? "");
  }
  addPageExampleClone = addPageExampleClone.replace("{seal_no}", seal_no ?? "");

  let sealed_str = "";
  if (sealed != undefined) {
    sealed_str = sealed ? "有" : "無";
    newHtml = newHtml.replace("<!-- {sealed} -->", sealed_str);
  }
  addPageExampleClone = addPageExampleClone.replace("{sealed}", sealed_str);

  if (unpacked_number) {
    newHtml = newHtml.replace(
      "<!-- {unpacked_number} -->",
      unpacked_number ?? "",
    );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{unpacked_number}",
    unpacked_number ?? "",
  );

  if (no_abnomality_number) {
    newHtml = newHtml.replace(
      "<!-- {no_abnomality_number} -->",
      no_abnomality_number ?? "",
    );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{no_abnomality_number}",
    no_abnomality_number ?? "",
  );

  let container_status_block = "";
  if (container_status.length > 0) {
    for (let index = 0; index < container_status.length; index++) {
      const item = container_status[index];
      if (item.container_status_img) {
        const container_status_img = await getBase64Image(
          item.container_status_img,
        );
        container_status_block += `
            <div class="container-row">
              <div class="container-str">${item.container_status_name}</div>
              <div
                class="container-image"
                style="
                  background-image: url(${container_status_img});
                "
                alt=""
              ></div>
            </div>`;
      } else {
        container_status_block += `<div class="container-row">${item.container_status_name}</div>`;
      }
    }
    newHtml = newHtml.replace(
      "<!-- {container_status} -->",
      container_status_block,
    );
  } else {
    const before_html = /div-value-container-big-str\shide/g;
    const after_html = `div-value-container-big-str`;
    newHtml = newHtml.replace(before_html, after_html);
    addPageExampleClone = addPageExampleClone.replace(before_html, after_html);
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{container_status}",
    container_status_block,
  );

  // 異常報告
  const abnomality_reports: string[] = [];
  let memoList: string[] = [];
  unit_model_list.forEach((item) => {
    if (item.model_name && item.total_number && item.memo) {
      const memo = item.memo.split("\n").join("");
      memoList.push(item.model_name + " " + item.total_number + "台:" + memo);
    }
  });
  const JOIN_TXT = "　";
  let abnomality_report_text =
    memoList.join(JOIN_TXT).replace(/,/g, "") + JOIN_TXT + abnomality_report;
  if (abnomality_report_text) {
    const abnomality_report_arr: Array<string> = abnomality_report_text.split(/\r?\n/);
    const maxLength = 60;
    abnomality_report_arr.map((item: string, index: number) => {
      if (abnomality_report_arr[index]) {
        if (abnomality_report_arr[index].length <= maxLength) {
          abnomality_reports.push(abnomality_report_arr[index]);
        } else {
          let before_index = index;
          for (
            let i = 0;
            i < abnomality_report_arr[before_index].length;
            i += maxLength
          ) {
            if (abnomality_report_arr[before_index]) {
              abnomality_reports.push(
                abnomality_report_arr[before_index].substring(i, i + maxLength),
              );
            }
          }
        }
      } else {
        abnomality_reports.push("");
      }
    });
  }

  //一ページ目
  for (i = 0; i < 5; i++) {
    newHtml = newHtml.replace(
      `<!-- {abnomality_report_${i}} -->`,
      abnomality_reports[i] ?? "",
    );
  }

  if (!bool_attention) {
    newHtml = newHtml.replace(
      /div-non-conformity-str-block\sdiv-flex-column\sdiv-title-int-margin/g,
      `div-non-conformity-str-block div-flex-column div-title-int-margin hide`,
    );
  }

  if (bool_persons) {
    newHtml = newHtml.replace(
      /div-title-int-5\sdiv-title-int-margin\shide/g,
      `div-title-int-5 div-title-int-margin`,
    );
  }

  model_infos.forEach((model_item, model_index) => {
    if (model_index == 0) {
      newHtml = newHtml.replace(
        "<!-- {current_page} -->",
        String(model_index + 1),
      );

      let total_page = model_infos.length;
      newHtml = newHtml.replace("<!-- {total_page} -->", String(total_page));
      addPageExampleClone = addPageExampleClone.replace(
        "{total_page}",
        String(total_page),
      );

      model_item.forEach((item, index) => {
        if (item?.model_name) {
          let model_name = item.model_name;
          if (!item.unexpected) {
            newHtml = newHtml.replace(
              `<!-- {model_name_${index}} -->`,
              item.model_name,
            );
          } else {
            model_name = `${model_name}<span style='color:#ff0000;'>(予定外)</span>`;
          }
          if (
            !item.unexpected &&
            Number(item.number_of_moves ?? "") !=
              Number(item.total_number ?? "")
          ) {
            model_name = `<span style='color:#ff0000;'>※</span>${model_name}`;
          }
          newHtml = newHtml.replace(
            `<!-- {model_name_0${index}} -->`,
            model_name,
          );
        }

        if (item?.number_of_moves && !item.unexpected) {
          newHtml = newHtml.replace(
            `<!-- {number_of_moves_${index}} -->`,
            item.number_of_moves,
          );
        }

        if (item?.count) {
          let count = item.count.split(/\r?\n/).join("<br/>");
          newHtml = newHtml.replace(`<!-- {count_${index}} -->`, count);
        }

        if (item?.good_products_number) {
          newHtml = newHtml.replace(
            `<!-- {good_products_number_${index}} -->`,
            item.good_products_number,
          );
        }

        if (item?.defective_products_number) {
          newHtml = newHtml.replace(
            `<!-- {defective_products_number_${index}} -->`,
            item.defective_products_number,
          );
        }

        if (item?.total_number) {
          newHtml = newHtml.replace(
            `<!-- {total_number_${index}} -->`,
            item.total_number,
          );
        }

        if (item?.defective_products) {
          item.defective_products.forEach((de_item, de_index) => {
            var number = de_item.defect.substring(0, 1);
            newHtml = newHtml.replace(
              `<!-- {defective_products_${index}_${number}} -->`,
              de_item.count,
            );
          });
        }
      });
    } else {
      let addPageExampleCloneCopy = addPageExampleClone;
      let firstIndex = model_index * 5;
      for (i = 0; i < 5; i++) {
        addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
          `{abnomality_report_${i}}`,
          abnomality_reports[firstIndex + i] ?? "",
        );
      }
      addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
        "{current_page}",
        String(model_index + 1),
      );
      for (i = 0; i < 4; i++) {
        if (model_item[i]) {
          let model_name = model_item[i].model_name ?? "";
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{model_name_${i}}`,
            !model_item[i].unexpected ? model_item[i].model_name : "",
          );

          if (
            !model_item[i].unexpected &&
            model_item[i].model_name &&
            Number(model_item[i]?.number_of_moves ?? "") !=
              Number(model_item[i]?.total_number ?? "")
          ) {
            model_name = `<span style='color:#ff0000;'>※</span>${model_item[i].model_name}`;
          }

          if (model_item[i].unexpected) {
            model_name = `${model_name}<span style='color:#ff0000;'>(予定外)</span>`;
          }

          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{model_name_0${i}}`,
            model_name,
          );
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{number_of_moves_${i}}`,
            model_item[i].number_of_moves && !model_item[i].unexpected
              ? model_item[i].number_of_moves
              : "",
          );

          let count = model_item[i].count.split(/\r?\n/).join("<br/>");
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{count_${i}}`,
            count ?? "",
          );
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{good_products_number_${i}}`,
            model_item[i].good_products_number ?? "",
          );

          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{defective_products_number_${i}}`,
            model_item[i].defective_products_number ?? "",
          );
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{total_number_${i}}`,
            model_item[i].total_number ?? "",
          );
          model_item[i].defective_products.forEach((de_item, de_index) => {
            var number = de_item.defect.substring(0, 1);
            addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
              `{defective_products_${i}_${number}}`,
              de_item.count,
            );
          });
        } else {
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{model_name_${i}}`,
            "",
          );
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{model_name_0${i}}`,
            "",
          );
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{number_of_moves_${i}}`,
            "",
          );

          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{count_${i}}`,
            "",
          );
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{good_products_number_${i}}`,
            "",
          );

          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{defective_products_number_${i}}`,
            "",
          );
          addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
            `{total_number_${i}}`,
            "",
          );
        }
      }
      while (addPageExampleCloneCopy.search(/\{defective_products.*\}/) != -1) {
        addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
          /\{defective_products.*\}/,
          "",
        );
      }
      if (!bool_attention) {
        addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
          /div-non-conformity-str-block\sdiv-flex-column\sdiv-title-int-margin/g,
          `div-non-conformity-str-block div-flex-column div-title-int-margin hide`,
        );
      }

      newHtml = newHtml.replace(
        "<!-- {add_page} -->",
        `
        ${addPageExampleCloneCopy}
        <!-- {add_page} -->
      `,
      );
    }
  });
  //帳票の異常報告欄に異常報告の文章が収まりきらない場合、異常報告用のページを追加(index:0～45の全46行)
  let count = model_infos.length ? model_infos.length * 5 : 5;
  var index = 0;
  while (abnomality_reports.length > count + 45 * index) {
    let addAbnomalReportPageExampleCloneCopy = addAbnomalReportPageExampleClone;
    for (i = 0; i < 46; i++) {
      addAbnomalReportPageExampleCloneCopy =
        addAbnomalReportPageExampleCloneCopy.replace(
          `{abnomality_report_${i}}`,
          abnomality_reports[count + i] ?? "",
        );
    }
    newHtml = newHtml.replace(
      "<!-- {add_abnomal_report_area} -->",
      `
      ${addAbnomalReportPageExampleCloneCopy}
      <!-- {add_abnomal_report_area} -->
    `,
    );
    index++;
  }

  return newHtml;
};

export const getImageArray = async (data: Array<any>) => {
  let image_array: Array<any> = [];
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const uri = item?.uri_jpg ?? item?.uri;
    if ((!item.base64 || item.base64.length === 0) && uri && uri.length > 0) {
      item.base64 = await getBase64Image(uri);
    }
    image_array.push(item);
  }
  return image_array;
};

export const insertReportLocal = (
  newHtml: string,
  data: DevanReceptionFormType,
  imageProductExample: string,
  pageImageExample: string,
  abnomality_image: Array<ImageInfoTypePreview>,
  mergeTemplate = false,
) => {
  data = getEscapedData(data);
  let { date_of_delivery, container_no, invoice_no, delivery_location } = data;

  if (abnomality_image?.length === 0) {
    return newHtml;
  } else {
    const image_array_chunk = _.chunk(abnomality_image, 6);

    for (const imageArray of image_array_chunk) {
      let pageImageExampleClone = pageImageExample;

      pageImageExampleClone = pageImageExampleClone.replace(
        "{date_of_delivery}",
        createDateText(date_of_delivery),
      );

      pageImageExampleClone = pageImageExampleClone.replace(
        "{container_no}",
        container_no ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{invoice_no}",
        invoice_no ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{delivery_location}",
        delivery_location ?? "",
      );

      imageArray.map((item: ImageInfoTypePreview) => {
        if (item?.uri) {
          let imageProductExampleClone = imageProductExample;
          imageProductExampleClone = imageProductExampleClone.replace(
            "{image_url}",
            item?.base64 ? item?.base64 : item?.uri_jpg ?? item?.uri,
          );
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_product_waiting}",
            imageProductExampleClone,
          );
        } else {
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_product_waiting}",
            "",
          );
        }
      });

      pageImageExampleClone = pageImageExampleClone.replaceAll(
        "{image_product_waiting}",
        "",
      );

      newHtml = newHtml.replace(
        "<!-- {add_more_area} -->",
        `
        ${pageImageExampleClone}
        <!-- {add_more_area} -->
      `,
      );
    }

    return newHtml;
  }
};

export const mergeTemplateInspectionReport = async (
  templateUri: string,
  data: DevanReceptionFormType,
) => {
  try {
    const f = await getFileByUrl(templateUri, "template.html", "text/html");
    let html_string = await f.text();
    const res = getInfoFromTemplate(html_string);
    const {
      imageProductExample,
      pageImageExample,
      addPageExample,
      addAbnomalReportPageExample,
    } = res;
    html_string = await makeNewHtml(
      html_string,
      data,
      addPageExample,
      addAbnomalReportPageExample,
    );
    html_string = insertReportLocal(
      html_string,
      data,
      imageProductExample,
      pageImageExample,
      data.abnomality_image,
      true,
    );
    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    const html_blob = new Blob([html_string], { type: "text/html" });
    templateUri = createObjectURL(html_blob);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};

export const handleEditInfoInspectionReport = (templateEditInfo: any) => {
  const newTemplateEditInfo = { ...templateEditInfo };
  return newTemplateEditInfo;
};

export const initDevanModelInfo = {
  SK: "",
  product_class: "", //商品分類
  model_name: "", //機種名
  count: "", //数量カウント
  good_products_number: "", //良品数
  defective_products_number: "", //不適合数
  total_number: "", //合計
  defective_products: [] as DefectiveProducts[], //不適合内容
  number_of_moves: "", //搬入数
  count_type: "UNIT" as const,
  good_products_pallet: [] as PalletCount[], //良品数パレットカウント
  defective_products_pallet: [] as PalletCount[], //不適合数パレットカウント
  unexpected: true, //予定外
};
