import { TYPES } from "store/types";

export type State = {
  loggedIn: boolean;
  is_password_reset: boolean;
  group_id: string;
};

const initialState: State = {
  loggedIn: false,
  is_password_reset: false,
  group_id: ""
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TYPES.SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        group_id: action.payload.group_id
      };

    case TYPES.SET_AUTH:
      return {
        ...state,
        ...action.payload,
      };

    case TYPES.CLEAR_AUTH:
      return initialState;

    default:
      return state;
  }
};
