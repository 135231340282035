import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormLabel,
  Grid,
} from "@mui/material";
import _, { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import AvatarRes from "components/atoms/AvatarRes";
import { Colors } from "@template/style";
import { ApproveState } from "store/reducer/ApproveFlowReducer";
import { TYPES } from "store/types";
import messages from "config/messages";
const ListMemberArea = () => {
  const dispatch = useDispatch();
  const approveState: ApproveState = useSelector(
    (state: RootState) => state.approveFlow,
  );
  const {
    currentStepId,
    clickOnSearch,
    apiLoading,
    stepList,
    openMemberSearchDialog,
    maxUserLocation,
  } = approveState;
  const [clonedMemberList, setClonedMemberList] = useState<Type.Member[]>([]);
  const [selectIsDisabled, setSelectIsDisabled] = useState<boolean>(true);
  const [selectedAllMembers, setSelectedAllMembers] = useState<any[]>([]);
  useEffect(() => {
    const cloneStepList = cloneDeep([...stepList]);
    if (openMemberSearchDialog) {
      const isCheckAll = cloneStepList.find(
        (item) =>
          item.stepId === currentStepId &&
          item.currentSearch.every((e) =>
            item.approvers.find(
              (approver) =>
                approver.memberId.split("|")[0] === e.memberId.split("|")[0]
            )
          )
      );

      const headerItem = {
        groupId: "",
        memberId: "headerItemId",
        name: "名前",
        linkedGroup: "所属グループ",
        isSelected: !!isCheckAll,
        linkAvatar: "",
        SK: "",
        PK: "",
        isDisable: false,
      };

      const currentSearch =
        cloneStepList.find((item) => {
          if (item.stepId === currentStepId) {
            item.currentSearch = item.currentSearch.map((e) => {
              const fixedEMemberId = e.memberId.split("|")[0];

              const isApproverSelected = item.approvers.some((approver) => {
                const fixedApproverMemberId = approver.memberId.split("|")[0];
                return (
                  approver.memberId === e.memberId ||
                  fixedEMemberId.includes(fixedApproverMemberId) ||
                  fixedApproverMemberId.includes(fixedEMemberId)
                );
              });

              const currentSelectedAllMembers = selectedAllMembers.filter(
                (item) => item.stepId === currentStepId
              );

              if (
                currentSelectedAllMembers.length &&
                currentSelectedAllMembers[0].approvers.length
              ) {
                currentSelectedAllMembers[0].approvers.forEach((el: any) => {
                  const fixedClonedMemberId = el.memberId.split("|")[0];
                  if (
                    el.isSelected &&
                    (el.memberId === e.memberId ||
                      fixedEMemberId.includes(fixedClonedMemberId) ||
                      fixedClonedMemberId.includes(fixedEMemberId))
                  ) {
                    e.isSelected = true;
                  }
                });
              } else {
                e.isSelected = isApproverSelected;
              }

              return e;
            });
            return true;
          }
          return false;
        })?.currentSearch || [];
      setClonedMemberList([headerItem, ...currentSearch]);

      const currentStep = cloneStepList.find(
        (item) => item.stepId === currentStepId
      );

      if (currentStep && Array.isArray(currentStep.approvers)) {
        setSelectIsDisabled(currentStep.approvers.length === 0);
      } else {
        setSelectIsDisabled(true);
      }

      if (!selectedAllMembers.length) {
        setSelectedAllMembers(cloneStepList);
      }
    } else {
      setSelectedAllMembers([]);
    }
  }, [currentStepId, stepList, openMemberSearchDialog]);


  const renderAccountArea = (name: string, linkAvatar: string) => {
    return (
      <>
        <AvatarRes size={"md"} sx={{ marginRight: 1 }} src={linkAvatar} />
        <FormLabel sx={styles.linkedGroupPosition}>
          <span className="IgnoreExtractRuleTarget">{name}</span>
        </FormLabel>
      </>
    );
  };

  useEffect(() => {
    const finalSelectedMembers = clonedMemberList.filter(
      (item) => item.isSelected && item.memberId !== "headerItemId",
    );
    updateSelectedAllMembers(finalSelectedMembers)
  }, [clonedMemberList]);
  const handleClickOnCheckbox = (index: number) => {
    const updatedMemberList = [...clonedMemberList];
    updatedMemberList[index].isSelected = !updatedMemberList[index].isSelected;
    for (let i = 0; i < updatedMemberList.length; i++) {
      let fixedIMemberId = updatedMemberList[i].memberId.split("|")[0];
      let fixedIndexMemberId = updatedMemberList[index].memberId.split("|")[0];
      if (
        i !== index &&
        (fixedIMemberId.includes(fixedIndexMemberId)
          || fixedIndexMemberId.includes(fixedIMemberId)
        )
      ) {
        if (updatedMemberList[index].isSelected) {
          updatedMemberList[index].isDisable = false;
          updatedMemberList[i].isSelected = false;
          updatedMemberList[i].isDisable = true;
        } else {
          updatedMemberList[i].isDisable = false;
        }
      }
    }
    if (index === 0) {
      const map = new Map();
      updatedMemberList.forEach((item) => {
        if (updatedMemberList[index].isSelected) {
          if (item.isDisable) {
            item.isSelected = false;
          } else {
            if (map.get(item.memberId.split("|")[0])) {
              item.isSelected = false;
              item.isDisable = true;
            } else {
              map.set(item.memberId.split("|")[0], 1);
              item.isDisable = false;
              item.isSelected = true;
            }
          }
        } else {
          item.isSelected = updatedMemberList[index].isSelected;
          item.isDisable = false;
        }
      });
    } else {
      updatedMemberList[0].isSelected = !updatedMemberList.some(
        (item, index) => item.isSelected === false && index != 0,
      );
      const map = new Map();
      updatedMemberList.forEach((item, index) => {
        if (!map.get(item.memberId.split("|")[0]) && index !== 0) {
          map.set(item.memberId.split("|")[0], item.isSelected ? 1 : -1);
        }
      });
      const array = Array.from(map, ([name, value]) => ({ name, value }));
      updatedMemberList[0].isSelected = array.every((item) => item.value === 1);
    }
    setClonedMemberList(updatedMemberList);
  };
  const renderItem = (index: number, member: Type.Member) => {
    return (
      <Box
        key={`${member.memberId}|${member.linkedGroup}`}
        sx={styles.memberRowContainer}
      >
        <Grid sx={styles.memberRow} container spacing={1}>
          <Grid sx={styles.linkedGroupPosition} item xs={1}>
            <Checkbox
              disabled={member.isDisable}
              checked={member.isSelected}
              onClick={() => handleClickOnCheckbox(index)}
            />
          </Grid>
          <Grid sx={styles.linkedGroupPosition} item xs={3}>
            {renderAccountArea(member.name, member.linkAvatar)}
          </Grid>
          <Grid sx={styles.linkedGroupPosition} item xs={8}>
            <FormLabel sx={styles.linkedGroupPosition}>
              <span className="IgnoreExtractRuleTarget">
                {member.linkedGroup}
              </span>
            </FormLabel>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderHeader = () => {
    if (
      !clonedMemberList ||
      (clonedMemberList && clonedMemberList.length === 0)
    ) {
      return <></>;
    }
    const index = 0;
    const member = clonedMemberList[0];
    return (
      <Box sx={styles.headerContainer}>
        <Grid
          key={member.memberId}
          sx={styles.headerArea}
          container
          spacing={1}
        >
          <Grid sx={styles.linkedGroupPosition} item xs={1}>
            <Checkbox
              checked={member.isSelected}
              onClick={() => handleClickOnCheckbox(index)}
            />
          </Grid>
          <Grid sx={styles.linkedGroupPosition} item xs={3}>
            <FormLabel sx={styles.linkedGroupPosition}>{member.name}</FormLabel>
          </Grid>
          <Grid sx={styles.linkedGroupPosition} item xs={8}>
            <FormLabel sx={styles.linkedGroupPosition}>
              {member.linkedGroup}
            </FormLabel>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderMemberList = useMemo(() => {
    if (
      !clonedMemberList ||
      (clonedMemberList && clonedMemberList.length === 0)
    ) {
      return <></>;
    }
    return (
      <Box sx={styles.memberListContainer}>
        {clonedMemberList.map((member, index) => {
          if (index !== 0) {
            return renderItem(index, member);
          }
        })}
      </Box>
    );
  }, [clonedMemberList]);

  const handlingAddErrorMessageForStep = (
    numOfApprover: number,
    approvers: Type.Member[],
    index: number,
  ) => {
    let result = "";
    const pattern = /^[1-9]\d*$/;
    if (!pattern.test(numOfApprover.toString()) || approvers.length === 0) {
      result = `承認ステップ${index + 1}に承認者が設定されていません`;
    }

    if (numOfApprover > maxUserLocation) {
      result = messages.ManageScreen.MSG_GREATER_THAN_MAX_APPROVERS;
    } else if (numOfApprover > approvers.length) {
      result = `承認ステップ${index + 1}の承認者が不足しています`;
    }

    return result;
  };

  const updateSelectedAllMembers = (selectedMembers: any[]) => {
    const targetStep: Type.StepDetail | undefined = selectedAllMembers.find(
      (item) => item.stepId == currentStepId,
    );
    if (targetStep) {
      for (let mem of selectedMembers) {
        if (
          !targetStep.approvers.find(
            (e) => {
              let fixedEMemberId = e.memberId.split("|")[0];
              let fixedMEMMemberId = mem.memberId.split("|")[0];
              return fixedEMemberId.includes(fixedMEMMemberId) ||
                fixedMEMMemberId.includes(fixedEMemberId);
            }
          )
        ) {
          targetStep.approvers.push(mem);
        }
      }
      //clonedMemberListに含まれるが、selectedでfalseの項目が、targetStepに含まれる場合は、削除する
      const deleteSelectedMembers = clonedMemberList.filter(
        (item) => !item.isSelected && item.memberId !== "headerItemId",
      );
      for (let delMem of deleteSelectedMembers) {
        targetStep.approvers = targetStep.approvers.filter((e) => {
          let fixedEMemberId = e.memberId.split("|")[0];
          let fixedMEMMemberId = delMem.memberId.split("|")[0];
          return !fixedEMemberId.includes(fixedMEMMemberId) &&
            !fixedMEMMemberId.includes(fixedEMemberId);
        });
      }

      targetStep.currentSearch = clonedMemberList.filter(
        (item) => item.memberId !== "headerItemId",
      );
      setSelectIsDisabled(targetStep.approvers.length > 0 ? false : true);
      setSelectedAllMembers(selectedAllMembers);
    }
  }

  const setSelectedMember = () => {
    const targetStep: Type.StepDetail | undefined = selectedAllMembers.find(
      (item) => item.stepId == currentStepId,
    );
    const oldTargetStep: Type.StepDetail | undefined = stepList.find(
      (item) => item.stepId == currentStepId,
    );
    const index = selectedAllMembers.findIndex((item) => item.stepId == currentStepId);
    if (targetStep) {
      const errorMessage = handlingAddErrorMessageForStep(
        targetStep.numOfApprover,
        targetStep.approvers,
        index,
      );
      targetStep.errorMessage = errorMessage;
      targetStep.isValidStep = targetStep.isValidStep
        ? targetStep.isValidStep
        : errorMessage.length === 0;
      let deletedMember = []
      if (oldTargetStep) {
        const differentElements = oldTargetStep.approvers.filter(
          oldApprover => !targetStep.approvers.some(targetApprover =>
            oldApprover.memberId === targetApprover.memberId
          ));
        for (let dif of differentElements) {
          const { SK, PK } = dif;
          if (SK && PK) {
            deletedMember.push({ SK, PK });
          }
        }
      }
      dispatch({
        type: TYPES.SET_APPROVER_FOR_STEP_AND_DELETE_APPROVER,
        payload: { deletedStepList: [...deletedMember], stepList: [...selectedAllMembers] },
      });
    }
  };

  if (apiLoading) {
    return (
      <Box sx={[styles.linkedGroupPosition, styles.emptyListSearch]}>
        <CircularProgress />
      </Box>
    );
  }
  if (clonedMemberList.length === 1) {
    if (!clickOnSearch) {
      return <></>;
    }
    return (
      <Box style={styles.emptyListSearch}>
        <FormLabel>{messages.ManageScreen.MSG_NO_ITEM}</FormLabel>
      </Box>
    );
  }
  return (
    <Box sx={{ mx: 3, my: 2 }}>
      <>{renderHeader()}</>
      <>{renderMemberList}</>
      <Box sx={styles.searchBtnCustomPosition}>
        <Button
          disabled={selectIsDisabled}
          color="secondary"
          onClick={setSelectedMember}
        >
          選択
        </Button>
      </Box>
    </Box>
  );
};

export default ListMemberArea;

const styles = {
  linkedGroupPosition: {
    display: "flex",
    alignItems: "center",
    padding: 0,
  },
  memberListContainer: {
    height: "40vh",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderWidth: 2,
    borderColor: Colors.BORDER,
    borderStyle: "solid",
    borderTop: "none",
    overflowY: "scroll",
  },
  memberRowContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  memberRow: {
    height: 65,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: Colors.BORDER,
    width: "100%",
    marginLeft: 2,
    // marginRight: 2
  },
  searchBtnCustomPosition: {
    marginTop: 2,
    display: "flex",
    justifyContent: "center",
  },
  headerContainer: {
    height: 50,
    marginTop: 0,
    borderWidth: 2,
    borderColor: Colors.BORDER,
    borderStyle: "solid",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  headerArea: {
    marginLeft: 2,
    borderBottom: "1px solid #C7CCD6",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  emptyListSearch: {
    display: "flex",
    justifyContent: "center",
  },
} as const;
