import { getSystemByPK } from "@api/system";
import { createExcelTemplate, getSignedUrlFile } from "@api/template";
import { Cancel } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import ModalController from "@shared-components/modal/ModalController";
import { EXCEL_TEMPLATE_INPUT, SYSTEM_KEY } from "@shared-constants";
import { Colors } from "@template/style";
import { createFormUpload, fileDownload, getFileByUrl } from "@utils/index";
import { Validation } from "@validation";
import CancelBtnBadge from "components/atoms/CancelBtnBadge";
import Dropzone from "components/atoms/Dropzone";
import LabelRequired from "components/atoms/LabelRequired";
import SelectLabel from "components/atoms/SelectLabel";
import DropzoneDialog from "components/molecules/DropzoneDialog";
import _ from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CreateTemplate from "screens/CreateTemplate";
import {
  CreateExcelTemplate,
  TemplateExcel,
  TemplateExcelExtra,
} from "services/models";

interface IProps {
  template?: TemplateExcel;
  isNew?: boolean;
  editable?: boolean;
  onChange?: Function;
  onError?: Function;
  activityBaseId: string;
  isDisabled?: boolean;
}

const initial_data: TemplateExcel = {
  PK: "",
  SK: "",
  excel_path: "",
  excel_name: "",
  start_col: "A",
  start_row: "1",
  end_col: "A",
  end_row: "1",
  sheet_name: "",
  font: "sans-serif",
  paper_size: "",
  horizontal_pixel_size: "",
  vertical_pixel_size: "",
  is_landscape: false,
  data_info: [],
  excel: undefined,
};

const initial_data_extra: TemplateExcelExtra = {
  excel_path: "",
  excel_name: "",
  start_col: "A",
  start_row: "1",
  end_col: "A",
  end_row: "1",
  sheet_name: "",
  excel: undefined,
  data_info: [],
};

interface IFormError {
  excel: string;
  start_row: string;
  end_row: string;
  sheet_name: string;
}

const initial_form_error = {
  excel: "",
  start_row: "",
  end_row: "",
  sheet_name: "",
};

const TemplateCreateBox = (
  {
    template,
    isNew = false,
    editable = true,
    onChange = () => {},
    onError = () => {},
    activityBaseId,
    isDisabled = false,
  }: IProps,
  ref: any,
) => {
  const [excelForm, setExcelForm] = useState<TemplateExcel>(initial_data);
  const [originData, setOriginData] = useState<TemplateExcel>(initial_data);
  const [formError, setFormError] = useState<IFormError>({
    excel: "",
    start_row: "",
    end_row: "",
    sheet_name: "",
  });
  const [extraFormError, setExtraFormError] = useState<IFormError[]>([]);
  const [openDropzone, setOpenDropzone] = useState(false);
  const [openTemplateCreate, setOpenTemplateCreate] = useState(false);
  const [htmlData, setHtmlData] = useState<Array<CreateExcelTemplate>>([]);
  const [tableRowsHeight, setTableRowsHeight] = useState<Array<string>>([]);
  const [htmlString, setHtmlString] = useState<string>("");
  const [listPaperSize, setListPaperSize] = useState<Array<any>>([]);
  const [listInputName, setListInputName] = useState<Array<string>>([]);
  const [clearStateForm, setClearStateForm] = useState<boolean>(false);
  const [extraPage, setExtraPage] = useState<
    Array<{
      tableRowsHeight: Array<string>;
      htmlString: string;
      listInputName: Array<string>;
      data: Array<any>;
      htmlData: Array<any>;
    }>
  >([]);
  const excelInfoRef = useRef<any>();
  const createTemplateRef = useRef<any>();

  useImperativeHandle(ref, () => ({
    stateForm: {
      ...excelForm,
      data_info: createTemplateRef.current.allStateForm[0],
      extra_template:
        excelForm.extra_template === undefined
          ? []
          : excelForm.extra_template.map((item, index) => ({
              excel_name: item.excel?.path ?? item.excel?.name,
              start_col: item.start_col,
              start_row: item.start_row,
              end_col: item.end_col,
              end_row: item.end_row,
              sheet_name: item.sheet_name,
              data_info: createTemplateRef.current.allStateForm[index + 1],
            })),
      extra_file:
        excelForm.extra_template === undefined
          ? []
          : excelForm.extra_template.map((item) => item.excel),
    },
    formError: formError,
    validationAllCheck: validationAllCheck,
    hasUpdateData: hasUpdateData,
  }));

  useEffect(() => {
    getSystemByPK(SYSTEM_KEY.PAPER_SIZE).then((res) => {
      if (res?.data) {
        setListPaperSize(res.data);
        if (res.data.length > 0 && excelForm.paper_size == "") {
          setExcelForm((prev) => ({
            ...prev,
            paper_size: res.data[0].paper_size,
            horizontal_pixel_size: res.data[0].horizontal_pixel_size,
            vertical_pixel_size: res.data[0].vertical_pixel_size,
          }));
          setOriginData((prev) => ({
            ...prev,
            paper_size: res.data[0].paper_size,
            horizontal_pixel_size: res.data[0].horizontal_pixel_size,
            vertical_pixel_size: res.data[0].vertical_pixel_size,
          }));
        }
      }
    });
  }, []);

  const setExcelTemplateData = async (template?: TemplateExcel) => {
    if (template) {
      // 画像設定の署名付きURLを取得
      const list_draw_image = template.data_info.filter(
        (item) => item.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
      );
      for (const [index, item] of list_draw_image.entries()) {
        if (item.image?.path_file) {
          const newUri = await getSignedUrlFile(item.image.path_file).then(
            (res) => {
              if (res?.link_url) {
                return res.link_url;
              }
            },
          );
          item.image.uri = newUri;
          item.image.non_Local = true;
        }
      }
      setExcelForm(template);
      setOriginData(template);
      if (template.data_info && template.data_info.length) {
        // 登録済みの項目名を取得
        const list_input_name = template.data_info
          .filter((item) => item.input !== EXCEL_TEMPLATE_INPUT.IMAGE_PAGE)
          .map((item) => item.name);
        setListInputName(list_input_name);
      }
      if (template.excel.non_Local) {
        excelInfoRef.current = template;
      }
      let newExtraFormError: IFormError[] = [];
      template.extra_template?.forEach(() => {
        newExtraFormError.push(_.cloneDeep(initial_form_error));
      });
      setExtraFormError(newExtraFormError);
    }
  };

  useEffect(() => {
    setExcelTemplateData(template);
    if (template) setTemplateForExtraPage(template);
  }, [template]);

  useEffect(() => {
    onChange(excelForm);
    onError(!validationAllCheck(false));
  }, [excelForm]);

  // ------------------------------------------------------------------
  // エクセル取り込み
  // ------------------------------------------------------------------
  const handleImportExcel = async () => {
    const excel_info = { ...excelForm };
    if (
      excelInfoRef.current &&
      _.isEqual(excelInfoRef.current, excel_info) &&
      htmlString != ""
    ) {
      // Excelの変更がない場合、HTML取得せずにテンプレート設定を開く
      setClearStateForm(false);
      setOpenTemplateCreate(true);
      return;
    }

    if (!validationAllCheck()) return;

    try {
      LoadingOverlayController.show();
      const data = {
        range: `${excelForm.start_col}${excelForm.start_row}:${excelForm.end_col}${excelForm.end_row}`,
        sheet_name: excelForm.sheet_name,
        font_name: excelForm.font,
        is_landscape: excelForm.is_landscape,
        horizontal: excelForm.horizontal_pixel_size,
        vertical: excelForm.vertical_pixel_size,
      };
      let excel = excelForm.excel;
      if (excel.non_Local) {
        // 複製、編集時はファイルを取得
        excel = await getExcelFile(excel.uri, excel.path);
        setExcelForm((prev) => ({ ...prev, excel: excel }));
      }
      const delay = 300;
      var timeout = 0;
      var startTime = 0;
      let formData = createFormUpload(excel, data);
      const res = await createExcelTemplate(formData);
      startTime = Date.now();
      if (res) {
        if (excelForm.extra_template) {
          let cloneExtraPage = _.cloneDeep(extraPage);
          let cloneExtraTemplate = _.cloneDeep(excelForm.extra_template);
          cloneExtraPage = [];
          for (const [index, item] of excelForm.extra_template.entries()) {
            const data = {
              range: `${item.start_col}${item.start_row}:${item.end_col}${item.end_row}`,
              sheet_name: item.sheet_name,
              font_name: excelForm.font,
              is_landscape: excelForm.is_landscape,
              horizontal: excelForm.horizontal_pixel_size,
              vertical: excelForm.vertical_pixel_size,
            };
            let excel = item.excel;
            if (excel.non_Local) {
              // 複製、編集時はファイルを取得
              excel = await getExcelFile(excel.uri, excel.path);
              cloneExtraTemplate[index].excel = excel;
            }
            let formData = createFormUpload(excel, data);
            timeout = delay - (Date.now() - startTime);
            if (timeout > 0)
              await new Promise((resolve) => setTimeout(resolve, timeout)); // 連続アップロード回避
            const res = await createExcelTemplate(formData);
            startTime = Date.now();
            if (res) {
              cloneExtraPage[index] = {
                htmlString: res.html,
                tableRowsHeight: JSON.parse(JSON.stringify(res.rows_height)),
                listInputName: [],
                data: [],
                htmlData: JSON.parse(JSON.stringify(res.response)),
              };
            }
            if (
              template?.extra_template &&
              template?.extra_template.length > index
            ) {
              cloneExtraPage[index]["listInputName"] = template?.extra_template[
                index
              ].data_info
                .filter(
                  (item) => item.input !== EXCEL_TEMPLATE_INPUT.IMAGE_PAGE,
                )
                .map((item) => item.name);
              cloneExtraPage[index]["data"] =
                template?.extra_template[index].data_info;
            }
          }
          setExtraPage(cloneExtraPage);
          setExcelForm({ ...excelForm, extra_template: cloneExtraTemplate });
        }
        setClearStateForm(IsChangeTemplateExcel(excel_info));
        setHtmlData(JSON.parse(JSON.stringify(res.response)));
        setTableRowsHeight(JSON.parse(JSON.stringify(res.rows_height)));
        setHtmlString(res.html);
        setOpenTemplateCreate(true);
        excelInfoRef.current = excel_info;
      }
    } catch (e: any) {
      console.log(e);
      ModalController.show({ message: e?.detail, visibleButton2: true });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const setTemplateForExtraPage = async (template: TemplateExcel) => {
    //extraPageの初期値を設定する
    const excel_info = { ...template };
    try {
      LoadingOverlayController.show();
      const data = {
        range: `${template.start_col}${template.start_row}:${template.end_col}${template.end_row}`,
        sheet_name: template.sheet_name,
        font_name: template.font,
        is_landscape: template.is_landscape,
        horizontal: template.horizontal_pixel_size,
        vertical: template.vertical_pixel_size,
      };
      let excel = template.excel;
      if (excel.non_Local) {
        // 複製、編集時はファイルを取得
        excel = await getExcelFile(excel.uri, excel.path);
        setExcelForm((prev) => ({ ...prev, excel: excel }));
      }
      const delay = 300;
      var timeout = 0;
      var startTime = 0;
      let formData = createFormUpload(excel, data);
      const res = await createExcelTemplate(formData);
      startTime = Date.now();
      if (res) {
        if (template.extra_template) {
          let cloneExtraPage = _.cloneDeep(extraPage);
          let cloneExtraTemplate = _.cloneDeep(template.extra_template);
          cloneExtraPage = [];
          for (const [index, item] of template.extra_template.entries()) {
            const data = {
              range: `${item.start_col}${item.start_row}:${item.end_col}${item.end_row}`,
              sheet_name: item.sheet_name,
              font_name: template.font,
              is_landscape: template.is_landscape,
              horizontal: template.horizontal_pixel_size,
              vertical: template.vertical_pixel_size,
            };
            let excel = item.excel;
            if (excel.non_Local) {
              // 複製、編集時はファイルを取得
              excel = await getExcelFile(excel.uri, excel.path);
              cloneExtraTemplate[index].excel = excel;
            }
            let formData = createFormUpload(excel, data);
            timeout = delay - (Date.now() - startTime);
            if (timeout > 0)
              await new Promise((resolve) => setTimeout(resolve, timeout)); // 連続アップロード回避
            const res = await createExcelTemplate(formData);
            startTime = Date.now();
            if (res) {
              cloneExtraPage[index] = {
                htmlString: res.html,
                tableRowsHeight: JSON.parse(JSON.stringify(res.rows_height)),
                listInputName: [],
                data: [],
                htmlData: JSON.parse(JSON.stringify(res.response)),
              };
            }
            if (
              template?.extra_template &&
              template?.extra_template.length > index
            ) {
              cloneExtraPage[index]["listInputName"] = template?.extra_template[
                index
              ].data_info
                .filter(
                  (item) => item.input !== EXCEL_TEMPLATE_INPUT.IMAGE_PAGE,
                )
                .map((item) => item.name);
              cloneExtraPage[index]["data"] =
                template?.extra_template[index].data_info;
            }
          }
          setExtraPage(cloneExtraPage);
          setExcelForm((prev) => ({
            ...prev,
            extra_template: cloneExtraTemplate,
          }));
        }
        setClearStateForm(IsChangeTemplateExcel(excel_info));
        setHtmlData(JSON.parse(JSON.stringify(res.response)));
        setTableRowsHeight(JSON.parse(JSON.stringify(res.rows_height)));
        setHtmlString(res.html);
        excelInfoRef.current = excel_info;
      }
    } catch (e: any) {
      console.log(e);
      ModalController.show({ message: e?.detail, visibleButton2: true });
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const getExcelFile = async (uri: string, path: string) => {
    return await getFileByUrl(
      uri,
      path,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    );
  };

  const IsChangeTemplateExcel = (excel_info: TemplateExcel) => {
    if (!excelInfoRef.current) return false;
    const clearStateFormKey: (keyof TemplateExcel)[] = [
      "excel_path",
      "sheet_name",
      "start_col",
      "start_row",
      "end_col",
      "end_row",
    ];
    let response = false;
    clearStateFormKey.forEach((key) => {
      if (!_.isEqual(excel_info[key], excelInfoRef.current[key])) {
        response = true;
      }
    });
    if (excel_info.extra_template) {
      if (!excelInfoRef.current.extra_template) {
        response = true;
      } else {
        excel_info.extra_template.forEach((value, index) => {
          clearStateFormKey.forEach((key) => {
            if (
              !excelInfoRef.current.extra_template[index] ||
              !_.isEqual(
                value[key as keyof TemplateExcelExtra],
                excelInfoRef.current.extra_template[index][key],
              )
            ) {
              response = true;
            }
          });
        });
      }
    }
    return response;
  };

  // ------------------------------------------------------------------
  // 入力項目
  // ------------------------------------------------------------------
  const validationAllCheck = (update: boolean = true) => {
    let mess_excel = validate("excel", undefined, update);
    let mess_row_s = validate("start_row", undefined, update);
    let mess_row_e = validate("end_row", undefined, update);
    let mess_sheet_name = validate("sheet_name", undefined, update);
    const form_error = {
      excel: mess_excel,
      start_row: mess_row_s,
      end_row: mess_row_e,
      sheet_name: mess_sheet_name,
    };
    let mess_extra_form: IFormError[] = [];
    excelForm.extra_template?.map((value, index) => {
      let mess_excel = validate("excel", value.excel, false);
      let mess_row_s = validate("start_row", value.start_row, false);
      let mess_row_e = validate("end_row", value.end_row, false);
      let mess_sheet_name = validate("sheet_name", value.sheet_name, false);
      mess_extra_form[index] = {
        excel: mess_excel,
        start_row: mess_row_s,
        end_row: mess_row_e,
        sheet_name: mess_sheet_name,
      };
    });
    if (update) {
      setFormError(form_error);
      setExtraFormError(mess_extra_form);
    }

    return (
      !Object.values(form_error).some((value) => value != "") &&
      !mess_extra_form.some((form) =>
        Object.values(form).some((value) => value != ""),
      )
    );
  };

  const validate = (
    field: keyof typeof excelForm | "excel",
    value?: any,
    update: boolean = true,
  ) => {
    let message = "";
    switch (field) {
      case "excel":
        const validate_value = value !== undefined ? value : excelForm.excel;
        message = Validation.validate({
          type: "text",
          value: validate_value ? "true" : "",
          name: "Excelファイル",
          required: true,
        });
        break;
      case "start_row":
        message = Validation.validate({
          type: "number",
          value: value !== undefined ? value : excelForm.start_row,
          name: "取込み範囲（開始）",
          required: true,
          min_value: 1,
        });
        break;
      case "end_row":
        message = Validation.validate({
          type: "number",
          value: value !== undefined ? value : excelForm.end_row,
          name: "取込み範囲（終了）",
          required: true,
          min_value: 1,
        });
        break;
      case "sheet_name":
        message = Validation.validate({
          type: "text",
          value: value !== undefined ? value : excelForm.sheet_name,
          name: "取込み対象シート名",
          required: true,
        });
        break;
    }
    if (update) setFormError({ ...formError, [field]: message });
    return message;
  };

  const hasUpdateData = () => {
    return (
      (excelForm && originData && !_.isEqual(excelForm, originData)) ||
      (createTemplateRef.current &&
        !_.isEqual(
          createTemplateRef.current.stateForm,
          template?.data_info ?? [],
        ))
    );
  };

  // ------------------------------------------------------------------
  // ダウンロード
  // ------------------------------------------------------------------
  const handleDownload = async (file_name: string, uri: string) => {
    await fileDownload(file_name, uri);
  };

  return (
    <>
      <DropzoneDialog
        open={openDropzone}
        onClose={() => setOpenDropzone(false)}
        onChoose={(res) => {
          setOpenDropzone(false);
          setExcelForm({ ...excelForm, excel: res[0] });
        }}
      />
      <CreateTemplate
        ref={createTemplateRef}
        open={openTemplateCreate}
        setOpen={setOpenTemplateCreate}
        data={template?.data_info ?? []}
        htmlData={htmlData}
        tableRowsHeight={tableRowsHeight}
        htmlString={htmlString}
        onSave={() => {
          onChange(excelForm);
        }}
        excelForm={excelForm}
        listInputName={listInputName}
        clearStateForm={clearStateForm}
        activityBaseId={activityBaseId}
        extraPage={extraPage}
      />
      <Stack width={"100%"}>
        <ExcelSelect
          excelForm={excelForm}
          formError={formError}
          handleChange={(value) => {
            setExcelForm({ ...value });
          }}
          handleBlur={(field, v) => validate(field, v)}
          isDisabled={isDisabled}
        />
        {excelForm.extra_template?.map((value, index) => (
          <CancelBtnBadge
            sx={{ width: "100%", "& .MuiBadge-badge": { top: 0, right: 5 } }}
            onClick={() => {
              const newExtraTemplate =
                _.cloneDeep(excelForm.extra_template) ?? [];
              newExtraTemplate.splice(index, 1);
              const newFormError = _.cloneDeep(extraFormError) ?? [];
              newFormError.splice(index, 1);
              setExcelForm({ ...excelForm, extra_template: newExtraTemplate });
              setExtraFormError(newFormError);
            }}
            key={index}
            disabled={isDisabled}
          >
            <Box
              border="3px solid"
              borderColor={Colors.MAIN_GREEN}
              borderRadius={1}
              padding={1}
              flex={1}
              display={"flex"}
              flexDirection={"column"}
            >
              <ExcelSelect
                excelForm={value}
                formError={
                  extraFormError.length > index ? extraFormError[index] : {}
                }
                handleChange={(v) => {
                  const newExtraTemplate =
                    _.cloneDeep(excelForm.extra_template) ?? [];
                  newExtraTemplate[index] = v;
                  setExcelForm({
                    ...excelForm,
                    extra_template: newExtraTemplate,
                  });
                }}
                handleBlur={(field, v) => {
                  const message = validate(
                    field,
                    v !== undefined
                      ? v
                      : value[field as keyof TemplateExcelExtra],
                    false,
                  );
                  const newFormError = _.cloneDeep(extraFormError) ?? [];
                  newFormError[index][field as keyof IFormError] = message;
                  setExtraFormError(newFormError);
                }}
                isDisabled={isDisabled}
              />
            </Box>
          </CancelBtnBadge>
        ))}
        <Box sx={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              const newExtraTemplate =
                _.cloneDeep(excelForm.extra_template) ?? [];
              newExtraTemplate.push(_.cloneDeep(initial_data_extra));
              const newFormError = _.cloneDeep(extraFormError) ?? [];
              newFormError.push(_.cloneDeep(initial_form_error));
              setExcelForm({ ...excelForm, extra_template: newExtraTemplate });
              setExtraFormError(newFormError);
            }}
            disabled={isDisabled}
          >
            Excelテンプレート追加
          </Button>
        </Box>
        <SelectLabel
          label={<LabelRequired title="フォント" />}
          value={excelForm.font}
          onChange={async (e) => {
            try {
              LoadingOverlayController.show();
              let excel = excelForm.excel;
              if (excel.non_Local) {
                excel = await getExcelFile(excel.uri, excel.path);
              }
              setExcelForm({
                ...excelForm,
                font: e.target.value,
                excel: excel,
              });
            } finally {
              LoadingOverlayController.hide();
            }
          }}
          disabled={isDisabled}
        >
          <MenuItem value={"sans-serif"}>Noto Sans JP</MenuItem>
          <MenuItem value={"serif"}>Noto Serif JP</MenuItem>
          <MenuItem value={"m_plus_1p"}>M PLUS 1p</MenuItem>
          <MenuItem value={"open_sans"}>Open Sans</MenuItem>
          <MenuItem value={"sans_sc"}>Noto Sans Simplified Chinese</MenuItem>
          <MenuItem value={"serif_thai"}>Noto Serif Thai</MenuItem>
        </SelectLabel>
        <SelectLabel
          label={<LabelRequired title="用紙サイズ" />}
          value={listPaperSize?.length > 0 ? excelForm.paper_size : ""}
          onChange={(e) => {
            const selected = listPaperSize?.find(
              (item) => item.paper_size == e.target.value,
            );
            if (selected) {
              setExcelForm({
                ...excelForm,
                paper_size: e.target.value,
                horizontal_pixel_size: selected.horizontal_pixel_size,
                vertical_pixel_size: selected.vertical_pixel_size,
              });
            }
          }}
          disabled={isDisabled}
        >
          {listPaperSize?.map((item, index) => (
            <MenuItem value={item.paper_size} key={index}>
              {item.paper_size}
            </MenuItem>
          ))}
        </SelectLabel>
        <SelectLabel
          label={<LabelRequired title="用紙向き" />}
          value={excelForm.is_landscape ? "1" : "0"}
          onChange={(e) =>
            setExcelForm({
              ...excelForm,
              is_landscape: e.target.value == "0" ? false : true,
            })
          }
          disabled={isDisabled}
        >
          <MenuItem value={0}>縦</MenuItem>
          <MenuItem value={1}>横</MenuItem>
        </SelectLabel>
        <FormControl>
          <InputLabel>
            <LabelRequired title="複数枚起票設定" />
          </InputLabel>
          <RadioGroup
            value={excelForm.is_multiple ?? false}
            row
            onChange={(_, value) => {
              setExcelForm({
                ...excelForm,
                is_multiple: value === "true" ? true : false,
              });
            }}
          >
            <FormControlLabel
              value={false}
              label="なし"
              control={<Radio disabled={isDisabled} />}
            />
            <FormControlLabel
              value={true}
              label="あり"
              control={<Radio disabled={isDisabled} />}
            />
          </RadioGroup>
        </FormControl>
        {!isNew && (
          <FormControl>
            <InputLabel>Excelファイルダウンロード</InputLabel>
            <Box>
              <Link
                component="button"
                onClick={async () =>
                  await handleDownload(
                    template?.excel?.path,
                    template?.excel?.uri,
                  )
                }
                variant="body1"
              >
                {template?.excel?.path}
              </Link>
            </Box>
            {template?.extra_template?.map((value, index) => (
              <Box key={index}>
                <Link
                  component="button"
                  onClick={async () =>
                    await handleDownload(value.excel?.path, value.excel?.uri)
                  }
                  variant="body1"
                >
                  {value.excel?.path}
                </Link>
              </Box>
            ))}
          </FormControl>
        )}
        <Box display={"flex"} justifyContent={"center"}>
          <Button onClick={handleImportExcel} disabled={isDisabled}>
            テンプレート設定
          </Button>
        </Box>
      </Stack>
    </>
  );
};

const ExcelSelect = ({
  excelForm,
  formError,
  handleChange,
  handleBlur,
  isDisabled = false,
}: {
  excelForm: any;
  formError: any;
  handleChange: (value: any) => void;
  handleBlur: (field: any, value?: any) => void;
  isDisabled: boolean;
}) => {
  // ------------------------------------------------------------------
  // 画面描画
  // ------------------------------------------------------------------
  const getIndexChar = useCallback((n: number) => {
    var codeAtA = "a".charCodeAt(0);
    var codeAtZ = "z".charCodeAt(0);
    var len = codeAtZ - codeAtA + 1;

    var s = "";
    while (n >= 0) {
      s = String.fromCharCode((n % len) + codeAtA).toUpperCase() + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }, []);

  return (
    <Stack>
      <FormControl>
        <InputLabel error={formError.excel?.length ?? 0 > 0}>
          <LabelRequired title="Excelファイル" />
        </InputLabel>
        {!excelForm.excel ? (
          <Dropzone
            onChoose={(res) => {
              handleChange({ ...excelForm, excel: res[0] });
              handleBlur("excel", res[0]);
            }}
            maxFiles={1}
            showFileList={false}
            accept="excel"
          />
        ) : (
          <Typography color={"GrayText"}>
            {excelForm.excel.path ?? excelForm.excel.name}
            <IconButton
              onClick={() => {
                handleChange({ ...excelForm, excel: undefined });
                handleBlur("excel", null);
              }}
              disabled={isDisabled}
            >
              <Cancel />
            </IconButton>
          </Typography>
        )}
        <FormHelperText error={formError.excel?.length ?? 0 > 0}>
          {formError.excel}
        </FormHelperText>
      </FormControl>
      <FormControl>
        <Stack direction={{ xs: "column", md: "row" }} mt={1}>
          <Stack direction={"row"}>
            <SelectLabel
              label={<LabelRequired title="取込み範囲（開始）" />}
              value={excelForm.start_col}
              onChange={(e) =>
                handleChange({ ...excelForm, start_col: e.target.value })
              }
              error={formError.start_row?.length ?? 0 > 0}
              disabled={isDisabled}
            >
              {[...Array(78)].map((_, i) => (
                <MenuItem key={i} value={getIndexChar(i)}>
                  {getIndexChar(i)}
                </MenuItem>
              ))}
            </SelectLabel>
            <TextField
              sx={{ width: "100px", pt: "26px" }}
              InputProps={{}}
              value={excelForm.start_row}
              onChange={(e) =>
                handleChange({ ...excelForm, start_row: e.target.value })
              }
              onBlur={() => handleBlur("start_row")}
              error={formError.start_row?.length ?? 0 > 0}
              disabled={isDisabled}
            />
          </Stack>
          <Typography pt={5} sx={{ display: { xs: "none", md: "inherit" } }}>
            :
          </Typography>
          <Stack direction={"row"}>
            <SelectLabel
              label={<LabelRequired title="取込み範囲（終了）" />}
              value={excelForm.end_col}
              onChange={(e) =>
                handleChange({ ...excelForm, end_col: e.target.value })
              }
              error={formError.end_row?.length ?? 0 > 0}
              disabled={isDisabled}
            >
              {[...Array(78)].map((_, i) => (
                <MenuItem key={i} value={getIndexChar(i)}>
                  {getIndexChar(i)}
                </MenuItem>
              ))}
            </SelectLabel>
            <TextField
              sx={{ width: "100px", pt: "26px" }}
              InputProps={{}}
              value={excelForm.end_row}
              onChange={(e) =>
                handleChange({ ...excelForm, end_row: e.target.value })
              }
              onBlur={() => handleBlur("end_row")}
              error={formError.end_row?.length ?? 0 > 0}
              disabled={isDisabled}
            />
          </Stack>
        </Stack>
        <FormHelperText error={formError.start_row?.length ?? 0 > 0}>
          {formError.start_row}
        </FormHelperText>
        <FormHelperText error={formError.end_row?.length ?? 0 > 0}>
          {formError.end_row}
        </FormHelperText>
      </FormControl>
      <TextField
        label={<LabelRequired title="取込対象シート名" />}
        value={excelForm.sheet_name}
        onChange={(e) =>
          handleChange({ ...excelForm, sheet_name: e.target.value })
        }
        onBlur={() => handleBlur("sheet_name")}
        error={formError.sheet_name?.length ?? 0 > 0}
        helperText={formError.sheet_name}
        disabled={isDisabled}
      />
    </Stack>
  );
};

export default forwardRef(TemplateCreateBox);
