import {
  AccidentHandling,
  AccidentReason,
  ImportDivision,
  ManualInput,
  OccurrenceLocation,
  Product,
  ProductStatus,
  Publisher,
  ShipperMaster,
  SpanMaster,
  User,
} from "services/models";
import { createDateText, createHtmlForMultipleText, getEscapedData } from "./";
import _ from "lodash";
import { createObjectURL, getBase64Image, getFileByUrl } from "..";
import { MMaster } from "screens/EditTemplate/TemplateIncidentDamageReport/IncidentDamageReportType"

interface IPersonFoundIssue extends User, ManualInput { }
export interface ImageInfoTypePreview extends Type.ImageInfoType {
  base64?: string;
}

export type StateFormType = {
    "risky_id": string; //帳票番号
    "date": string;
    "time": string;
    "customer": MMaster | undefined;
    "warehouse": MMaster | undefined;
    "area": MMaster | undefined;
    "reported_by": User | MMaster | string;
    "model_name": string;
    "quantity": string;
    "serial_no": string[];
    "cause": MMaster | string;
    "qa_authorizer": MMaster | undefined;
    "occurred_company": MMaster | undefined;//～起票者
    "image_array": Array<any>//不適合写真
    "qrcode": string;
};


export const getInfoFromTemplate = (htmlString: string) => {
  let pageImageExample: string = "";
  let imageProductExample: string = "";
  let qrcodeImageExample: string = "";
  let addPageExample: string = "";
  const pageImageExampleArray = htmlString.split("page_image_example");
  if (pageImageExampleArray.length >= 3) {
    pageImageExample = pageImageExampleArray[1];
  }
  const imageProductExampleArray = htmlString.split("image_product_example");
  if (imageProductExampleArray.length >= 3) {
    imageProductExample = imageProductExampleArray[1];
  }

  const addPageExampleArray = htmlString.split("add_page_example");
  if (addPageExampleArray.length >= 3) {
    addPageExample = addPageExampleArray[1];
  }


  const qrcodeImageExampleArray = htmlString.split("image_qrcode_example");
  if (qrcodeImageExampleArray.length >= 3) {
    qrcodeImageExample = qrcodeImageExampleArray[1];
  }

    return { pageImageExample, imageProductExample, qrcodeImageExample, addPageExample };
};

export const makeNewHtml = (
  newHtml: string,
  data: StateFormType,
  imageQrcodeExample: string,
  addPageExample: string) => {
  data = getEscapedData(data);
  let {
    risky_id,
    date,
    time,
    customer,
    warehouse,
    area,
    reported_by,
    model_name,
    quantity,
    serial_no,
    cause,
    qa_authorizer,
    occurred_company,
    image_array,
        qrcode
  } = data;

  // serial_noが4つ以上なら追加ページ
  let serial_no_infos: any[][] = [];
  for (var i = 0; i < serial_no.length; i += 4) {
    let arr = [
      serial_no[i],
      serial_no[i + 1],
      serial_no[i + 2],
      serial_no[i + 3],
    ];
    serial_no_infos.push(arr);
  }

  let addPageExampleClone = addPageExample;

  if (risky_id) {
    newHtml = newHtml.replace("<!-- {risky_id} -->", risky_id);
    addPageExampleClone = addPageExampleClone.replace(
      "<!-- {risky_id} -->",
      risky_id,
    );
        addPageExampleClone = addPageExampleClone.replace(
            "{risky_id}",
            risky_id,
        );
  } else {
    addPageExampleClone = addPageExampleClone.replace(
      "{risky_id}",
      "<!-- {risky_id} -->",
    );
  }

  if (serial_no_infos.length == 0) {
    newHtml = newHtml.replace("<!-- {current_page} -->", "1");
    newHtml = newHtml.replace("<!-- {total_page} -->", "1");
  }

  const [year, month, day] = date.split("-");



    newHtml = newHtml.replace(
        "<!-- {year} -->",
        year,
    );
    addPageExampleClone = addPageExampleClone.replace(
        "{year}",
        year ?? "",
    );

    newHtml = newHtml.replace(
        "<!-- {month} -->",
        month,
    );
    addPageExampleClone = addPageExampleClone.replace(
        "{month}",
        month ?? "",
    );

    newHtml = newHtml.replace(
        "<!-- {day} -->",
        day,
    );
    addPageExampleClone = addPageExampleClone.replace(
        "{day}",
        day ?? "",
    );

    newHtml = newHtml.replace(
        "<!-- {Time} -->",
        time,
    );
    addPageExampleClone = addPageExampleClone.replace(
        "{Time}",
        time ?? "",
    );

  if (warehouse) {
        newHtml = newHtml.replace(
            "<!-- {Warehouse} -->",
            warehouse?.name ?? "",
        );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{Warehouse}",
    warehouse?.name ?? "",
  );

  if (area) {
        newHtml = newHtml.replace(
            "<!-- {Area} -->",
            area?.name ?? "",
        );
  }
    addPageExampleClone = addPageExampleClone.replace(
        "{Area}",
        area?.name ?? "",
    );

  if (reported_by) {
        let reportedByText = ""
    if (typeof reported_by === "string") {
            reportedByText = reported_by
    } else {
      if ("name" in reported_by) {
                reportedByText = reported_by.name
      } else if ("full_name" in reported_by) {
                reportedByText = reported_by.full_name
      }
    }
        newHtml = newHtml.replace(
            "<!-- {Reported_by} -->",
            reportedByText,
        );
    addPageExampleClone = addPageExampleClone.replace(
      "{Reported_by}",
      reportedByText,
    );
  } else {
        addPageExampleClone = addPageExampleClone.replace(
            "{Reported_by}",
            "",
        );
  }

  if (model_name) {
        newHtml = newHtml.replace(
            "<!-- {Model_Name} -->",
            model_name ?? "",
        );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{Model_Name}",
    model_name ?? "",
  );

  if (quantity) {
        newHtml = newHtml.replace(
            "<!-- {Quantity} -->",
            quantity ?? "",
        );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{Quantity}",
    quantity ?? "",
  );

  if (customer) {
        newHtml = newHtml.replace(
            "<!-- {Customer} -->",
            customer.name ?? "",
        );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{Customer}",
    customer?.name ?? "",
  );

  if (cause) {
        let causeText = ""
    if (typeof cause === "string") {
            causeText = cause
        } else {
            causeText = cause.name
        }
        newHtml = newHtml.replace(
            "<!-- {Cause} -->",
            causeText,
        );
        addPageExampleClone = addPageExampleClone.replace(
            "{Cause}",
            causeText,
        );
    } else {
        addPageExampleClone = addPageExampleClone.replace(
            "{Cause}",
            "",
        );
  }

  if (qa_authorizer) {
    newHtml = newHtml.replace(
      "<!-- {QA_Authorizer} -->",
      qa_authorizer.name ?? "",
    );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{QA_Authorizer}",
    qa_authorizer?.name ?? "",
  );

  if (occurred_company) {
    newHtml = newHtml.replace(
      "<!-- {Occurred_Company} -->",
      occurred_company.name ?? "",
    );
  }
  addPageExampleClone = addPageExampleClone.replace(
    "{Occurred_Company}",
    occurred_company?.name ?? "",
  );

  if (qrcode) {
    let imageQrcodeExampleClone = imageQrcodeExample;
    imageQrcodeExampleClone = imageQrcodeExampleClone.replace(
      "{qr_image_url}",
      qrcode,
    );
    newHtml = newHtml.replace(
      " <!-- {image_qrcode_example} -->",
            imageQrcodeExampleClone
    );
    addPageExampleClone = addPageExampleClone.replace(
      " {image_qrcode_example}",
            imageQrcodeExampleClone
    );
  } else {
    addPageExampleClone = addPageExampleClone.replace(
      " {image_qrcode_example}",
            " <!-- {image_qrcode_example} -->"
    );
  }

  addPageExampleClone = addPageExampleClone.replace(
    " {page_image_example}",
    " <!-- {page_image_example} -->",
  );

  serial_no_infos.forEach((item, index) => {
    if (index == 0) {
      if (item.length) {
                let filterItem = item.filter((d: any) => d !== undefined && d !== "")
        newHtml = newHtml.replace(
          `<!-- {Serial_No} -->`,
          Array.isArray(item) ? filterItem.join(",") : item,
        );
      }
            newHtml = newHtml.replace(
                "<!-- {current_page} -->",
                String(index + 1),
            );

      let total_page = serial_no_infos.length;
      newHtml = newHtml.replace("<!-- {total_page} -->", String(total_page));
      addPageExampleClone = addPageExampleClone.replace(
        "{total_page}",
        String(total_page),
      );
    } else {
            let filterItem = item.filter((d: any) => d !== undefined && d !== "")
      let addPageExampleCloneCopy = addPageExampleClone;
      addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
        "{current_page}",
        String(index + 1),
      );
      addPageExampleCloneCopy = addPageExampleCloneCopy.replace(
        `{Serial_No}`,
        Array.isArray(item) ? filterItem.join(",") : item,
      );


      newHtml = newHtml.replace(
        "<!-- {add_page} -->",
        `
            <div style="margin-top:200px;"/>
            ${addPageExampleCloneCopy}
            <!-- {add_page} -->
          `,
      );
    }
  });

  return newHtml;
};

export const getImageArray = async (data: Array<any>) => {
  let image_array: Array<any> = [];
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const uri = item?.uri_jpg ?? item?.uri;
    if ((!item.base64 || item.base64.length === 0) && uri && uri.length > 0) {
      item.base64 = await getBase64Image(uri);
    }
    image_array.push(item);
  }
  return image_array;
};

export const insertReportLocal = (
  newHtml: string,
  imageProductExample: string,
  pageImageExample: string,
  image_array: Array<ImageInfoTypePreview>,
  mergeTemplate = false,
) => {
  if (image_array?.length === 0) {
    return newHtml;
  } else {

    let pageImageExampleClone = pageImageExample;
        let isExistUri = false

    image_array.map((item: ImageInfoTypePreview) => {
      if (item?.uri) {
        let imageProductExampleClone = imageProductExample;
        imageProductExampleClone = imageProductExampleClone.replace(
          "{image_url}",
          `'${item?.base64 ? item?.base64 : item?.uri_jpg ?? item?.uri}'`,
        );
        pageImageExampleClone = pageImageExampleClone.replace(
          "{image_product_waiting}",
          imageProductExampleClone,
        );
                isExistUri = true //一枚でもuriをもつ画像があるときはtrueにする
      } else {
        pageImageExampleClone = pageImageExampleClone.replace(
          "{image_product_waiting}",
          "",
        );
      }
    });

    if (isExistUri) {
      pageImageExampleClone = pageImageExampleClone.replaceAll(
        "{image_product_waiting}",
        "",
      );
      newHtml = newHtml.replace(
        /<!-- {page_image_example} -->/g,
                pageImageExampleClone
      );
    }

    return newHtml;
  }
};

export const handleEditInfoRiskyId = (templateEditInfo: any) => {
  const newTemplateEditInfo = { ...templateEditInfo };
  return newTemplateEditInfo;
};

export const deleteImageDataWhenFinishRiskyId = (templateEditInfo: any) => {
  return templateEditInfo;
};

const countString = (text: string) => {
  return text.split("").reduce((count, char) => {
    const len = Math.min(new Blob([char]).size, 2);
    return count + len;
  }, 0);
};

export const mergeTemplateIncidentDamageReport = async (
  templateUri: string,
  data: StateFormType,
  templateData: any,
) => {
  try {
    const f = await getFileByUrl(templateUri, "template.html", "text/html");
    let html_string = await f.text();
    const res = getInfoFromTemplate(html_string);
        html_string = makeNewHtml(html_string, data, res.qrcodeImageExample, res.addPageExample);
    html_string = insertReportLocal(
      html_string,
      res.imageProductExample,
      res.pageImageExample,
      data.image_array,
    );
    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    const html_blob = new Blob([html_string], { type: "text/html" });
    templateUri = createObjectURL(html_blob);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};