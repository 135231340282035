import React, { VFC, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormGroup,
  TextField,
  MenuItem,
  SelectChangeEvent,
  ListItemIcon,
  Stack,
} from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import LabelRequired from "components/atoms/LabelRequired";
import { Circle } from "@mui/icons-material";
import { useEffect } from "react";
import { Validation } from "@validation";
import { getDataStorage, STORAGE } from "@utils/Storage";
import _ from "lodash";

export interface IData {
  type: string;
  name: string;
  color: string;
  color_id: string;
  color_name: string;
  color_needs: boolean;
  is_manual_input: boolean;
  is_default?: boolean;
  custom_item: Array<ICustomItem>;
  custom_item_name: Array<string>;
}

interface ICustomItem {
  name: string;
  barcode: boolean;
  required: boolean;
}

interface IProps {
  data: IData;
  open: boolean;
  onChange: Function;
  onClose: Function;
}

var colorDatas: any[] = [];

const CustomMasterInfoEditDialog: VFC<IProps> = ({
  data,
  open,
  onChange,
  onClose,
}: IProps) => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [name, setName] = useState("");
  const [color, setColor] = useState("");
  const [errorText, setErrorText] = useState("");
  const [type, setType] = useState("");
  const [isManualInput, setIsManualInput] = useState(false);
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [originName, setOriginName] = useState<string>("");
  const [originIsManualInput, setOriginIsManualInput] =
    useState<boolean>(false);
  const [originIsDefault, setOriginIsDefault] = useState<boolean>(false);
  const [customItemName, setCustomItemName] = useState<Array<string>>([]);
  const [listCustomError, setListCustomError] = useState<Array<string>>([]);

  useEffect(() => {
    const setColorData = async () => {
      colorDatas = JSON.parse(await getDataStorage(STORAGE.COLOR_DATA, "[]"));
      const defaltTempData = colorDatas.filter(
        (data: any) => data.is_default === true,
      );
      setColor(
        data.color_id && data.color_id === ""
          ? defaltTempData[0].color_id
          : data.color_id,
      );
    };
    const setData = () => {
      setName(data.name ?? "");
      setType(data.type);
      setOriginName(data.name ?? "");
      setIsManualInput(data.is_manual_input ?? false);
      setOriginIsManualInput(data.is_manual_input ?? false);
      setIsDefault(data.is_default ?? false);
      setOriginIsDefault(data.is_default ?? false);
      setErrorText("");
      setListCustomError(data.custom_item.map(() => ""));
      setCustomItemName(data.custom_item_name);
    };

    if (open) {
      if (data.color_needs) setColorData();
      setData();
    }
  }, [open, data]);

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeColor = (e: SelectChangeEvent) => {
    setColor(e.target.value);
  };

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const validName = () => {
    const error_text = Validation.validate({
      type: "text",
      name: type,
      value: name,
      required: true,
    });
    setErrorText(error_text);
  };

  const changeCustomItemName =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      var newCustomItemName = _.cloneDeep(customItemName);
      newCustomItemName[index] = e.target.value;
      setCustomItemName(newCustomItemName);
    };

  const validCustomItemName = (index: number) => () => {
    const error_text = Validation.validate({
      type: "text",
      name: data.custom_item[index].name,
      value: customItemName[index],
      required: data.custom_item[index].required,
    });
    var newListCustomError = _.cloneDeep(listCustomError);
    newListCustomError[index] = error_text;
    setListCustomError(newListCustomError);
  };

  const isDisableOKButton = React.useMemo(() => {
    const isDataChanged =
      name !== originName ||
      isManualInput !== originIsManualInput ||
      isDefault !== originIsDefault ||
      !_.isEqual(data.custom_item_name, customItemName);
    const customItemRequired = customItemName.some((value, index) => {
      if (data.custom_item.length > index) {
        return data.custom_item[index].required && value.trim().length === 0;
      }
      return false;
    });

    return (
      name?.trim().length === 0 ||
      !isDataChanged ||
      errorText.length > 0 ||
      listCustomError.some((value) => value != "") ||
      customItemRequired
    );
  }, [
    name,
    isManualInput,
    isDefault,
    originName,
    originIsManualInput,
    originIsDefault,
    errorText,
    customItemName,
    listCustomError,
  ]);

  return (
    <Dialog
      maxWidth="sm"
      sx={{ "& .MuiDialog-paper": { width: "80%" } }}
      open={open}
    >
      <DialogContent>
        <FormGroup>
          <Stack>
            <TextField
              label={
                <>
                  <LabelRequired title={type} />
                </>
              }
              name="master_name"
              value={name}
              onChange={changeName}
              onBlur={validName}
              error={errorText.length > 0}
              helperText={errorText}
            />
            {data.custom_item.map((item, index) => (
              <TextField
                label={
                  item.required ? (
                    <LabelRequired title={item.name} />
                  ) : (
                    item.name
                  )
                }
                value={customItemName[index]}
                onChange={changeCustomItemName(index)}
                onBlur={validCustomItemName(index)}
                error={listCustomError[index]?.length > 0}
                helperText={listCustomError[index]}
                key={index}
              />
            ))}
            {data.color_needs && (
              <SelectLabel
                label="色"
                name="master_color"
                value={color}
                isIgnoreExtractRuleLabel={false}
                isIgnoreExtractRuleSelect={true}
                onChange={changeColor}
                inputProps={{
                  sx: {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                required
              >
                {colorDatas.map((item) => (
                  <MenuItem value={item.color_id} key={item.color_id}>
                    <ListItemIcon sx={{ mr: 1 }}>
                      <Circle sx={{ color: item.color_code }} />
                    </ListItemIcon>
                    <span className="IgnoreExtractRuleTarget">
                      {item.color_name}
                    </span>
                  </MenuItem>
                ))}
              </SelectLabel>
            )}
          </Stack>
          {!data.color_needs && (
            <div>
              <div>
                <CheckboxLabel
                  label="手入力有り"
                  checked={isManualInput}
                  onChange={(e) => {
                    setIsManualInput(e.target.checked);
                  }}
                  formSx={{ pt: 2 }}
                />
              </div>
              <div>
                <CheckboxLabel
                  label="デフォルト値"
                  checked={isDefault}
                  onChange={(e) => {
                    setIsDefault(e.target.checked);
                  }}
                  // formSx={{ pt: 2 }}
                />
              </div>
            </div>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant="outlined"
        >
          キャンセル
        </Button>

        <Button
          onClick={() => {
            const selected_color = colorDatas.filter(
              (item) => item.color_id === color,
            );
            const response: IData = {
              type: type,
              name: name,
              color: data.color_needs ? selected_color[0].color_code : "",
              color_id: data.color_needs ? selected_color[0].color_id : "",
              color_name: data.color_needs ? selected_color[0].color_name : "",
              color_needs: data.color_needs,
              is_manual_input: isManualInput,
              is_default: isDefault,
              custom_item: data.custom_item,
              custom_item_name: customItemName,
            };
            onChange(response);
            onClose();
          }}
          disabled={isDisableOKButton}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomMasterInfoEditDialog;
