import React, { useEffect, useRef, useState, VFC } from "react";
import GenericTemplate from "@template/index";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import _ from "lodash";
import messages from "config/messages";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getCsvData, getUserInfo } from "@utils/index";
import { downloadXlsx } from "@utils/CSV";
import { Validation } from "@validation";
import Dropzone from "components/atoms/Dropzone";
import LabelRequired from "components/atoms/LabelRequired";
import SelectLabel from "components/atoms/SelectLabel";
import ListCancelBadge from "components/molecules/ListCancelBadge";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import { importMaster, getAddColumns } from "@api/master";
import { insertLogApi } from "@api/log";
import { importUsers } from "@api/User";
import { checkMasterTypeExist, getMasterType } from "@api/masterType";
import { getContractInfoApi } from "@api/contract";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import {
  getListActivityBase,
  getListGroupMaster,
  getListGroup
} from "@api/groupMaster";
import { Colors } from "@template/style";
import {
  getColumnMaxlength,
  getColumnMinlength,
  getDefaultColumnIndex,
  getRequiredColumn,
  getNotRequiredColumn,
  IMasterList,
  LIST_EXPORT_CSV,
  LIST_GROUP,
  LIST_MASTER,
  LIST_ACCOUNT,
  NewUserMaster,
} from "shared/constants/MasterInfo";
import { USER_ROLES } from "@shared-constants";
import {
  ActivityBase,
  GroupMaster,
  GroupInfo
} from "services/models";
import { getDataStorage, STORAGE } from "@utils/Storage";

interface IStateForm {
  master: string;
  master_name: string;
  input_master_name: string;
  keyitem: boolean;
}
interface IErrorForm {
  master: string;
  input_master_name: string;
  csv: string;
  custom_item: string[];
}
const initialStateForm: IStateForm = {
  master: "",
  master_name: "",
  input_master_name: "",
  keyitem: false,
};

interface ICustomItem {
  name: string;
  barcode: boolean;
  required: boolean;
  keyitem: boolean;
}

interface GroupCheckResponse {
  error_message: any;
  group_info: any;
}

export type ISelectedData = { [key: string]: string };

export interface IGroupEditData {
  PK: string;
  SK: string;
  group_parentid?: string;
  groupName: string;
  isCreateNew?: boolean;
  index: number;
  groupNameAll?: string;
  selectedData?: ISelectedData;
}

// interface IStateForm extends User {
//   group_info: IGroupEditData[];
// }

//アカウント作成必須項目取得用のキー
const reqSuperUserKey = "CREATE_SUPER_USER";
const reqSuperNotUserKey = "CREATE_NOT_SUPER_USER";

const LOGIN_ID_INDEX = 0;
const PASSWORD_INDEX = 1;
const NAME_INDEX = 2;
const EMAIL_INDEX = 3;
const PERMISSION_INDEX = 4;
const GROUP_NAME_INDEX = 5;
const POSITION_INDEX = 6;
const USER_REPORT_CREATION_INDEX = 7;
// 以下、スーパーユーザーの場合は使わない定数
const SEAL_DISPLAY_NAME_INDEX = 8;
const SEAL_DISPLAY_VERTICAL_INDEX = 9;
const BASE_INDEX = 10;

const MasterImportScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [listMaster, setListMaster] = useState<IMasterList[]>([]);
  const [createFlg, setCreateFlg] = useState<boolean>(false);
  const [stateForm, setStateForm] = useState<IStateForm>({
    ...initialStateForm,
  });
  const [selectedCSV, setSelectedCSV] = useState<File>();
  const [formError, setFormError] = useState<IErrorForm>({
    master: "",
    input_master_name: "",
    csv: "",
    custom_item: [],
  });
  const [activityBaseId, setActivityBaseId] = useState<string>("");
  const [listMasterType, setListMasterType] = useState<Array<any>>([]);
  const [listCustomItem, setListCustomItem] = useState<Array<ICustomItem>>([]);
  const dropzoneRef = useRef<any>(null);
  const accountCreate = LIST_ACCOUNT.filter((value) => (value.id === "ACCOUNT_CREATE"));
  const user_info = getUserInfo();
  const group_id = useSelector((state: RootState) => state.authInfo.group_id);
  const [locationCountDict, setLocationCountDict] = useState<any>({});
  useEffect(() => {
    setActivityBaseId(user_info.location_id);
    getMasterTypeList(user_info.location_id);
  }, []);
  //契約情報
  const contract_info = getDataStorage(STORAGE.CONTRACT_INFO);
  const contractInfoObject = JSON.parse(contract_info);

  const getMasterTypeList = async (activityBaseId: string) => {
    var list_account = [...LIST_ACCOUNT];
    if (!activityBaseId) {
      setListMaster(list_account);
    } else {
      LoadingOverlayController.show();
      await getMasterType(activityBaseId, true)
        .then((res) => {
          var new_list_master = [...LIST_MASTER];
          new_list_master = new_list_master.concat(list_account);
          if (res?.data) {
            const list_user_master = res.data.map((v: any) => ({
              id: v.SK,
              name: v.name,
              group: LIST_GROUP.USER_MASTER.name,
            }));
            new_list_master = new_list_master.concat(list_user_master);
            setListMasterType(res.data);
          }
          new_list_master.push(NewUserMaster);
          setListMaster(new_list_master);
        })
        .finally(() => LoadingOverlayController.hide());
    }
  };

  const handleClear = () => {
    setStateForm({ ...initialStateForm });
    setSelectedCSV(undefined);
    setCreateFlg(false);
    setListCustomItem([]);
    if (dropzoneRef.current) {
      dropzoneRef.current.delData();
    }
  };

  // ------------------------------------------------------------------
  // 入力
  // ------------------------------------------------------------------
  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStateForm((prev) => ({
      ...prev,
      input_master_name: e.target.value,
    }));
  };

  const changeKeyitem = (value: boolean) => {
    setStateForm((prev) => ({
      ...prev,
      keyitem: value,
    }));
  };

  const changeCustomItem = (
    field: keyof ICustomItem,
    index: number,
    value: any,
  ) => {
    setListCustomItem((prev) => {
      var new_value = _.cloneDeep(prev);
      new_value[index] = { ...new_value[index], [field]: value };
      return new_value;
    });
  };

  const onValidateText = (field: keyof IStateForm) => {
    const mess = validator(field, stateForm[field]);
    setFormError({ ...formError, [field]: mess });
  };

  const onValidCustomItemName = (index: number) => () => {
    const mess = validator("custom_item_name", listCustomItem[index]["name"]);
    var newErrorCustomItem = _.cloneDeep(formError["custom_item"]);
    newErrorCustomItem[index] = mess;
    setFormError({ ...formError, custom_item: newErrorCustomItem });
  };

  const showErrorMessage = (e: unknown) => {
    const message = (e instanceof Error) ? e.message : messages.COMMON.MSG_COMMON_ERROR_001;
    ModalController.show({ message: message, visibleButton2: true });
  }

  const validator = (field: string, value: any) => {
    let mess: string = "";
    switch (field) {
      case "master":
        mess = Validation.validate({
          type: "text",
          name: "マスタ",
          value: stateForm.master,
          required: true,
        });
        break;
      case "input_master_name":
        mess = Validation.validate({
          type: "text",
          name: "ユーザー作成マスタ名",
          value: stateForm.input_master_name,
          required: true,
          max_length: 30,
        });
        break;
      case "custom_item_name":
        mess = Validation.validate({
          type: "text",
          name: "項目名",
          value: value,
          required: true,
          max_length: 30,
        });
        break;
      case "csv":
        if (!value) {
          mess = messages.COMMON.ERROR.MSG_RQUIRED_SELECT("CSV");
        }
        break;
    }
    return mess;
  };

  const validationAllCheck = async () => {
    // 入力チェック
    const master_error = validator("master", stateForm.master);
    const input_master_name_error = createFlg
      ? validator("input_master_name", stateForm.input_master_name)
      : "";
    const csv_error = validator("csv", selectedCSV);
    const custom_item_error = listCustomItem.map((value) => {
      return validator("custom_item_name", value.name);
    });
    setFormError({
      ...formError,
      master: master_error,
      input_master_name: input_master_name_error,
      csv: csv_error,
      custom_item: custom_item_error,
    });

    // マスタ名の重複チェック
    if (createFlg && input_master_name_error == "") {
      let params = {
        master_type_name: stateForm.input_master_name,
        activity_base_id: activityBaseId,
        master_type_id: "",
      };
      const master_type_exist = await checkMasterTypeExist(params);
      if (master_type_exist) {
        ModalController.show({
          message: messages.COMMON.ERROR.MSG_EXISTING("マスタ名"),
          visibleButton2: true,
        });
        return false;
      }
    }

    // バーコード検索する項目は1つのみ
    const count_barcode = listCustomItem.filter(
      (value) => value.barcode == true,
    ).length;
    if (count_barcode > 1) {
      ModalController.show({
        message: messages.MASTER.MSG_ERROR_BARCODE_LIMIT_1,
        visibleButton2: true,
      });
      return false;
    }

    // キー項目は1つのみ
    const count_keyitem = listCustomItem.filter(
      (value) => value.keyitem == true,
    ).length;
    if (count_keyitem > 1 || (count_keyitem == 1 && stateForm.keyitem)) {
      ModalController.show({
        message: messages.MASTER.MSG_ERROR_KEYITEM_LIMIT_1,
        visibleButton2: true,
      });
      return false;
    }

    if (
      master_error !== "" ||
      input_master_name_error !== "" ||
      csv_error !== "" ||
      custom_item_error.some((v) => v !== "")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkAccountCreateCsvData = async (csv_data: string[][]) => {
    LoadingOverlayController.show();
    var countCanCreateUserReport = 0;
    var listLoginId: any[] = [];
    var isCreateNotOwner = false;
    var activityBaseList: any[] = [];
    var response: any[] = [];
    var isError = false;
    var selectedLocation: any = null;
    const id = accountCreate[0].id;
    const columns = LIST_EXPORT_CSV[id];
    const listData: any[] = [];
    //必要/必要ないカラム
    var reqSUserKey = getRequiredColumn(reqSuperUserKey);
    var reqNotSUserKey = getRequiredColumn(reqSuperNotUserKey);
    var notReq = getNotRequiredColumn(reqSuperUserKey);
    //権限情報
    const roleNames = Object.values(USER_ROLES).map(role => role.name);
    const roleNamesForOwner = roleNames.slice(0, 3);
    const roleNamesForAdmin = roleNames.slice(1, 3);
    const roleNamesForMember = roleNames.slice(2, 3);
    //桁数
    const max_length = getColumnMaxlength(id);
    const min_length = getColumnMinlength(id);
    //件数
    let locationCount: any = {}

    if (csv_data.length == 0 || csv_data[0].length < 1) {
      response.push(messages.MASTER.MSG_ERROR_CSV);
      isError = true;
    }

    //メンバー数のチェック
    const checkAddUser = async () => {
      return await getContractInfoApi().then((res) => {
        return {
          "remainingCount": res.max_number_employees - res.user_count,
          "remainingCreatableUserCount": res.number_of_create_user_report_accounts
            - res.can_create_user_report_user_count
        }
      })
        .catch((error) => {
          isError = true;
          console.log(error, "error checkAddUser");
          return null;
        })
    };

    const contractInfo = await checkAddUser();
    if (contractInfo && csv_data.length - 1 > contractInfo.remainingCount) {
      response.push(messages.OTHER_SCREEN.MSG_MEMBERS_MANAGEMENT_ERROR_ADD_BUTTON);
      isError = true;
    }
    //拠点
    const getActivityBaseInfo = async () => {
      try {
        const res_ab = await getListActivityBase("", true);
        if (res_ab.length > 0) {
          const default_AB = res_ab.filter(
            (item: ActivityBase) => item.SK === activityBaseId,
          );
          return {
            "listActivityBase": res_ab,
            "default_AB": default_AB
          }
        } else {
          return {
            "listActivityBase": [],
            "default_AB": []
          }
        }
      } catch (error: any) {
        isError = true;
        console.log(error, "error fetch list name GM");
      }
    };
    const activityBaseInfo = await getActivityBaseInfo();
    if (user_info.user_role === USER_ROLES.OWNER.value) {
      activityBaseList = activityBaseInfo ? activityBaseInfo.listActivityBase : []
    }
    else if (user_info.user_role === USER_ROLES.ADMIN.value || user_info.user_role === USER_ROLES.MEMBER.value) {
      activityBaseList = activityBaseInfo ? activityBaseInfo.default_AB : []
    }
    // 入力チェック
    for (let n = 0; n < csv_data.length; n++) {
      const row = csv_data[n];
      // ヘッダはチェックしない
      if (n > 0) {
        var req: any = null;
        //権限チェック
        if (user_info.user_role === USER_ROLES.OWNER.value) {
          if (!roleNamesForOwner.includes(row[PERMISSION_INDEX])) {
            //スーパーユーザー:権限がスーパーユーザー、管理者、一般から選択されること
            response.push(messages.MASTER.MSG_INCORRECT_USER_ROLE);
            isError = true;
          }
        } else if (user_info.user_role === USER_ROLES.ADMIN.value) {
          //管理者:権限が管理者、一般から選択されること
          if (!roleNamesForAdmin.includes(row[PERMISSION_INDEX])) {
            response.push(messages.MASTER.MSG_INCORRECT_USER_ROLE_FOR_ADMIN);
            isError = true;
          }
        } else if (
          user_info.user_role === USER_ROLES.MEMBER.value
        ) {
          //一般:一般と入力されること
          if (!roleNamesForMember.includes(row[PERMISSION_INDEX])) {
            response.push(messages.MASTER.MSG_INCORRECT_USER_ROLE_FOR_MEMBER);
            isError = true;
          }
        }
        if (row[PERMISSION_INDEX] === USER_ROLES.OWNER.name) {
          req = reqSUserKey;
        } else if (
          row[PERMISSION_INDEX] === USER_ROLES.ADMIN.name || row[PERMISSION_INDEX] === USER_ROLES.MEMBER.name
        ) {
          req = reqNotSUserKey;
          isCreateNotOwner = true;
        }
        // if (req === null || req === undefined) {
        //   response.push(messages.MASTER.MSG_INCORRECT_USER_ROLE);
        //   isError = true;
        // }
        var isOwner = Boolean(row[PERMISSION_INDEX] === USER_ROLES.OWNER.name);

        //ユーザー作成帳票可能
        if (Number(row[USER_REPORT_CREATION_INDEX]) === 1) {
          countCanCreateUserReport++;
        }
        row.forEach((value, i) => {
          //不要なカラム
          if (isOwner && notReq.includes(i)) {
            if (value) {
              response.push(
                `${n + 1}行目${i + 1}列目` +
                messages.MASTER.MSG_INCORRECT_COLUMN_FOR_OWNER(new String(columns[i].header).toString()));
              isError = true;
            }
          } else {
            // 必須チェック
            switch (i) {
              case LOGIN_ID_INDEX:
                listLoginId.push(value);
                break;
              case PASSWORD_INDEX:
                var mess = Validation.validatePassword(value);
                if (mess) {
                  mess = `${n + 1}行目${i + 1}列目` + mess;
                  response.push(mess);
                  isError = true;
                }
                break;
              case EMAIL_INDEX:
                var mess = Validation.validateEmail(
                  value,
                  `${n + 1}行目${i + 1}列目` + "メールアドレス",
                  false,
                );
                if (mess) {
                  response.push(mess);
                  isError = true;
                }
                break;
              default:
                var mess = Validation.validate({
                  type: "text",
                  value: value,
                  name:
                    columns[i]?.header !== undefined
                      ? new String(columns[i].header).toString() + `（${n + 1}行目）`
                      : `${n + 1}行目${i + 1}列目`,
                  required: req ? req.includes(i) : null,
                  max_length: max_length[i]?.maxlen,
                  min_length: min_length[i]?.minlen,
                });
                if (mess) {
                  response.push(mess);
                  isError = true;
                }
                break;
            }

          }
          // if (i === LOGIN_ID_INDEX) {
          //   listLoginId.push(value);
          // }
        });

        //拠点
        if (!isOwner && user_info.user_role === USER_ROLES.OWNER.value) {
          var existList = activityBaseList.filter((val: any) => val.activity_base_name === row[BASE_INDEX])
          if (!existList.length) {
            response.push(`${n + 1}行目${BASE_INDEX + 1}列目` +
              messages.MASTER.MSG_MASTER_NOT_EXISTS_ERROR(row[BASE_INDEX], "拠点"));
            isError = true;
          } else {
            selectedLocation = existList[0]
          }
        } else if (!isOwner) {
          selectedLocation = activityBaseList[0]
          if (selectedLocation.activity_base_name !== row[BASE_INDEX]) {
            response.push(
              `${n + 1}行目${BASE_INDEX + 1}列目` +
              messages.MASTER.MSG_MASTER_NOT_EXISTS_ERROR(row[BASE_INDEX], "拠点"));
            isError = true;
          }
        } else {
          selectedLocation = ""
        }
        //ログインidの重複チェック
        const checkLoginIDTlog = async () => {
          let locationCode = "";
          if (selectedLocation) {
            locationCode = selectedLocation.activity_base_code ?? "";
            //拠点ごとの件数をカウント
            let key = selectedLocation.SK
            locationCount[key] = (locationCount[key] || 0) + 1
          } else {
            //追加するスーパーユーザーの件数をカウント
            let key = contractInfoObject.contract_id
            locationCount[key] = (locationCount[key] || 0) + 1
          }
          var mess = await Validation.validateLoginIDTlog(
            row[LOGIN_ID_INDEX],
            "",
            locationCode,
            "",
            contractInfoObject.contract_login_id
          );
          if (mess != "") {
            response.push(`${n + 1}行目${LOGIN_ID_INDEX + 1}列目` + mess)
          }
        }
        await checkLoginIDTlog();

        if (!isError) {
          var selectUserRole = Object.values(USER_ROLES).find(role => role.name === row[PERMISSION_INDEX]);
          const data = {
            preferred_username_cognito: row[LOGIN_ID_INDEX],
            password: row[PASSWORD_INDEX],
            user_role: selectUserRole?.value,
            fullname: row[NAME_INDEX],
            belong_company_name: row[GROUP_NAME_INDEX],
            country_code: undefined,
            email: row[EMAIL_INDEX],
            position_id: "",
            position_name: row[POSITION_INDEX],
            seal_display_name: row[SEAL_DISPLAY_NAME_INDEX],
            location_code: selectedLocation ? selectedLocation.activity_base_code : "",
            location_id: selectedLocation ? selectedLocation.SK : "",
            file: undefined,
            seal_display_file: undefined,
            is_vertical_writing_sign: isOwner ? false
              : Boolean(Number(row[SEAL_DISPLAY_VERTICAL_INDEX]) === 1 ? true : false),
            can_create_user_report: isOwner ? false
              : Boolean(Number(row[USER_REPORT_CREATION_INDEX]) === 1 ? true : false),
          };
          listData.push(data)
        }
      }
    }

    setLocationCountDict(locationCount);

    if (contractInfo && countCanCreateUserReport > contractInfo.remainingCreatableUserCount) {
      response.push(messages.ACCOUNT.MSG_UPDATE_ACCOUNT_USER_REPORT_ERROR);
      isError = true;
    }
    const req_column = isCreateNotOwner ? reqNotSUserKey : reqSUserKey;
    const not_exist_req_column =
      csv_data.length > 0 ? req_column.filter((v) => v > csv_data[0].length - 1) : [];

    //カラムを確認
    if (not_exist_req_column.length > 0) {
      isError = true;
      not_exist_req_column.forEach((v) => {
        response.push(messages.COMMON.ERROR.MSG_RQUIRED(`${v + 1}列目`));
      });
    }
    //csv内で、ログインIDの重複がないかチェック
    const hasDuplicates = new Set(listLoginId).size !== listLoginId.length;
    if (hasDuplicates) {
      isError = true;
      response.push(messages.COMMON.ERROR.MSG_EXISTING("ログインID"));
    }

    if (!isError) {
      return { "response": response, "listData": listData };
    } else {
      return { "response": response, "listData": null };
    }
  };


  const checkGroupData = async (csv_data: string[][]) => {
    let max_level = 0;
    let res: GroupInfo[] = [];
    let group_info_list: any = [];
    let errorStringArray: any = [];
    // グループ階層を取得
    const res_master: GroupMaster[] = await getListGroupMaster();
    //階層の数
    max_level = res_master.length;
    const initUnit = Array(max_level).fill("");
    //所属部分を抜き出し、行、階層ごとに配列を整理する
    let fixedCsvData = extractAndChunkByGroup(csv_data, LIST_EXPORT_CSV.ACCOUNT_CREATE.length, undefined, max_level)
    // 第一階層のグループを取得
    res = await getListGroup({
      group_parentid: res_master[0].PK,
    });

    //拠点のSKをキーとしたdictを作成する。以後取得した拠点を格納する {親のSK(第一階層はPK):[属する拠点]}
    let groupDict: { [key: string]: any } = res.reduce<{ [key: string]: any }>(
      (acc, item) => {
        acc[item.SK] = "";
        return acc;
      }, {}
    );
    if (res) {
      async function processGroupData() {
        for (let index = 0; index < fixedCsvData.length; index++) {
          const roleNames = Object.values(USER_ROLES).map(role => role.name);
          //スーパーユーザーは所属設定できない
          if (csv_data[index][PERMISSION_INDEX] === roleNames[0]
            && fixedCsvData[index].some((e: any) => JSON.stringify(e) !== JSON.stringify(initUnit))) {
            let errorMessage = messages.MASTER.MSG_INCORRECT_COLUMN_FOR_OWNER("所属");
            errorStringArray.push(`${index}行目` + errorMessage);
            continue
          }
          let slicedCsvData = fixedCsvData[index];
          let group_info: any = [];
          let parentGroup: any = null;
          let childrenGroup: any = null;
          let SK_group = "";
          let group_parentid = "";
          for (let i = 0; i < slicedCsvData.length; i++) {
            let unit = slicedCsvData[i];
            for (let j = 0; j < unit.length; j++) {
              if (j === 0) {
                if (unit?.[0]) {
                  if (user_info.user_role === USER_ROLES.ADMIN.value
                    || user_info.user_role === USER_ROLES.MEMBER.value) {
                    // 管理者または一般ユーザーの場合、第一階層はログイン拠点と一致する必要がある
                    parentGroup = res.filter(item => item.SK === group_id);
                    let groupNames = parentGroup.map((item: any) => item.group_name);
                    if (unit[0] !== groupNames[0]) {
                      addErrorMessage(index + 1, unit);
                    }
                  } else if (user_info.user_role === USER_ROLES.OWNER.value) {
                    // 第一階層の拠点名が存在することを確認
                    parentGroup = res.filter(item => item.group_name === unit[0]);
                  }
                  if (!parentGroup.length) {
                    addErrorMessage(index + 1, unit);
                  } else {
                    parentGroup = parentGroup[0]
                  }
                  SK_group = parentGroup.SK
                  group_parentid = parentGroup.group_parentid
                } else {
                  continue; // 空の単位があった場合はスキップ
                }
              }
              if (j > 0) {
                if (!unit?.[j]) continue;
                // 非同期処理にawaitを使用して同期的に待つ
                if (groupDict[parentGroup.SK] === "") {
                  // 非同期関数としてawaitを直接使用
                  const response: GroupInfo[] = await getListGroup({ group_parentid: parentGroup.SK });
                  groupDict[parentGroup.SK] = response;
                  let addDict = response.reduce<{ [key: string]: any }>((acc, item) => {
                    acc[item.SK] = "";
                    return acc;
                  }, {});
                  Object.assign(groupDict, addDict);
                }
                if (Array.isArray(groupDict[parentGroup.SK])) {
                  childrenGroup = groupDict[parentGroup.SK].filter((item: any) => item.group_name === unit[j]);
                }
                //子→親に更新
                if (!childrenGroup.length) {
                  addErrorMessage(index + 1, unit);
                } else {
                  SK_group = childrenGroup[0].SK
                  group_parentid = parentGroup.SK
                  parentGroup = childrenGroup[0];
                }
              }
            }
            group_info.push({ "SK_group": SK_group, "group_parentid": group_parentid });
          }
          group_info_list.push(group_info);
        }
      }
      await processGroupData()
    }
    function addErrorMessage(index: number, unit: any[]) {
      let errorMessage = messages.COMMON.ERROR.MSG_INVALID_VALUE(unit.join(" "), `${index}行目 所属`);
      errorStringArray.push(errorMessage);
    }
    let check_group_response: GroupCheckResponse = {
      "error_message": errorStringArray,
      "group_info": group_info_list
    }
    return check_group_response;
  }


  function extractAndChunkByGroup(arr: any[], start: number, end: number | undefined, chunkSize: number) {
    let result: any = [];
    arr.forEach((subArr, index) => {
      let user_groups: any = [];
      //0はヘッダなので、処理しない
      if (index !== 0) {
        let slicedSubArr = subArr.slice(start, end); // 指定範囲を取得
        if (slicedSubArr.length) {
          if (chunkSize > slicedSubArr.length) {
            let diff = chunkSize - slicedSubArr.length;
            // 形式を整えるために差の回数だけ空文字を追加
            slicedSubArr.push(...Array(diff).fill(""));
          }
          for (let i = 0; i < slicedSubArr.length; i += chunkSize) {
            user_groups.push(slicedSubArr.slice(i, i + chunkSize));
          }
        } else {
          user_groups.push([])
        }
        result.push(user_groups)
      }
    })
    return result;
  }

  // ------------------------------------------------------------------
  // 雛形CSVダウンロード
  // ------------------------------------------------------------------
  const handleExportCSV = async () => {
    if (!stateForm.master) {
      ModalController.show({
        message: messages.COMMON.ERROR.MSG_RQUIRED("マスタ"),
        visibleButton2: true,
      });
    } else {
      var columns = LIST_EXPORT_CSV[stateForm.master] ?? [];
      // ユーザー作成マスタの場合は、マスタデータから任意項目を追加
      const master = listMaster.find((v) => v.id === stateForm.master);
      if (master?.group == LIST_GROUP.USER_MASTER.name) {
        columns = [...LIST_EXPORT_CSV[LIST_GROUP.USER_MASTER.id]];
        const master_type = listMasterType.find(
          (v) => v.SK == stateForm.master,
        );
        if (master_type && master_type.custom_item) {
          master_type.custom_item.forEach((v: any) => {
            columns.push({ header: v.name });
          });
        }
      } else if (stateForm.master === accountCreate[0].id) {
        //アカウント一覧のときは、所属のカラムを取得する
        let add_columns = await getAddColumns();
        columns = columns.concat(add_columns);
      }
      if (stateForm.master == NewUserMaster.id) {
        // 新規作成の場合は、入力した任意項目を追加
        listCustomItem.forEach((v) => {
          columns.push({ header: v.name });
        });
      }
      await downloadXlsx({
        data: [],
        columns: columns,
        filename: `${stateForm.master_name}マスタ雛形.csv`,
      });
    }
  };

  // ------------------------------------------------------------------
  // 取込み
  // ------------------------------------------------------------------
  const handleImportCSV = async () => {
    try {
      LoadingOverlayController.show();
      // 入力チェック
      if (!(await validationAllCheck()) || !selectedCSV) {
        return false;
      }
      // CSVデータ取得
      const csv_data = await getCsvData(selectedCSV);
      if (csv_data.length == 0) {
        ModalController.show({
          message: messages.COMMON.MSG_ERROR_FILE_OPEN,
          visibleButton2: true,
        });
        return;
      }

      // CSVチェック
      const error_message = checkCsvData(csv_data);
      if (error_message.length > 0) {
        ModalController.show({
          message: error_message.join("\n"),
          visibleButton2: true,
        });
        return;
      }

      // 保存
      const formData = {
        master: stateForm.master,
        master_name: stateForm.input_master_name,
        csv: csv_data.splice(1),
        activity_base_id: activityBaseId,
        is_create_master_type: createFlg,
        custom_item: listCustomItem,
        keyitem: stateForm.keyitem,
      };
      await importMaster(formData)
        .then(() => {
          ModalController.show({
            message: messages.COMMON.MSG_COMMON_SUCCESS_001("CSV取込"),
            visibleButton2: true,
            handlePressButton2: () => {
              handleClear();
              getMasterTypeList(activityBaseId);
            },
          });
          //{拠点:件数}
          let locationInfo = { activityBaseId: csv_data.splice(1).length }
          insertLog(locationInfo)
        })
        .catch((e) => {
          const message = (e.detail) ? e.detail : messages.COMMON.MSG_COMMON_ERROR_001;
          ModalController.show({ message: message, visibleButton2: true });
        });
    } catch (e) {
      console.log("handleImportCSV error", e);
      showErrorMessage(e);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const handleImportUserCreateCSV = async () => {
    try {
      LoadingOverlayController.show();
      // 入力チェック
      if (!(await validationAllCheck()) || !selectedCSV) {
        return;
      }

      // CSVデータ取得
      const csv_data = await getCsvData(selectedCSV);
      if (csv_data.length == 0) {
        ModalController.show({
          message: messages.COMMON.MSG_ERROR_FILE_OPEN,
          visibleButton2: true,
        });
        return;
      }

      // CSVチェック
      const res = await checkAccountCreateCsvData(csv_data);
      const error_message = res?.response ?? [];
      if (error_message.length > 0) {
        ModalController.show({
          message: error_message.join("\n"),
          visibleButton2: true,
        });
        return;
      }

      //所属のチェック
      const maxLength = Math.max(...csv_data.map(subArray => subArray.length));
      let group_info: any = []
      let group_error_message: any = []
      //CSVの最大カラム数>アカウントの想定カラム数のとき、所属の設定がされているとみなす
      if (maxLength > LIST_EXPORT_CSV.ACCOUNT_CREATE.length) {
        const res_group_check: GroupCheckResponse = await checkGroupData(csv_data);
        if (res_group_check) {
          group_error_message = res_group_check.error_message ?? [];
          group_info = res_group_check.group_info ?? [];
        }
        if (group_error_message.length > 0) {
          ModalController.show({
            message: group_error_message.join("\n"),
            visibleButton2: true,
          });
          return;
        }
      }

      // 保存
      const formData = new FormData();
      formData.append("contract_id", contractInfoObject.contract_id);
      formData.append("csv_data", JSON.stringify(res?.listData ?? []));
      formData.append("contract_login_id", contractInfoObject.contract_login_id);
      formData.append("group_info", JSON.stringify(group_info))

      await importUsers(formData)
        .then(() => {
          insertLog();
          ModalController.show({
            message: messages.COMMON.MSG_COMMON_SUCCESS_001("CSV取込"),
            visibleButton2: true,
            handlePressButton2: () => {
              handleClear();
              getMasterTypeList(activityBaseId);
            },
          });
        })
        .catch((e) => {
          const message = (e.detail) ? e.detail : messages.COMMON.MSG_COMMON_ERROR_001;
          ModalController.show({ message: message, visibleButton2: true });
        });
    } catch (e) {
      console.log("handleImportUserCreateCSV error", e);
      showErrorMessage(e);
    } finally {
      LoadingOverlayController.hide();
    }
  };

  const checkCsvData = (csv_data: string[][]) => {
    var response: string[] = [];
    const master = listMaster.find((v) => v.id === stateForm.master);
    const id =
      master?.group == LIST_GROUP.USER_MASTER.name
        ? LIST_GROUP.USER_MASTER.id
        : stateForm.master;
    const columns = LIST_EXPORT_CSV[id];
    var req = getRequiredColumn(id);
    const max_length = getColumnMaxlength(id);
    const def_index = getDefaultColumnIndex(id);

    if (csv_data.length == 0 || csv_data[0].length < 1) {
      response.push(messages.MASTER.MSG_ERROR_CSV);
    }

    if (id == LIST_GROUP.USER_MASTER.id) {
      if (stateForm.master == NewUserMaster.id) {
        // 新規作成
        listCustomItem.forEach((v, i) => {
          if (v.required) {
            req.push(i + 3);
          }
        });
      } else {
        // 既存ユーザー作成マスタ
        const master_type = listMasterType.find(
          (v) => v.SK == stateForm.master,
        );
        if (master_type && master_type.custom_item) {
          master_type.custom_item.forEach((v: any, i: number) => {
            if (v.required) {
              req.push(i + 3);
            }
          });
        }
      }
    }

    // 入力チェック
    csv_data.forEach((row, n) => {
      // ヘッダはチェックしない
      if (n > 0) {
        row.forEach((value, i) => {
          // 必須チェック
          var mess = Validation.validate({
            type: "text",
            value: value,
            name:
              columns[i]?.header !== undefined
                ? new String(columns[i].header).toString() + `（${n + 1}行目）`
                : `${n + 1}行目${i + 1}列目`,
            required: req.includes(i),
            max_length: max_length[i]?.maxlen,
          });
          if (mess) response.push(mess);
        });
      }
    });
    const not_exist_req_column =
      csv_data.length > 0 ? req.filter((v) => v > csv_data[0].length - 1) : [];
    if (not_exist_req_column.length > 0) {
      not_exist_req_column.forEach((v) => {
        response.push(messages.COMMON.ERROR.MSG_RQUIRED(`${v + 1}列目`));
      });
    }

    // 名前の重複チェック
    if (!["CHECK_SECTION", "CHECK_ITEM"].includes(id)) {
      var count_name = csv_data
        .map((row) => row[0])
        .filter((value, i, self) => {
          return (
            self.indexOf(value) === i &&
            i !== self.lastIndexOf(value) &&
            value !== ""
          );
        }).length;
      if (count_name > 0) {
        response.push(messages.COMMON.ERROR.MSG_EXISTING("名称"));
      }
    }

    // デフォルト値の重複チェック
    if (def_index !== null) {
      const count_def = csv_data
        .map((row) => row[def_index])
        .filter((value) => {
          return value == "1";
        }).length;
      if (count_def > 1) {
        response.push(messages.COMMON.ERROR.MSG_EXISTING("デフォルト値"));
      }
    }
    return response;
  };

  // ------------------------------------------------------------------
  // ログ書き込み
  // ------------------------------------------------------------------

  const insertLog = async (locationCount?: any) => {
    let locationCountData = locationCount ?? locationCountDict;
    const dataArray = Object.keys(locationCountData).map(key => ({
      export_account_id: "",
      export_account_name: "",
      export_count: "0",
      export_data_type: "",
      import_data_type: stateForm.master,
      import_account_id: user_info.SK,
      import_account_name: user_info.full_name,
      import_count: String(locationCountDict[key]),
      is_export: false, // true→export, false→import
      activity_base_id: key === contractInfoObject.contract_id ? "" : key
    }));
    // const formData = createFormUpload(null, dataArray);
    await insertLogApi(dataArray).catch(error => {
      console.error("insertLogApi failed:", error);
    });
  }

  const handleAdd = () => {
    var newListCustomItem = _.cloneDeep(listCustomItem);
    newListCustomItem.push({
      name: "",
      barcode: false,
      required: false,
      keyitem: false,
    });
    var newErrorCustomItem = _.cloneDeep(formError.custom_item);
    newErrorCustomItem.push("");
    setListCustomItem(newListCustomItem);
    setFormError({ ...formError, custom_item: newErrorCustomItem });
  };

  const handleDelelte = (index: number) => {
    var newListCustomItem = _.cloneDeep(listCustomItem);
    newListCustomItem.splice(index, 1);
    var newErrorCustomItem = _.cloneDeep(formError.custom_item);
    newErrorCustomItem.splice(index, 1);
    setListCustomItem(newListCustomItem);
    setFormError({ ...formError, custom_item: newErrorCustomItem });
  };

  const renderListItem = (value: ICustomItem, index: number) => {
    return (
      <Stack sx={{ width: "100%", mt: 1 }} spacing={1}>
        <TextField
          label={<LabelRequired title={"項目名"} />}
          value={value.name}
          onChange={(e) => {
            changeCustomItem("name", index, e.target.value);
          }}
          onBlur={onValidCustomItemName(index)}
          error={formError.custom_item[index]?.length > 0}
          helperText={formError.custom_item[index]}
          inputProps={{
            maxLength: 30,
          }}
        />
        <FormGroup sx={{ flexDirection: "row" }}>
          <CheckboxLabel
            label="キー項目"
            checked={value.keyitem}
            onChange={(_, checked) => {
              changeCustomItem("keyitem", index, checked);
              if (checked) changeCustomItem("required", index, checked);
            }}
          />
          <CheckboxLabel
            label="バーコード検索する"
            checked={value.barcode}
            onChange={(_, checked) => {
              changeCustomItem("barcode", index, checked);
              if (checked) changeCustomItem("required", index, checked);
            }}
          />
          <CheckboxLabel
            label="必須"
            checked={value.required}
            onChange={(_, checked) => {
              changeCustomItem("required", index, checked);
            }}
          />
        </FormGroup>
      </Stack>
    );
  };

  return (
    <GenericTemplate title="CSV取込">
      <Card>
        <CardContent>
          <Stack>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{ width: { xs: "100%", md: "50%" } }}
            >
              <SelectLabel
                label={<LabelRequired title="マスタ" />}
                fullWidth
                value={stateForm.master}
                onChange={(e) => {
                  const item = listMaster.find((v) => v.id === e.target.value);
                  if (item) {
                    setCreateFlg(item.is_new ?? false);
                    setStateForm({
                      ...stateForm,
                      master: item.id,
                      master_name: item.name,
                      input_master_name: "",
                      keyitem: false,
                    });
                    setFormError({
                      ...formError,
                      master: "",
                      input_master_name: "",
                    });
                    setListCustomItem([]);
                  }
                }}
                MenuProps={{ style: { maxHeight: 300 } }}
                error={formError.master.length > 0}
                helperText={formError.master}
              >
                {listMaster.map((item, index) => {
                  var subheader = false;
                  var response = [];
                  if (index == 0 || listMaster[index - 1].group != item.group) {
                    subheader = true;
                    response.push(
                      <ListSubheader
                        sx={{ color: Colors.LIGHT_GRAY, fontStyle: "italic" }}
                        key={index}
                      >
                        {item.group}
                      </ListSubheader>,
                    );
                  }
                  response.push(
                    <MenuItem
                      value={item.id}
                      key={subheader ? undefined : index}
                    >
                      {item.name}
                    </MenuItem>,
                  );
                  return response;
                })}
              </SelectLabel>
            </Box>
            <TextField
              label="ユーザー作成マスタ名"
              value={stateForm.input_master_name}
              disabled={!createFlg}
              onChange={changeName}
              onBlur={() => onValidateText("input_master_name")}
              error={formError.input_master_name.length > 0}
              helperText={formError.input_master_name}
              inputProps={{
                maxLength: 30,
              }}
            />
            <CheckboxLabel
              label="名称をキー項目にする"
              checked={stateForm.keyitem}
              onChange={(_, checked) => changeKeyitem(checked)}
              disabled={!createFlg}
            />
            <ListCancelBadge
              data={listCustomItem}
              renderItem={renderListItem}
              handleCancel={handleDelelte}
              cancelBtnDisabled={!createFlg}
              addBtnTitle="項目追加"
              handleAdd={handleAdd}
              addBtnDisabled={!createFlg}
            />
            <FormControl error={formError.csv.length > 0}>
              <InputLabel>
                <LabelRequired title="CSV" />
              </InputLabel>
              <Dropzone
                ref={dropzoneRef}
                accept="csv"
                maxFiles={1}
                onChoose={(res) => {
                  setSelectedCSV(res[0]);
                  setFormError({ ...formError, csv: "" });
                }}
                onDeleteFile={() => {
                  setSelectedCSV(undefined);
                }}
              />
              <FormHelperText error={formError.csv.length > 0}>
                {formError.csv}
              </FormHelperText>
            </FormControl>
            {
              stateForm.master === accountCreate[0].id ? (
                <Alert severity="info" color="warning">
                  ユーザー作成帳票作成可能のときは、ユーザー作成帳票作成可能に1を設定してください
                  <br />
                  電子印の表示名を縦表示するときは、電子印の表示名を縦表示に1を設定してください
                </Alert>
              ) : (
                <Alert severity="info" color="warning">
                  手入力のときは、手入力有りに1を設定してください
                  <br />
                  デフォルトのときは、デフォルト値に1を設定してください
                </Alert>
              )
            }
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleExportCSV}>雛形CSVダウンロード</Button>
          <Button color="secondary" onClick={() => {
            stateForm.master === accountCreate[0].id
              ? handleImportUserCreateCSV()
              : handleImportCSV();
          }}>
            取込
          </Button>
        </CardActions>
      </Card>
    </GenericTemplate >
  );
};

export default MasterImportScreen;


