import { createFormUpload } from "@utils/index";
import { insertLogApi } from "@api/log";

// ------------------------------------------------------------------
// ログの書き込み
// ------------------------------------------------------------------
export const insertExportLog = async (userInfo: any, masterType: string, location_dict_count: any) => {
  return insertLog(userInfo, masterType, true, location_dict_count);
}

const insertLog = async (
  userInfo: any, masterType: string, isExport: boolean, location_dict_count: any
) => {
  const dataArray = Object.keys(location_dict_count).map(key => ({
    export_account_id: userInfo.SK,
    export_account_name: userInfo.full_name,
    export_count: String(location_dict_count[key]),
    export_data_type: masterType,
    import_data_type: "",
    import_account_id: "",
    import_account_name: "",
    import_count: "0",
    is_export: isExport, // true→export, false→import
    activity_base_id: key === userInfo.contract_id ? "" : key
  }));

  // const formData = createFormUpload(null, data);
  const res = await insertLogApi(dataArray);
  return res;
}