import { useCallback, useEffect, useMemo, useState, VFC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { TYPES } from "store/types";
import GenericTemplate from "@template/index";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { getListProject, updateFavoriteProjectApi } from "@api/project";
import { getListTopGroupAll } from "@api/groupMaster";
import PopupSort from "components/atoms/PopupSort";
import { useHistoryCustom } from "shared/hook/useHistoryCustom";
import InfiniteScroll from "react-infinite-scroller";
import ScrollLoader from "components/atoms/ScrollLoader";
import ConstructionItem from "./ConstructionItem";
import { LIST_TYPE_SORT_PROJECT, USER_ROLES } from "@shared-constants";
import FilterInput from "components/molecules/FilterInput";
import { getParamsFilter, getUserInfo } from "@utils/index";
import {
  getConditionFilter,
  getHasFilterConditionProject,
} from "selector/filterSelector";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import messages from "config/messages";
import { getSortOfScreen } from "selector/sortSelector";
import { Project, ActivityBase } from "services/models";

let paramsFilter: any;

const ProjectListTlogScreen: VFC = () => {
  // ------------------------------------------------------------------
  // 初期化
  // ------------------------------------------------------------------
  const [data, setData] = useState<any[]>([]);
  const [lastKey, setLastKey] = useState<any | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const hasCondition = useSelector(getHasFilterConditionProject("project"));
  const projectFilter = useSelector(getConditionFilter("project"));
  const typeSort = useSelector(getSortOfScreen("project"));
  const [searchedKeyword, setSearchKeyword] = useState<string>("");
  const [isDisableFilter, setIsDisableFilter] = useState<boolean>(false);
  const [listGroup, setListGroup] = useState<ActivityBase[]>([]);

  const userInfo = getUserInfo();
  const history = useHistoryCustom();
  const dispatch = useDispatch();

  const listEditableRole = [USER_ROLES.OWNER.value, USER_ROLES.ADMIN.value];

  // ------------------------------------------------------------------
  // データ取得
  // ------------------------------------------------------------------
  const fetchListProject = async (
    keyWord?: string,
    sortType?: string,
    lastKeyword?: string,
    sort_by?: string,
    isMerge: boolean = false,
  ) => {
    setLoading(true);
    if (data.length === 0) {
      LoadingOverlayController.show();
    }

    const params = {
      keyword: keyWord ?? searchedKeyword,
      sort_type: sortType ?? typeSort,
      last_key: lastKeyword ?? lastKey,
      sort_by: sort_by ?? sortBy ?? undefined,
      filter_condition: paramsFilter,
    };

    return await getListProject(params)
      .then((res) => {
        if (isMerge) {
          setData((preValue) => [...preValue, ...res?.data]);
        } else {
          setData(res?.data);
        }
        if (res?.last_key) {
          setLastKey(res?.last_key?.SK ?? null);
          setSortBy(res?.last_key?.sort_by ?? null);
          setLoadMore(true);
        } else {
          setLastKey(null);
          setSortBy(null);
          setLoadMore(false);
        }
        if (res?.data.length < 10 && res?.last_key) {
          fetchListProject(
            params.keyword,
            params?.sort_type,
            res?.last_key?.SK,
            res?.last_key?.sort_by,
            true,
          );
        }
        if (!res?.last_key || (res.data.length >= 10 && res.last_key)) {
          LoadingOverlayController.hide();
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
        LoadingOverlayController.hide();
      });
  };

  const showLoadMore = useMemo(() => {
    return loadMore && lastKey && data.length > 0;
  }, [data, loadMore, lastKey]);

  useEffect(() => {
    paramsFilter = getParamsFilter(projectFilter);
    dispatch({ type: TYPES.GET_LIST_LOCATION });
    fetchListProject();
  }, []);

  // ------------------------------------------------------------------
  // 絞り込み
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!data.length && !hasCondition) {
      setIsDisableFilter(true);
    } else {
      setIsDisableFilter(false);
    }
  }, [data]);

  useDeepCompareEffect(() => {
    if (sortBy) {
      setSortBy(null);
    }
    if (lastKey) {
      setLastKey(null);
    }
    paramsFilter = getParamsFilter(projectFilter);
  }, [projectFilter]);

  // ------------------------------------------------------------------
  // 並べ替え
  // ------------------------------------------------------------------
  const handleSort = (type_sort: string) => {
    LoadingOverlayController.show();
    setSortBy(type_sort);
    const payload = {
      type_screen: "project",
      value: type_sort,
    };
    dispatch({ type: TYPES.SET_SORT_SCREEN, payload });
    dispatch({ type: TYPES.SET_SORT_CONDITION, payload });
    fetchListProject(undefined, type_sort, "", "");
  };

  const sortDefaultIndex = useMemo(
    () => {
      const defaultSort = Object.values(LIST_TYPE_SORT_PROJECT).filter(
        (item) => item.value === typeSort,
      );
      if (defaultSort.length === 0) {
        return 0;
      } else {
        return Object.values(LIST_TYPE_SORT_PROJECT).indexOf(defaultSort[0]);
      }
    },
    [] /* 画面初期表示時のみ判定 */,
  );

  // ------------------------------------------------------------------
  // 帳票選択
  // ------------------------------------------------------------------
  const handleOnClick = (data: Project) => {
    dispatch({
      type: TYPES.SET_SELECTED_PROJECT_ID,
      selected_project_id: data.PK,
      selected_project_name: data.project_name,
    });
    dispatch({ type: TYPES.SET_PROJECT_INFO, payload: data });
    history.pushWithRef("/project/info");
  };

  // ------------------------------------------------------------------
  // 検索
  // ------------------------------------------------------------------
  const _handleSearch = (str: string) => {
    setSearchKeyword(str);
    setLoadMore(true);
    setLastKey(null);
    setSortBy(null);
    LoadingOverlayController.show();
    fetchListProject(str, undefined, "", "");
  };

  useEffect(() => {
    getListGroup();
  }, []);

  const getListGroup = async () => {
    await getListTopGroupAll(userInfo.PK)
      .then((res) => {
        setListGroup(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <GenericTemplate
      title="帳票一覧"
      isSearch={true}
      searchPlaceholder="帳票名で検索"
      onSearch={(v) => _handleSearch(v)}
      onSearchClear={() => _handleSearch("")}
    >
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12} md="auto">
          {/* =================== 並べ替え =================== */}
          <PopupSort
            data={Object.values(LIST_TYPE_SORT_PROJECT).map((item) => {
              return {
                title: item.title,
                onClick: () => handleSort(item.value),
              };
            })}
            defaultIndex={sortDefaultIndex}
            disabled={data.length === 0}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md="auto"
          sx={{ width: { md: "40%" }, marginLeft: "auto" }}
        >
          {/* =================== 絞り込み =================== */}
          <FilterInput
            typeScreen="project"
            disabled={isDisableFilter}
            onFetchData={() => {
              LoadingOverlayController.show();
              fetchListProject(undefined, undefined, "", "");
            }}
          />
        </Grid>
      </Grid>

      {/* =================== 帳票 =================== */}
      <InfiniteScroll
        loadMore={() => {
          if (loadMore && lastKey && data.length > 0 && !loading) {
            fetchListProject(undefined, undefined, lastKey, undefined, true);
          }
        }}
        hasMore={showLoadMore}
        loader={<ScrollLoader key={0} />}
      >
        <Grid container spacing={3} sx={{ pb: 5 }}>
          {data.map((d: any, i) => {
            return (
              <Grid item xs={12} md={4} lg={4} key={i}>
                <ConstructionItem
                  data={d}
                  handleOnClick={handleOnClick}
                  listGroup={listGroup}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
      {data.length === 0 && !loading && (
        <Typography>
          {searchedKeyword === ""
            ? messages.COMMON.MSG_NOT_EXIST("帳票")
            : messages.COMMON.MSG_NON_SEARCH_RESULT(searchedKeyword)}
        </Typography>
      )}

      {/* =================== 帳票作成ボタン =================== */}
      {listEditableRole.includes(userInfo?.user_role) && (
        <Button
          onClick={() => {
            history.pushWithRef("project/create");
          }}
          color="secondary"
          sx={{
            position: "fixed",
            margin: 0,
            top: "auto",
            right: 20,
            bottom: 20,
            left: "auto",
          }}
          size="large"
          endIcon={<AddCircle />}
        >
          帳票作成
        </Button>
      )}
    </GenericTemplate>
  );
};

export default ProjectListTlogScreen;
