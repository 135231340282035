import formatDateToString from "@utils/DateFormat";
import { User } from "services/models";
import { createObjectURL, getBase64Image, getFileByUrl } from "..";
import { getEscapedData } from "./";

export interface ImageInfoTypePreview extends Type.ImageInfoType {
  base64?: string;
}

export type ExteriorDamagedTCFormType = {
  risky_id: string; // 帳票番号
  date: string; // 日付
  arrival_date: string; // 入荷日
  exterior_damaged_location: any; // 外装破損発見場所
  exterior_damaged_location_other: string;
  written_by: any; // 庫内記入者
  written_by_other: string;
  checked_by: any; // 確認者
  checked_by_other: string;
  jan_code: string; // JANコード
  supplier_code: string; // 仕入先コード
  supplier_name: string; // 仕入先名
  product_name: string; // 商品名
  product_type: string; // 型名
  total: number | string; // 総外装破損数量
  status_1?: boolean; // 外装破損状況_1
  status_2?: boolean; // 外装破損状況_2
  status_3?: boolean; // 外装破損状況_3
  status_4?: boolean; // 外装破損状況_4
  status_5?: boolean; // 外装破損状況_5
  status_6?: boolean; // 外装破損状況_6
  status_text_1?: string; // 外装破損状況選択肢_1
  status_text_2?: string; // 外装破損状況選択肢_2
  status_text_3?: string; // 外装破損状況選択肢_3
  status_text_4?: string; // 外装破損状況選択肢_4
  status_text_5?: string; // 外装破損状況選択肢_5
  status_text_6?: string; // 外装破損状況選択肢_6
  image_array: Type.ImageInfoType[]; //状況写真
  sign_date: ImageInfoTypePreview | null; // 日付
  sign_company: ImageInfoTypePreview | null; // 運送会社
  sign_car_no: ImageInfoTypePreview | null; // 車番
  sign_name: ImageInfoTypePreview | null; // 氏名
  purchasing: boolean; // 仕入れました
  order_customer_count: number | string; // お客様注文商品数量
  order_replenishment_count: number | string; // 補充商品、割当商品数量
  non_purchasing: boolean; // 仕入不可
  non_purchasing_count: number | string; // 仕入不可商品数量
  memo: string; // 備考
  item_check_user: User | null; // 現品確認
  store_check_user: User | null; // 格納確認
  //  帳票表示文言 ----------------------------------------------------------
  document_title: string; // 帳票タイトル
  shipper_name: string; // 荷主名
  shipper_name_sub: string; //荷主拠点名
  shipper_address: string; // 荷主住所
  shipper_tel: string; // 荷主TEL
  shipper_fax: string; // 荷主FAX
  order_customer_label: string; // お客様注文商品文言(見出し)
  order_customer_text: string; // お客様注文商品文言(内容)
  order_replenishment_label: string; // 補充商品、割当商品文言(見出し)
  order_replenishment_text: string; // 補充商品、割当商品文言(内容)
  purchasing_statement: string; // 仕入可文言
  non_purchasing_statement: string; // 仕入不可文言
};

export const getInfoFromTemplate = (htmlString: string) => {
  let signatureExample: string = "";
  const imageProductExampleArray = htmlString.split("signature_driver_example");
  if (imageProductExampleArray.length >= 3) {
    signatureExample = imageProductExampleArray[1];
  }
  return {
    signatureExample,
  };
};

export const makeNewHtml = (
  newHtml: string,
  data: ExteriorDamagedTCFormType,
) => {
  data = getEscapedData(data);
  let {
    risky_id, // 帳票番号
    date, // 日付
    arrival_date, // 入荷日
    exterior_damaged_location, // 外装破損発見場所
    exterior_damaged_location_other,
    jan_code, // JANコード
    supplier_code, // 仕入先コード
    supplier_name, // 仕入先名
    product_name, // 商品名
    product_type, // 型名
    total, // 総外装破損数量
    purchasing, // 仕入れました
    order_customer_count, // お客様注文商品数量
    order_replenishment_count, // 補充商品、割当商品数量
    non_purchasing, // 仕入不可
    non_purchasing_count, // 仕入不可商品数量
    memo, // 備考
    document_title, // 帳票タイトル
    shipper_name, // 荷主名
    shipper_name_sub, //荷主拠点名
    shipper_address, // 荷主住所
    shipper_tel, // 荷主TEL
    shipper_fax, // 荷主FAX
    order_customer_label, // お客様注文商品文言(見出し)
    order_customer_text, // お客様注文商品文言(内容)
    order_replenishment_label, // 補充商品、割当商品文言(見出し)
    order_replenishment_text, // 補充商品、割当商品文言(内容)
    purchasing_statement, // 仕入可文言
    non_purchasing_statement, // 仕入不可文言
  } = data;

  // タイトル ================================
  if (supplier_name) {
    newHtml = newHtml.replace("<!-- {to_whom} -->", supplier_name);
  }
  if (risky_id) {
    newHtml = newHtml.replace("<!-- {risky_id} -->", risky_id ?? "");
  }
  if (document_title) {
    newHtml = newHtml.replace("<!-- {document_title} -->", document_title);
  }
  if (date) {
    const date_arr = formatDateToString(date, "YMD_sl").split("/");
    if (date_arr.length > 2) {
      newHtml = newHtml.replace("<!-- {year} -->", date_arr[0]);
      newHtml = newHtml.replace("<!-- {month} -->", date_arr[1]);
      newHtml = newHtml.replace("<!-- {date} -->", date_arr[2]);
    }
  }
  if (shipper_name) {
    newHtml = newHtml.replace("<!-- {shipper_name} -->", shipper_name);
  }
  if (shipper_name_sub) {
    newHtml = newHtml.replace("<!-- {shipper_name_sub} -->", shipper_name_sub);
  }
  if (shipper_address) {
    newHtml = newHtml.replace("<!-- {shipper_address} -->", shipper_address);
  }
  if (shipper_tel) {
    newHtml = newHtml.replace("<!-- {shipper_tel} -->", shipper_tel);
  }
  if (shipper_fax) {
    newHtml = newHtml.replace("<!-- {shipper_fax} -->", shipper_fax);
  }
  // メイン ================================
  if (arrival_date) {
    const date_arr = formatDateToString(arrival_date, "YMD_sl").split("/");
    if (date_arr.length > 2) {
      const replaceArrivalDate = `${date_arr[1]}/${date_arr[2]}`;
      newHtml = newHtml.replace("<!-- {arrival_date} -->", replaceArrivalDate);
    }
  }
  if (exterior_damaged_location) {
    newHtml = newHtml.replace(
      "<!-- {exterior_damaged_location} -->",
      exterior_damaged_location?.is_manual_input
        ? `${exterior_damaged_location_other}`
        : exterior_damaged_location?.item1 ?? "",
    );
  }
  if (supplier_code) {
    newHtml = newHtml.replace("<!-- {supplier_code} -->", supplier_code);
  }
  if (jan_code) {
    [...jan_code].forEach((value, index) => {
      newHtml = newHtml.replace(`<!-- {jan_code_${index}} -->`, value);
    });
  }
  if (product_name) {
    newHtml = newHtml.replace("<!-- {product_name} -->", product_name);
  }
  if (product_type) {
    newHtml = newHtml.replace("<!-- {product_type} -->", product_type);
  }
  if (total) {
    newHtml = newHtml.replace("<!-- {total} -->", total.toString());
  }
  [...Array(6)].forEach((_, index) => {
    const key = (index + 1).toString();
    const status_key: keyof ExteriorDamagedTCFormType = ("status_" +
      key) as any;
    const status_text_key: keyof ExteriorDamagedTCFormType = ("status_text_" +
      key) as any;
    if (data[status_key]) {
      newHtml = newHtml.replace(`<!-- {status_${key}} -->`, "✓");
    }
    if (data[status_text_key]) {
      newHtml = newHtml.replace(
        `<!-- {status_text_${key}} -->`,
        `${key}．${data[status_text_key]}`,
      );
    }
  });
  // 処理方法 ================================
  if (order_customer_label) {
    newHtml = newHtml.replace(
      "<!-- {order_customer_label} -->",
      order_customer_label,
    );
  }
  if (order_customer_text) {
    newHtml = newHtml.replace(
      "<!-- {order_customer_text} -->",
      order_customer_text,
    );
  }
  if (order_replenishment_label) {
    newHtml = newHtml.replace(
      "<!-- {order_replenishment_label} -->",
      order_replenishment_label,
    );
  }
  if (order_replenishment_text) {
    newHtml = newHtml.replace(
      "<!-- {order_replenishment_text} -->",
      order_replenishment_text,
    );
  }
  if (purchasing_statement) {
    newHtml = newHtml.replace(
      "<!-- {purchasing_statement} -->",
      purchasing_statement,
    );
  }
  if (non_purchasing_statement) {
    newHtml = newHtml.replace(
      "<!-- {non_purchasing_statement} -->",
      non_purchasing_statement,
    );
  }
  if (purchasing) {
    newHtml = newHtml.replace("<!-- {purchasing} -->", "✓");
    if (order_customer_count) {
      newHtml = newHtml.replace(
        "<!-- {order_customer_count} -->",
        order_customer_count.toString(),
      );
    }
    if (order_replenishment_count) {
      newHtml = newHtml.replace(
        "<!-- {order_replenishment_count} -->",
        order_replenishment_count.toString(),
      );
    }
  }
  if (non_purchasing) {
    newHtml = newHtml.replace("<!-- {non_purchasing} -->", "✓");
    if (non_purchasing_count) {
      newHtml = newHtml.replace(
        "<!-- {non_purchasing_count} -->",
        non_purchasing_count.toString(),
      );
    }
  }
  if (memo) {
    const replaceMemo = memo.split(/\r?\n/).join("<br/>");
    newHtml = newHtml.replace("<!-- {memo} -->", replaceMemo);
  }
  return newHtml;
};

export const insertReportLocal = (
  newHtml: string,
  data: ExteriorDamagedTCFormType,
  signatureExample: string,
  mergeTemplate = false,
) => {
  let { sign_date, sign_company, sign_car_no, sign_name } = data;
  let signatureExampleClone = "";

  if (sign_date) {
    signatureExampleClone = "";
    if (sign_date.uri) {
      signatureExampleClone = signatureExample.replace(
        "{image_url}",
        sign_date?.base64
          ? sign_date.base64
          : sign_date?.uri_jpg ?? sign_date?.uri,
      );
    }
    newHtml = newHtml.replace("<!-- {sign_date} -->", signatureExampleClone);
  }
  if (sign_company) {
    signatureExampleClone = "";
    if (sign_company.uri) {
      signatureExampleClone = signatureExample.replace(
        "{image_url}",
        sign_company?.base64
          ? sign_company.base64
          : sign_company?.uri_jpg ?? sign_company?.uri,
      );
    }
    newHtml = newHtml.replace("<!-- {sign_company} -->", signatureExampleClone);
  }
  if (sign_car_no) {
    signatureExampleClone = "";
    if (sign_car_no.uri) {
      signatureExampleClone = signatureExample.replace(
        "{image_url}",
        sign_car_no?.base64
          ? sign_car_no.base64
          : sign_car_no?.uri_jpg ?? sign_car_no?.uri,
      );
    }
    newHtml = newHtml.replace("<!-- {sign_car_no} -->", signatureExampleClone);
  }
  if (sign_name) {
    signatureExampleClone = "";
    if (sign_name.uri) {
      signatureExampleClone = signatureExample.replace(
        "{image_url}",
        sign_name?.base64
          ? sign_name.base64
          : sign_name?.uri_jpg ?? sign_name?.uri,
      );
    }
    newHtml = newHtml.replace("<!-- {sign_name} -->", signatureExampleClone);
  }
  return newHtml;
};

export const getImageArray = async (data: Array<any>) => {
  let image_array: Array<any> = [];
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    if (item) {
      const uri = item.uri_jpg ?? item.uri;
      if ((!item.base64 || item.base64.length === 0) && uri && uri.length > 0) {
        item.base64 = await getBase64Image(uri);
      }
    }
    image_array.push(item);
  }
  return image_array;
};

export const mergeTemplateExteriorDamagedTC = async (
  templateUri: string,
  data: ExteriorDamagedTCFormType,
  templateData: any,
) => {
  try {
    const f = await getFileByUrl(templateUri, "template.html", "text/html");
    let html_string = await f.text();
    const res = getInfoFromTemplate(html_string);
    const { signatureExample } = res;
    html_string = makeNewHtml(html_string, data);
    html_string = insertReportLocal(html_string, data, signatureExample, true);

    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    const html_blob = new Blob([html_string], { type: "text/html" });
    templateUri = createObjectURL(html_blob);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};
