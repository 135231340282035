import {
  AccidentHandling,
  AccidentReason,
  ImportDivision,
  ManualInput,
  OccurrenceLocation,
  Product,
  ProductStatus,
  Publisher,
  ShipperMaster,
  SpanMaster,
  User,
} from "services/models";
import { createDateText, createHtmlForMultipleText, getEscapedData } from "./";
import _ from "lodash";
import { createObjectURL, getBase64Image, getFileByUrl } from "..";

interface IPersonFoundIssue extends User, ManualInput {}
export interface ImageInfoTypePreview extends Type.ImageInfoType {
  base64?: string;
}

export type StateFormType = {
  risky_id: string;
  place_found_issue: Publisher | null;
  place_found_issue_other: string;
  incident_date: string;
  issue_place_floor: OccurrenceLocation | null;
  issue_place_span: SpanMaster | null;
  is_product_issue: boolean;
  is_packaging_issue: boolean;
  responsible_person: Publisher | null;
  responsible_person_other: string;
  person_found_issue: IPersonFoundIssue | null;
  person_found_issue_other: string;
  product_type: ShipperMaster | null;
  product_type_other: string;
  product_name: Product | null;
  amount_of_product: string;
  product_id: Array<string>;
  product_packaging_status: Array<ProductStatus> | ProductStatus;
  product_packaging_status_other: string;
  reason_cause_issue: AccidentReason | null;
  reason_cause_issue_other: string;
  memo: string;
  is_move_to_KF: boolean;
  is_move_product: boolean;
  image_array: Array<Type.ImageInfoType | ImageInfoTypePreview>;
  warehouse_place_floor: OccurrenceLocation | null;
  warehouse_place_span: SpanMaster | null;
  bad_place: string;
  importance_type: ImportDivision | null;
  date_shipper_take: string;
  shipper_name: User | null;
  receiver: string;
  instruction_solve_issue: AccidentHandling | null;
  instruction_solve_issue_other: string;
  completion_confirm_date: string;
  completion_confirm_person_in_charge: User | null;
  expense_claim: string;
  translate_product: string;
  translate_packaging: string;
  translate_other: string;
};

export const issueType = {
  product: "本体",
  packaging: "カートン（中身未検収）",
};

const getIssueType = (
  is_product_issue: boolean,
  is_packaging_issue: boolean,
  translate_product: string,
  translate_packaging: string,
) => {
  const arrayIssueType: Array<string> = [];
  if (is_product_issue) {
    arrayIssueType.push(translate_product);
  }
  if (is_packaging_issue) {
    arrayIssueType.push(translate_packaging);
  }
  return arrayIssueType.join(", ");
};

export const getInfoFromTemplate = (htmlString: string) => {
  let imageProductExample: string = "";
  let pageImageExample: string = "";
  const imageProductExampleArray = htmlString.split("image_product_example");
  if (imageProductExampleArray.length >= 3) {
    imageProductExample = imageProductExampleArray[1];
  }
  const pageImageExampleArray = htmlString.split("page_image_example");
  if (pageImageExampleArray.length >= 3) {
    pageImageExample = pageImageExampleArray[1];
  }
  return { imageProductExample, pageImageExample };
};

export const makeNewHtml = (newHtml: string, data: StateFormType) => {
  data = getEscapedData(data);
  let {
    risky_id,
    place_found_issue,
    place_found_issue_other,
    incident_date,
    issue_place_floor,
    issue_place_span,
    is_product_issue,
    is_packaging_issue,
    responsible_person,
    responsible_person_other,
    person_found_issue,
    person_found_issue_other,
    product_type,
    product_type_other,
    product_name,
    amount_of_product,
    product_id,
    product_packaging_status,
    product_packaging_status_other,
    reason_cause_issue,
    reason_cause_issue_other,
    memo,
    warehouse_place_floor,
    warehouse_place_span,
    bad_place,
    importance_type,
    date_shipper_take,
    shipper_name,
    receiver,
    instruction_solve_issue,
    instruction_solve_issue_other,
    completion_confirm_date,
    completion_confirm_person_in_charge,
    expense_claim,
    translate_product,
    translate_packaging,
    translate_other,
  } = data;

  memo = createHtmlForMultipleText(memo);

  if (risky_id) {
    newHtml = newHtml.replace("<!-- {risky_id} -->", risky_id);
  }
  if (place_found_issue) {
    newHtml = newHtml.replace(
      "<!-- {place_found_issue} -->",
      place_found_issue?.is_manual_input
        ? `${place_found_issue_other}`
        : place_found_issue?.publisher_name ?? "",
    );
  }
  newHtml = newHtml.replace(
    "<!-- {incident_date} -->",
    createDateText(incident_date),
  );
  if (issue_place_floor) {
    newHtml = newHtml.replace(
      "<!-- {issue_place_floor} -->",
      issue_place_floor?.occurrence_location_name ?? "",
    );
  }
  if (issue_place_span) {
    newHtml = newHtml.replace(
      "<!-- {issue_place_span} -->",
      issue_place_span?.span_name ?? "",
    );
  }
  newHtml = newHtml.replace(
    "<!-- {issue_type} -->",
    getIssueType(
      is_product_issue,
      is_packaging_issue,
      translate_product,
      translate_packaging,
    ),
  );
  if (responsible_person) {
    newHtml = newHtml.replace(
      "<!-- {responsible_person} -->",
      responsible_person?.is_manual_input
        ? responsible_person_other
        : responsible_person?.publisher_name ?? "",
    );
  }
  if (person_found_issue) {
    newHtml = newHtml.replace(
      "<!-- {person_found_issue} -->",
      person_found_issue.is_manual_input
        ? person_found_issue_other
        : person_found_issue?.full_name ?? "",
    );
  }
  if (product_type) {
    const product_type_text = product_type?.is_manual_input
      ? `${product_type_other}`
      : product_type?.shipper_name ?? "";
    const len = countString(product_type_text);
    const class_name = len > 30 ? "div-product-value1-1-s" : "";

    newHtml = newHtml.replace(
      "<!-- {product_type} -->",
      class_name == ""
        ? product_type_text
        : `<div class="${class_name}">${product_type_text}</div>`,
    );
  }
  if (product_name) {
    const product_name_text = product_name?.product_name ?? "";
    const len = countString(product_name_text);
    const class_name = len > 94 ? "div-product-value2-s" : "";

    newHtml = newHtml.replace(
      "<!-- {product_name} -->",
      class_name == ""
        ? product_name_text
        : `<div class="${class_name}">${product_name_text}</div>`,
    );
  }
  newHtml = newHtml.replace("<!-- {amount_of_product} -->", amount_of_product);
  // newHtml = newHtml.replace("<!-- {product_id} -->", product_id.join(", "));
  for (let index = 0; index < product_id.length; index++) {
    newHtml = newHtml.replace(
      `<!-- {product_id_${index}} -->`,
      product_id[index],
    );
  }

  if (product_packaging_status) {
    if (Array.isArray(product_packaging_status)) {
      let product_packaging_status_string = product_packaging_status
        .filter((item) => !item?.is_manual_input)
        .map((item) => item?.product_status_name ?? "");
      if (product_packaging_status.some((item) => item?.is_manual_input)) {
        product_packaging_status_string.push(
          `${translate_other}（${product_packaging_status_other})`,
        );
      }
      newHtml = newHtml.replace(
        `<!-- product_packaging_status -->`,
        product_packaging_status_string.join("、"),
      );
    } else {
      newHtml = newHtml.replace(
        "<!-- product_packaging_status -->",
        product_packaging_status?.is_manual_input
          ? `${translate_other}（${product_packaging_status_other})`
          : product_packaging_status?.product_status_name ?? "",
      );
    }
  }
  if (reason_cause_issue) {
    newHtml = newHtml.replace(
      "<!-- {reason_cause_issue} -->",
      reason_cause_issue?.is_manual_input
        ? `${translate_other}（${reason_cause_issue_other})`
        : reason_cause_issue?.accident_reason_name ?? "",
    );
  }

  const lenMemo = countString(memo);
  const class_name_memo = lenMemo > 144 ? "div-memo-value-s" : "";
  newHtml = newHtml.replace(
    "<!-- {memo} -->",
    class_name_memo === ""
      ? memo
      : `<div class="${class_name_memo}">${memo}</div>`,
  );
  if (warehouse_place_floor) {
    newHtml = newHtml.replace(
      "<!-- {warehouse_place_floor} -->",
      warehouse_place_floor?.occurrence_location_name ?? "",
    );
  }
  if (warehouse_place_span) {
    newHtml = newHtml.replace(
      "<!-- {warehouse_place_span} -->",
      warehouse_place_span?.span_name ?? "",
    );
  }
  newHtml = newHtml.replace("<!-- {bad_place} -->", bad_place);
  if (importance_type) {
    newHtml = newHtml.replace(
      "<!-- {importance_type} -->",
      importance_type?.import_division_name ?? "",
    );
  }
  newHtml = newHtml.replace(
    "<!-- {date_shipper_take} -->",
    createDateText(date_shipper_take),
  );
  if (shipper_name) {
    newHtml = newHtml.replace(
      "<!-- {shipper_name} -->",
      shipper_name?.full_name ?? "",
    );
  }
  newHtml = newHtml.replace("<!-- {receiver} -->", receiver);
  if (instruction_solve_issue) {
    newHtml = newHtml.replace(
      "<!-- {instruction_solve_issue} -->",
      instruction_solve_issue?.is_manual_input
        ? instruction_solve_issue_other
        : instruction_solve_issue?.accident_handling_name ?? "",
    );
  }
  newHtml = newHtml.replace(
    "<!-- {completion_confirm_date} -->",
    createDateText(completion_confirm_date),
  );
  if (completion_confirm_person_in_charge) {
    newHtml = newHtml.replace(
      "<!-- {completion_confirm_person_in_charge} -->",
      completion_confirm_person_in_charge?.full_name ?? "",
    );
  }
  newHtml = newHtml.replace("<!-- {expense_claim} -->", expense_claim);

  return newHtml;
};

export const getImageArray = async (data: Array<any>) => {
  let image_array: Array<any> = [];
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    const uri = item?.uri_jpg ?? item?.uri;
    if ((!item.base64 || item.base64.length === 0) && uri && uri.length > 0) {
      item.base64 = await getBase64Image(uri);
    }
    image_array.push(item);
  }
  return image_array;
};

export const insertReportLocal = (
  newHtml: string,
  data: StateFormType,
  imageProductExample: string,
  pageImageExample: string,
  image_array: Array<ImageInfoTypePreview>,
  mergeTemplate = false,
) => {
  data = getEscapedData(data);
  let {
    risky_id,
    incident_date,
    is_product_issue,
    is_packaging_issue,
    // image_array,
    warehouse_place_floor,
    warehouse_place_span,
    product_name,
    amount_of_product,
    translate_product,
    translate_packaging,
  } = data;

  if (image_array?.length === 0) {
    return newHtml;
  } else {
    const image_array_chunk = _.chunk(image_array, 6);

    for (const imageArray of image_array_chunk) {
      let pageImageExampleClone = pageImageExample;

      if (risky_id) {
        pageImageExampleClone = pageImageExampleClone.replace(
          '<span class="hide">{risky_id}</span>',
          risky_id,
        );
      }

      pageImageExampleClone = pageImageExampleClone.replace(
        "{incident_date}",
        createDateText(incident_date),
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{issue_type}",
        getIssueType(
          is_product_issue,
          is_packaging_issue,
          translate_product,
          translate_packaging,
        ),
      );

      pageImageExampleClone = pageImageExampleClone.replace(
        "{warehouse_place_floor}",
        warehouse_place_floor?.occurrence_location_name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{warehouse_place_span}",
        warehouse_place_span?.span_name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{product_name}",
        product_name?.product_name ?? "",
      );
      pageImageExampleClone = pageImageExampleClone.replace(
        "{amount_of_product}",
        amount_of_product,
      );

      imageArray.map((item: ImageInfoTypePreview) => {
        if (item?.uri) {
          let imageProductExampleClone = imageProductExample;
          imageProductExampleClone = imageProductExampleClone.replace(
            "{image_url}",
            `'${item?.base64 ? item?.base64 : item?.uri_jpg ?? item?.uri}'`,
          );
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_product_waiting}",
            imageProductExampleClone,
          );
        } else {
          pageImageExampleClone = pageImageExampleClone.replace(
            "{image_product_waiting}",
            "",
          );
        }
      });

      pageImageExampleClone = pageImageExampleClone.replaceAll(
        "{image_product_waiting}",
        "",
      );

      newHtml = newHtml.replace(
        "<!-- {add_more_area} -->",
        `
        ${pageImageExampleClone}
        <!-- {add_more_area} -->
      `,
      );
    }

    return newHtml;
  }
};

// export const mergeTemplateReportRiskyId = async (
//   templateUri: string,
//   data: StateFormType,
// ) => {
//   try {
//     await RNFS.readFile(templateUri, "utf8").then(async (content) => {
//       const res = getInfoFromTemplate(content);
//       const { imageProductExample, pageImageExample } = res;
//       content = makeNewHtml(content, data);
//       content = insertReportLocal(
//         content,
//         data,
//         imageProductExample,
//         pageImageExample,
//         true,
//       );
//       const path = `${RNFS.DocumentDirectoryPath}/Genbastar_${new Date()
//         .getTime()
//         .toString()}.html`;
//       await RNFS.writeFile(path, content, "utf8")
//         .then((res) => {
//           templateUri = path;
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     });
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
//   return templateUri;
// };

export const handleEditInfoRiskyId = (templateEditInfo: any) => {
  const newTemplateEditInfo = { ...templateEditInfo };
  return newTemplateEditInfo;
};

export const mergeTemplateReportRiskyId = async (
  templateUri: string,
  data: StateFormType,
) => {
  try {
    const f = await getFileByUrl(templateUri, "template.html", "text/html");
    let html_string = await f.text();
    const res = getInfoFromTemplate(html_string);
    const { imageProductExample, pageImageExample } = res;
    html_string = makeNewHtml(html_string, data);
    html_string = insertReportLocal(
      html_string,
      data,
      imageProductExample,
      pageImageExample,
      data.image_array,
      true,
    );

    const doc = document.createElement("div");
    doc.appendChild(
      document.createRange().createContextualFragment(html_string),
    );
    var serializeHtml = new XMLSerializer().serializeToString(doc);
    const svg = new Blob([serializeHtml], { type: "text/html" });
    templateUri = createObjectURL(svg);
  } catch (err) {
    console.log(err);
    return null;
  }
  return templateUri;
};

export const deleteImageDataWhenFinishRiskyId = (templateEditInfo: any) => {
  // if (templateEditInfo) {
  //   const image_array = templateEditInfo.image_array;
  //   const newImageArray = image_array.map(() => {
  //     return {
  //       name: "",
  //       type: "",
  //       uri: "",
  //     };
  //   });
  //   templateEditInfo = {
  //     ...templateEditInfo,
  //     image_array: newImageArray,
  //   };
  //   return templateEditInfo;
  // } else return templateEditInfo;
  return templateEditInfo;
};

const countString = (text: string) => {
  return text.split("").reduce((count, char) => {
    const len = Math.min(new Blob([char]).size, 2);
    return count + len;
  }, 0);
};
