import {
  apiGetChildrenGroup4GroupMaster,
  apiGetListGroupMaster,
  apiGetMemberByConditions,
} from "@api/flowApproval";
import { getListActivityBase } from "@api/groupMaster";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import { Colors } from "@template/style";
import { getGroupSelectedData, getUserInfo } from "@utils/index";
import SelectLabel from "components/atoms/SelectLabel";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityBase } from "services/models";
import { RootState } from "store/reducer";
import { ApproveState } from "store/reducer/ApproveFlowReducer";
import { TYPES } from "store/types";

interface IActivityBase extends ActivityBase {
  selectedData?: ISelectedData;
}

interface IAllGroup extends Type.GroupInfo {
  children?: IAllGroup[]; // 下位階層のグループマスタ情報
}

type ISelectedData = { [key: string]: string };

/**
 * ツリーデータループ処理
 */
const loop = (
  data: IAllGroup[],
  isMatch: (item: IAllGroup) => boolean,
  callback: (item: IAllGroup) => void,
  level: number = 1,
) => {
  for (let index = 0; index < data.length; index++) {
    let item = data[index];
    if (isMatch(item)) {
      callback(item);
      return;
    }
    if (item.children) {
      loop(item.children, isMatch, callback, level + 1);
    }
  }
};

export const FilterMemberArea: React.FC = () => {
  const approvalFlowState: ApproveState = useSelector(
    (state: RootState) => state.approveFlow,
  );
  const { textSearch, currentStepId, stepList } = approvalFlowState;
  useEffect(() => {
    dispatch({ type: TYPES.CLEAR_MEMBER_LIST_SEARCH });
  }, [textSearch]);
  const [locationId, setLocationId] = useState<string>("");
  const [listActivityBase, setListActivityBase] = useState<IActivityBase[]>([]);
  const [selectedData, setSelectedData] = useState<ISelectedData>({});
  const [listGroupMaster, setListGroupMaster] = useState<Type.MasterGroup[]>(
    [],
  );
  const [listAllGroup, setListAllGroup] = useState<IAllGroup[]>([]);
  const [readOnlyGroup, setReadOnlyGroup] = useState<{
    [key: string]: boolean;
  }>({});
  const initialDataRef = useRef<IAllGroup[]>();
  const dispatch = useDispatch();
  const groupInfoDefault: Type.GroupInfo = {
    updated_at: "",
    group_master_id: "",
    created_at: "",
    created_by: "",
    group_parentid: "",
    location_id: "",
    SK: "",
    PK: "",
    seq: -1,
    group_id: "",
    group_name: "未選択",
    isSelected: true,
  };

  // データ取得 ------------------------------------------------
  const fetchData = async (
    groupParentID?: string,
    initialDataFlg?: boolean,
  ) => {
    let isFirst = groupParentID === undefined;
    let res: Type.GroupInfo[] = [];

    if (isFirst) {
      // グループ階層を取得
      const res_master: Type.MasterGroup[] = await apiGetListGroupMaster();
      groupParentID = res_master[0].PK;
      fetchActivityBase(res_master.length);
      setListGroupMaster(res_master);
    }

    // グループを取得
    res = await apiGetChildrenGroup4GroupMaster({
      group_parentid: groupParentID ?? "CONTRACT#",
    });

    if (isFirst) {
      setListAllGroup(JSON.parse(JSON.stringify(res)));
      initialDataRef.current = res;
    } else {
      let new_data: IAllGroup[] = JSON.parse(
        JSON.stringify(initialDataRef.current ?? listAllGroup),
      );
      const callback = (item: IAllGroup) => {
        item.children = [...res];
      };
      loop(new_data, (item) => item.SK === groupParentID, callback, 1);
      setListAllGroup(() => new_data);

      if (initialDataFlg) initialDataRef.current = new_data;
    }
    return res;
  };

  const fetchActivityBase = async (group_level_count: number = 1) => {
    const res_ab = await getListActivityBase("", true);
    if (res_ab.length > 0) {
      let list_ab: IActivityBase[] = [];
      for (let index = 0; index < res_ab.length; index++) {
        const element = res_ab[index];
        const selectedData: ISelectedData = getGroupSelectedData(
          element.list_group,
          group_level_count,
        );
        element["selectedData"] = selectedData;
        list_ab.push(element);
      }
      setListActivityBase(list_ab);
    }
  };

  const setDefaultSelectedData = async () => {
    // ログインアカウントの所属拠点を取得
    const userInfo = getUserInfo();
    const targetLocationId = userInfo.location_id;
    setLocationId(targetLocationId);

    // 初期選択拠点を設定
    const userActivityBase = listActivityBase.find(
      (d) => d.SK === targetLocationId,
    );
    const initialSelect = userActivityBase
      ? userActivityBase.selectedData
      : undefined;
    setSelectedData({ ...initialSelect });

    if (initialSelect) {
      // 絞り込み条件をログインアカウントの所属拠点の下位のみとする
      let read_only_group: { [key: string]: boolean } = {};
      Object.keys(initialSelect).forEach((key, i) => {
        read_only_group[i + 1] = initialSelect
          ? initialSelect[i + 1] !== ""
          : false;
      });
      setReadOnlyGroup(read_only_group);

      // 初期選択拠点の上位階層のデータを取得する
      const keys = Object.keys(initialSelect);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        if (initialSelect[key]) await fetchData(initialSelect[key], true);
      }
    }
    initialDataRef.current = undefined;
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    //　拠点の初期値を設定
    if (listActivityBase.length > 0) {
      setDefaultSelectedData();
    }
  }, [listActivityBase]);

  // 入力制御 ------------------------------------------------
  const handleSelectOption = async (
    level: number, // levelは1から数える
    value: string,
  ) => {
    let item_list: IAllGroup[] = JSON.parse(JSON.stringify(listAllGroup));
    let selectedSK = "";
    let selected_data = { ...selectedData };

    // 選択したグループの子階層のグループを取得
    for (let index = 0; index < level; index++) {
      selectedSK = index + 1 === level ? value : selected_data[index + 1];
      if (selectedSK === "") continue;

      let tmp = item_list.find((d) => d.SK === selectedSK);
      if (tmp && tmp.children) {
        item_list = tmp.children ?? [];
      } else {
        const res = await fetchData(value);
        item_list = res ?? [];
      }
    }

    // 選択したグループより下の階層をリセット
    for (let index = level + 1; index <= listGroupMaster.length; index++) {
      selected_data[index] = "";
    }
    setSelectedData({ ...selected_data, [level]: value });

    dispatch({ type: TYPES.CLEAR_MEMBER_LIST_SEARCH });
  };

  const lastLevel = useMemo(() => {
    if (selectedData && Object.keys(selectedData).length > 0) {
      return Object.keys(selectedData).reduce((a, b) => {
        if (selectedData[b] === "") return a;
        return Math.max(Number(a), Number(b)).toString();
      });
    } else {
      return "-1";
    }
  }, [selectedData]);

  const renderFilterArea = useMemo(() => {
    const filterArea: any = [];
    let listParentGroup: any[] = [];
    for (let i = 0; i < listGroupMaster.length; i++) {
      const level = i + 1;
      let listItem: any[] = []; // MenuItem

      if (i === 0) {
        listItem = cloneDeep(listAllGroup);
      } else {
        let tmp = listParentGroup.find((d) => d.SK === selectedData[level - 1]);
        if (tmp && tmp.children) {
          listItem = tmp.children;
        }
      }
      listItem.splice(0, 0, groupInfoDefault); // 未選択の選択肢を先頭に追加
      listParentGroup = listItem;
      let selected_group = listItem.find((d) => d.SK === selectedData[level]);

      const item = !readOnlyGroup[level] ? (
        <SelectLabel
          key={listGroupMaster[i].SK}
          label={listGroupMaster[i].group_master_name}
          sx={[styles.selectLabel, { mr: 2 }]}
          onChange={(e) => {
            handleSelectOption(level, e.target.value);
          }}
          value={listItem.length > 0 ? selectedData[level] || "" : ""}
          disabled={level > Number(lastLevel) && selectedData[level - 1] == ""}
          isIgnoreExtractRuleLabel={true}
          isIgnoreExtractRuleSelect={true}
          displayEmpty
        >
          {listItem &&
            listItem.map((option) => {
              return (
                <MenuItem key={option.SK} value={option.SK}>
                  {option.SK != "" ? (
                    <span className="IgnoreExtractRuleTarget">
                      {option.group_name}
                    </span>
                  ) : (
                    <span className="IgnoreExtractRuleTarget">
                      {option.group_name}
                    </span>
                  )}
                </MenuItem>
              );
            })}
        </SelectLabel>
      ) : (
        <div key={i} style={{ ...styles.selectLabel, display: "inline-block" }}>
          <Typography>
            <span className="IgnoreExtractRuleTarget">
              {listGroupMaster[i].group_master_name}
            </span>
          </Typography>
          <Typography sx={styles.selectTypography}>
            <span className="IgnoreExtractRuleTarget">
              {selected_group?.group_name}
            </span>
          </Typography>
        </div>
      );
      filterArea.push(item);
    }
    return filterArea;
  }, [listGroupMaster, listAllGroup, readOnlyGroup, selectedData, lastLevel]);

  // 検索 ------------------------------------------------
  const handleStoreListMember = (members: Type.UserInfo[]) => {
    const linkedGroup: string[] = [];
    let listItem = cloneDeep(listAllGroup);
    Object.values(selectedData).forEach((value) => {
      if (value) {
        const selected = listItem.find((v) => v.SK == value);
        if (selected) {
          linkedGroup.push(selected.group_name);
          listItem = cloneDeep(selected.children ?? []);
        }
      }
    });

    const convertedMemberList = members.map((item) => {
      let targetLinkedGroup = linkedGroup.join(" ");
      let SK = "";
      let PK = "";
      const memberId = `${item.user_id}|${item.group_id}`;
      const isExistApprover = stepList.find(
        (item) =>
          item.stepId === currentStepId &&
          item.approvers.find((approver) => {
            if (approver.memberId === memberId) {
              SK = approver.SK;
              PK = approver.PK;
              return true;
            }
            return false;
          }),
      );
      const convertedItem: Type.Member = {
        groupId: item.group_id,
        memberId: memberId,
        name: item.full_name,
        linkedGroup: targetLinkedGroup + " " + item.path_group,
        isSelected: isExistApprover ? true : false,
        linkAvatar: item.avatar,
        SK,
        PK,
        isDisable: false,
      };
      return convertedItem;
    });
    const targetStep = stepList.find((item) => item.stepId === currentStepId);
    if (targetStep) {
      for (let i = 0; i < convertedMemberList.length - 1; i++) {
        var tmpLinkedGroup = [convertedMemberList[i].linkedGroup];
        for (let j = convertedMemberList.length - 1; j > i; j--) {
          if (
            convertedMemberList[i].memberId.split("|")[0] ===
            convertedMemberList[j].memberId.split("|")[0]
          ) {
            // 同一アカウントは、一行にまとめる
            tmpLinkedGroup.splice(1, 0, convertedMemberList[j].linkedGroup);
            convertedMemberList.splice(j, 1);
          }
        }
        convertedMemberList[i].linkedGroup = tmpLinkedGroup.join(" / "); // 所属グループを/区切りで表示
      }
      targetStep.currentSearch = convertedMemberList;
    }
    dispatch({
      type: TYPES.SET_MEMBER_LIST_SEARCH,
      payload: [...stepList],
    });
  };

  const handleSearchListMember = async () => {
    dispatch({
      type: TYPES.SET_CLICK_ON_SEARCH,
    });

    let memberListRes: Type.UserInfo[] = [];
    const params = {
      sk_group: selectedData[lastLevel],
      key_word: textSearch ? textSearch : undefined,
      location_id: locationId,
    };
    memberListRes = await apiGetMemberByConditions(params);
    handleStoreListMember(memberListRes);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filterArea}>{renderFilterArea}</Box>
      <Box sx={styles.searchBtnCustomPosition}>
        <Button sx={styles.searchBtnCustom} onClick={handleSearchListMember}>
          検索
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  selectLabel: { width: 300, height: 50 },
  container: {
    my: 0,
    mx: 3,
  },
  searchBtnCustom: {
    backgroundColor: Colors.MAIN_GREEN,
    color: "white",
  },
  searchBtnCustomPosition: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterArea: {
    height: 105,
    overflow: "auto",
    whiteSpace: "nowrap",
    scrollbarWidth: 10,
    borderBottom: 1,
    borderBottomColor: Colors.BORDER,
    marginBottom: 1,
  },
  selectTypography: {
    color: Colors.DISABLE_BTN_TEXT,
    ml: 2,
    mt: 2,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
} as const;
