import React, { useEffect, useState } from "react";
import {
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { EXCEL_TEMPLATE_INPUT } from "@shared-constants";
import _, { cloneDeep } from "lodash";
import { TemplateExcelDataInfo } from "services/models";
import { IWorkSearchForm } from "../SearchDialog";
import { getTemplateKey } from "@utils/template/excelTemplate";
import formatDateToString from "@utils/DateFormat";
import { Validation } from "@validation";
import CheckboxLabel from "components/atoms/CheckboxLabel";
import DateRangePicker from "components/atoms/DatePickerCustom/DateRangePicker";
import AccordionSection from "components/molecules/AccordionSection";

export interface IExcelTemplateSearchForm {
  [coordinate: string]: {
    input: number;
    name: string;
    value: any;
    is_group: boolean;
    label?: string;
  };
}
interface IExcelTemplateFormError {
  [coordinate: string]: any;
}

interface IExcelTemplateDetail {
  templateInfo: any;
  stateForm: IWorkSearchForm;
  onChange: Function;
  setFormError: Function;
}

var templateSK = "";

const ExcelTemplateDetail = ({
  templateInfo,
  stateForm: allStateForm,
  onChange,
  setFormError: changeFormError,
}: IExcelTemplateDetail) => {
  const [stateForm, setStateForm] = useState<IExcelTemplateSearchForm>({});
  const [formError, setFormError] = useState<IExcelTemplateFormError>({});
  const inputSetting: Array<TemplateExcelDataInfo> =
    templateInfo?.data_info ?? [];
  const extraPage: Array<Array<TemplateExcelDataInfo>> =
    templateInfo?.extra_template?.map((item: any) => item.data_info) ?? [];

  // 検索条件不使用の帳票入力設定
  const ListIgnoreInput: Array<number> = [
    EXCEL_TEMPLATE_INPUT.IMAGE,
    EXCEL_TEMPLATE_INPUT.IMAGE_PAGE,
    EXCEL_TEMPLATE_INPUT.REPORT_NO,
    EXCEL_TEMPLATE_INPUT.USER_SEAL,
    EXCEL_TEMPLATE_INPUT.APPROVED_SEAL,
    EXCEL_TEMPLATE_INPUT.APPROVED_DATE,
    EXCEL_TEMPLATE_INPUT.SIGNATURE,
    EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
  ];

  useEffect(() => {
    // 初期データを取得・設定
    if (templateInfo) getData();
  }, [templateInfo]);

  useEffect(() => {
    // 入力値を更新
    const cloneAllStateForm = cloneDeep(allStateForm);
    cloneAllStateForm.excel_template = { ...stateForm };
    if (!_.isEqual(allStateForm, cloneAllStateForm)) {
      onChange(cloneDeep(cloneAllStateForm));
    }
  }, [stateForm, allStateForm]);

  useEffect(() => {
    // 入力エラーを更新
    let isError = false;
    Object.values(formError).forEach((value) => {
      if (typeof value === "string") {
        isError = value.length > 0;
      } else if (typeof value === "object") {
        if (value.some((v: string) => v.length > 0)) {
          isError = true;
        }
      }
    });
    changeFormError((prev: any) => ({
      ...prev,
      excel_template: isError ? "error" : "",
    }));
  }, [formError]);

  const getInitialStateForm = (
    info: TemplateExcelDataInfo,
    templateNo: number,
  ) => {
    let data: IExcelTemplateSearchForm = {};
    // 検索条件に使用しない入力設定は除外
    if (ListIgnoreInput.find((v) => v === info.input)) return data;

    const coordinate = getTemplateKey(info.coordinate, templateNo);
    if (info.input === EXCEL_TEMPLATE_INPUT.INPUT_GROUP) {
      info.group_info?.map((g) => {
        data[coordinate + "@" + g.coordinate] = {
          input: g.input,
          name: g.name,
          value: undefined,
          is_group: true,
        };
      });
    } else {
      data[coordinate] = {
        input: info.input,
        name: info.name,
        value: undefined,
        is_group: false,
      };
    }
    return data;
  };

  const getData = async () => {
    if (templateInfo.SK !== templateSK) {
      let newStateForm: IExcelTemplateSearchForm = {};
      inputSetting.map((item) => {
        newStateForm = { ...newStateForm, ...getInitialStateForm(item, 0) };
      });
      extraPage.map((value, index) => {
        value.map((item) => {
          newStateForm = {
            ...newStateForm,
            ...getInitialStateForm(item, index + 1),
          };
        });
      });
      setStateForm(newStateForm);
      templateSK = templateInfo.SK;
    } else {
      setStateForm(cloneDeep(allStateForm.excel_template));
    }
  };

  return (
    <Stack mt={1}>
      {inputSetting.length > 0 && <Divider>テンプレート1</Divider>}
      {inputSetting.map((item, index) => {
        // 検索条件に使用しない入力設定の場合は入力項目を表示しない
        if (ListIgnoreInput.find((v) => v === item.input)) return null;
        const coordinate = getTemplateKey(item.coordinate, 0);
        return (
          <SearchInput
            info={item}
            stateForm={stateForm}
            onChange={(value) => {
              setStateForm(value);
            }}
            coordinate={coordinate}
            setFormError={(mess) =>
              setFormError({ ...formError, [coordinate]: mess })
            }
            key={item.coordinate + "-" + index}
          />
        );
      })}
      {extraPage.map((value, index) => (
        <React.Fragment key={index}>
          <Divider>テンプレート{index + 2}</Divider>
          {value.map((item, n) => {
            // 検索条件に使用しない入力設定の場合は入力項目を表示しない
            if (ListIgnoreInput.find((v) => v === item.input)) return null;
            const coordinate = getTemplateKey(item.coordinate, index + 1);
            return (
              <SearchInput
                info={item}
                stateForm={stateForm}
                onChange={(value) => {
                  setStateForm(value);
                }}
                coordinate={coordinate}
                setFormError={(mess) => {
                  setFormError({ ...formError, [coordinate]: mess });
                }}
                key={item.coordinate + "-" + index + "-" + n}
              />
            );
          })}
        </React.Fragment>
      ))}
    </Stack>
  );
};

const SearchInput = ({
  info,
  stateForm,
  coordinate,
  onChange,
  setFormError,
}: {
  info: TemplateExcelDataInfo;
  stateForm: IExcelTemplateSearchForm;
  coordinate: string;
  onChange: (value: IExcelTemplateSearchForm) => void;
  setFormError: (value: any) => void;
}) => {
  const [formValue, setFormValue] = useState<any>("");
  const [errorMessage, setErrorMessage] = useState<any>("");

  useEffect(() => {
    setFormValue(
      stateForm[coordinate] && stateForm[coordinate].value !== undefined
        ? stateForm[coordinate].value
        : getInitialValue(info.input),
    );
  }, [info, stateForm, coordinate]);

  useEffect(() => {
    if (info.input === EXCEL_TEMPLATE_INPUT.DATE) {
      setErrorMessage(["", ""]);
    } else {
      setErrorMessage("");
    }
  }, [info]);

  useEffect(() => {
    setFormError(errorMessage);
  }, [errorMessage]);

  const getInitialValue = (input: number) => {
    switch (input) {
      case EXCEL_TEMPLATE_INPUT.DATE:
        return ["", ""]; // [0]: 開始日 / [1]: 終了日
      case EXCEL_TEMPLATE_INPUT.RADIO:
        return []; // チェック済み項目のindex番号の配列
      default:
        return "";
    }
  };

  const handleChange = (value: any) => {
    let label = value;
    if (info.input === EXCEL_TEMPLATE_INPUT.DATE) {
      if (value[0] == "" && value[1] == "") {
        label = "";
      } else {
        label =
          formatDateToString(value[0], "YMD_sl") +
          " ~ " +
          formatDateToString(value[1], "YMD_sl");
      }
    } else if (info.input === EXCEL_TEMPLATE_INPUT.CHECKBOX) {
      label = value === "true" ? "チェックあり" : "チェックなし";
    } else if (info.input === EXCEL_TEMPLATE_INPUT.RADIO) {
      const selected_items: string[] = value.map((key: number) => {
        if (info.radio) return info.radio[key].label;
        return "";
      });
      label = selected_items.filter(Boolean).join(",");
    }
    onChange({
      ...stateForm,
      [coordinate]: { ...stateForm[coordinate], value, label },
    });
    setFormValue(value);
  };

  const getDateValue = (isFrom: boolean) => {
    const currentValue = formValue ?? [];
    const index = isFrom ? 0 : 1;
    return currentValue.length > 1 ? currentValue[index] : undefined;
  };

  const handleChangeDate = (newValue: string | null, isFrom: boolean) => {
    let displayValue = "";
    if (!newValue) {
      newValue = "";
    } else {
      // YMDだと二桁の日が直接入力不可だが、バリデーションには使う
      newValue = formatDateToString(newValue, "YMD");
      displayValue = formatDateToString(newValue, "YMd");
    }
    let errorMessage = new Array(2).fill("");
    let mess = "";
    if (newValue) {
      mess = Validation.validateDate(newValue, info.name, false);
      errorMessage[isFrom ? 0 : 1] = mess;
    }
    setErrorMessage(errorMessage);

    if (mess.length === 0) {
      // 日付範囲のチェック
      const start = isFrom ? newValue : getDateValue(true);
      const end = !isFrom ? newValue : getDateValue(false);
      let mess_start = "";
      let mess_end = "";
      if (typeof start == "string" && typeof end == "string") {
        if (start && end) {
          mess_start = Validation.validateDateRange(
            new Date(start),
            new Date(end),
          );
          if (mess_start) {
            mess_end = mess_start;
          }
        }
        setErrorMessage([mess_start, mess_end]);
      }
    }

    let newFormValue = [];
    newFormValue[isFrom ? 0 : 1] = displayValue;
    newFormValue[!isFrom ? 0 : 1] = getDateValue(!isFrom);
    handleChange(newFormValue);
    setFormValue(newFormValue);
  };

  switch (info.input) {
    case EXCEL_TEMPLATE_INPUT.TEXT:
    case EXCEL_TEMPLATE_INPUT.MASTER:
    case EXCEL_TEMPLATE_INPUT.USER_NAME:
    case EXCEL_TEMPLATE_INPUT.CALC:
    case EXCEL_TEMPLATE_INPUT.NUMBER:
    case EXCEL_TEMPLATE_INPUT.TIME:
    case EXCEL_TEMPLATE_INPUT.BARCODE:
      return (
        <TextField
          label={info.name}
          value={formValue}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
        />
      );
    case EXCEL_TEMPLATE_INPUT.DATE:
      return (
        <DateRangePicker
          label={info.name}
          value={(formValue ?? []) as Array<any>}
          onChange={[
            (v) => {
              handleChangeDate(v, true);
            },
            (v) => {
              handleChangeDate(v, false);
            },
          ]}
          onBlur={[
            () => {
              let newFormValue = cloneDeep(formValue);
              newFormValue[0] = formatDateToString(newFormValue[0], "YMD");
              handleChange(newFormValue);
              setFormValue(newFormValue);
            },
            () => {
              let newFormValue = cloneDeep(formValue);
              newFormValue[1] = formatDateToString(newFormValue[1], "YMD");
              handleChange(newFormValue);
              setFormValue(newFormValue);
            },
          ]}
          onAccept={[
            (v) => {
              let newFormValue = cloneDeep(formValue);
              newFormValue[0] = formatDateToString(v, "YMD");
              handleChange(newFormValue);
              setFormValue(newFormValue);
            },
            (v) => {
              let newFormValue = cloneDeep(formValue);
              newFormValue[1] = formatDateToString(v, "YMD");
              handleChange(newFormValue);
              setFormValue(newFormValue);
            },
          ]}
          helperText={errorMessage}
          error={
            errorMessage
              ? [errorMessage[0].length > 0, errorMessage[1].length > 0]
              : [false, false]
          }
        />
      );
    case EXCEL_TEMPLATE_INPUT.CHECKBOX:
      return (
        <FormControl>
          <InputLabel>{info.name}</InputLabel>
          <RadioGroup
            value={formValue ?? ""}
            row
            onChange={(_, value) => {
              setFormValue(value);
              handleChange(value);
            }}
          >
            <FormControlLabel value={""} label="指定なし" control={<Radio />} />
            <FormControlLabel
              value={true}
              label="チェックあり"
              control={<Radio />}
            />
            <FormControlLabel
              value={false}
              label="チェックなし"
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      );
    case EXCEL_TEMPLATE_INPUT.RADIO:
      return (
        <FormControl>
          <InputLabel>{info.name}</InputLabel>
          <Stack
            direction={{ xs: "column", md: "row" }}
            sx={{ gap: 1 }}
            spacing={0}
          >
            {info.radio?.map((item, index) => {
              return (
                <CheckboxLabel
                  label={item?.label}
                  checked={formValue?.indexOf(index) >= 0}
                  onChange={(_, checked) => {
                    const cloneFormValue: Array<number> = cloneDeep(formValue);
                    const key = cloneFormValue.indexOf(index);
                    // チェック済み項目のIndexを配列に追加
                    if (checked) {
                      if (key < 0) {
                        cloneFormValue.push(index);
                      }
                    } else {
                      if (key >= 0) {
                        cloneFormValue.splice(key, 1);
                      }
                    }
                    setFormValue([...cloneFormValue].sort());
                    handleChange([...cloneFormValue].sort());
                  }}
                  key={coordinate + index}
                />
              );
            })}
          </Stack>
        </FormControl>
      );

    case EXCEL_TEMPLATE_INPUT.INPUT_GROUP:
      return (
        <AccordionSection title={info.name}>
          <Stack>
            {info.group_info?.map((item, index) => {
              return (
                <SearchInput
                  info={item}
                  stateForm={stateForm}
                  coordinate={coordinate + "@" + item.coordinate}
                  onChange={onChange}
                  setFormError={setFormError}
                  key={index}
                />
              );
            })}
          </Stack>
        </AccordionSection>
      );
    default:
      return null;
  }
};

export default ExcelTemplateDetail;
