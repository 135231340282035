import { TYPES } from "store/types";

export type contract = {
    activity_base_id: string;
};

const initialState: contract = {
    activity_base_id: "",
};

export const activityBaseIdReducer = (
    state = initialState,
    { payload, type }: any,
) => {
    switch (type) {
        case TYPES.SET_ACTIVITY_BASE_ID: {
            return { activity_base_id: payload };
        }
        default:
            return state;
    }
};