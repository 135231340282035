import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { createConnector, METHOD_API } from "@api/Connector";
import { clearData, STORAGE, storeData } from "@utils/Storage";
import ModalController from "@shared-components/modal/ModalController";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { TYPES } from "store/types";
import { createFormUpload } from "@utils/index";
import messages from "config/messages";
/**
*
* @param action
*/
export function* setContractInfoSaga(action: any): any {
    LoadingOverlayController.show();
    const { ...props } = action.payload;

    try {
        let formData = createFormUpload(null, props);
        // 契約決定
        const res: any = yield call(
            createConnector,
            METHOD_API.POST,
            `api/v1/contracts/${encodeURIComponent(props.contract_login_id)}`,
            formData,
        );
        if (res && res.exists_contract) {
            //契約ログインIDを保存
            yield call(
                storeData,
                STORAGE.CONTRACT_INFO,
                JSON.stringify({
                    contract_login_id: props.contract_login_id,
                    contract_name: res.contract_name,
                    contract_password: props.contract_password,
                    contract_id: res.contract_id
                }),
            );
            yield put({
                type: TYPES.SET_CONTRACT_INFO,
                payload: {
                    is_contract_logged_in: true,
                },
            });
        } else if (res && res.error_message && res.error_message.length) {
            ModalController.show({
                message: res.error_message,
                visibleButton2: true,
            });
        }
    } catch (error: any) {
        console.log(error);
        ModalController.show({
            message: error.detail,
            visibleButton2: true,
        });
        // 契約ログイン状況を変更
        yield put({ type: TYPES.CLEAR_CONTRACT_INFO });
        yield call(clearData, STORAGE.CONTRACT_INFO);
    } finally {
        LoadingOverlayController.hide();
    }
}

export function* clearContractInfoSaga() {
    LoadingOverlayController.show();
    try {
        // 保存情報を削除
        yield put({ type: TYPES.CLEAR_CONTRACT_INFO });
        yield call(clearData, STORAGE.CONTRACT_INFO);

        // ログイン状況を変更
        yield put({ type: TYPES.SET_LOGGEDIN, payload: { loggedIn: false, group_id: "" } });
    } catch (error) {
        console.log(error);
    } finally {
        LoadingOverlayController.hide();
    }
}