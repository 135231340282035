import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Grid, MenuItem } from "@mui/material";
import SelectLabel from "components/atoms/SelectLabel";
import { Box, CircularProgress } from "@mui/material";
import { TEMPLATE_SORT_TYPE, TEMPLATE_TYPE } from "@shared-constants";
import {
  apiDeleteFlow,
  apiGetAprrovalList,
  apiGetListTemplate,
} from "@api/flowApproval";
import { Colors } from "@template/style";
import { useDispatch, useSelector } from "react-redux";
import { TYPES } from "store/types";
import "../../ManageScreen.css";
import ModalController from "@shared-components/modal/ModalController";
import { RootState } from "store/reducer";
import { ApproveState } from "store/reducer/ApproveFlowReducer";
import { cloneDeep } from "lodash";
import LoadingOverlayController from "@shared-components/loading/LoadingOverlayController";
import { stepDetailDefault } from "./FlowStepArea";

const defaultApprovalFlow: Type.FlowInfo = {
  template_name: "",
  updated_at: "",
  flow_id: "",
  created_at: "",
  template_id: "",
  flow_name: "",
  SK: "",
  PK: "",
  location_id: "",
  is_used_daily_application: true,
  is_used_final_application: true,
};

const defaultTemplateInfo: Type.TemplateInfo = {
  created_at: "",
  flow_name: "",
  template_size: "",
  template_path: "",
  template_image: "",
  order: 1,
  template_preview_path: "",
  template_image_thumb: "",
  updated_at: "",
  flow_id: "",
  template_name: "",
  screen_id: "",
  template_id: "",
  SK: "",
  PK: "",
  template_type: "",
};

export const SelectTargetFolowArea: React.FC = () => {
  const dispatch = useDispatch();
  const approvalFlowState: ApproveState = useSelector(
    (state: RootState) => state.approveFlow,
  );
  const { onClickDelete, flowInfo, templateInfo, apiLoading, newFlowSK } =
    approvalFlowState;
  const [templateList, setListTemplateList] = useState<Type.TemplateInfo[]>([]);
  const [approvalFlowList, setApprovalFlowList] = useState<Type.FlowInfo[]>([]);
  const [selectedApprovalFlow, setSelectedApprovalFlow] =
    useState<Type.FlowInfo>(cloneDeep(defaultApprovalFlow));
  const [selectedTemplate, setSelectedTemplate] = useState<Type.TemplateInfo>(
    cloneDeep(defaultTemplateInfo),
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handlingGetDataForStates = async () => {
    let params: Type.ParamApiGetTemplate = {
      template_type: TEMPLATE_TYPE.TEMPLATE_WORK,
      sort_type: TEMPLATE_SORT_TYPE.nameSort,
      filter_condition: "",
    };
    try {
      setIsLoading(true);
      dispatch({ type: TYPES.SET_API_IS_LOADING, payload: true });
      const templateRes: Type.ResponseGetListTemplateApi =
        await apiGetListTemplate(params);
      if (templateRes && templateRes.data.length > 0) {
        setListTemplateList(templateRes.data);
        const theFirstTemplate = templateRes.data[0];
        setSelectedTemplate(theFirstTemplate);
        dispatch({
          type: TYPES.SET_TEMPLATE,
          payload: theFirstTemplate,
        });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        ModalController.show({
          message: error.message || "内部サーバーエラー",
          visibleButton2: true,
        });
      }
    } finally {
      dispatch({ type: TYPES.SET_API_IS_LOADING, payload: false });
      setIsLoading(false);
    }
  };

  const handleResetApprovalFlow = () => {
    dispatch({
      type: TYPES.SET_RESET,
    });
  };

  useEffect(() => {
    if (isLoading || !templateList || templateList.length === 0) {
      LoadingOverlayController.show();
    } else {
      LoadingOverlayController.hide();
    }
  }, [isLoading, templateList])

  useEffect(() => {
    handlingGetDataForStates();
  }, []);

  const handlingSetSelectedApprovalFlow = (item: Type.FlowInfo) => {
    setSelectedApprovalFlow(item);
    dispatch({ type: TYPES.SET_APPROVAL_FLOW, payload: item });
    handleResetApprovalFlow();
  };

  const handlingDeleteFlow = useCallback(async () => {
    if (onClickDelete) {
      try {
        setIsLoading(true);
        await apiDeleteFlow({ sk_flow: flowInfo.SK });
      } catch (error: unknown) {
        if (error instanceof Error) {
          ModalController.show({
            message: error.message || "内部サーバーエラー",
            visibleButton2: true,
          });
        }
      } finally {
        dispatch({ type: TYPES.SET_TEMPLATE, payload: { ...templateInfo } });
        setIsLoading(false);
      }
    }
  }, [onClickDelete]);

  useEffect(() => {
    handlingDeleteFlow();
  }, [handlingDeleteFlow]);

  const renderOptionListTemplate = useMemo(() => {
    return templateList.map((item) => {
      return (
        <MenuItem
          value={item.SK}
          key={item.SK}
          className="IgnoreExtractRuleTarget"
        >
          {item.template_name}
        </MenuItem>
      );
    });
  }, [templateList]);

  const renderOptionListFlow = useMemo(() => {
    return approvalFlowList.map((item) => {
      return (
        <MenuItem value={item.flow_id} key={item.flow_id}>
          <span className="IgnoreExtractRuleTarget">{item.flow_name}</span>
        </MenuItem>
      );
    });
  }, [approvalFlowList]);

  const handlingSelectTemplate = (event: any) => {
    const templateId = event.target.value;
    templateList.forEach((item) => {
      if (item.SK === templateId) {
        setSelectedTemplate(item);
        dispatch({
          type: TYPES.SET_TEMPLATE,
          payload: item,
        });
        dispatch({
          type: TYPES.SET_STEP_LIST,
          payload: [],
        });
      }
    });
  };

  const handlingSelectFlow = (event: any) => {
    const aprrovalFlowId = event.target.value;
    approvalFlowList.forEach((item) => {
      if (item.flow_id === aprrovalFlowId) {
        handlingSetSelectedApprovalFlow(item);
      }
    });
  };

  useEffect(() => {
    if (approvalFlowList.length === 0) {
      handlingSetSelectedApprovalFlow(cloneDeep(defaultApprovalFlow));
    } else {
      const targetSelectedFlow = approvalFlowList.find(
        (item) => item.SK === newFlowSK,
      );
      if (targetSelectedFlow) {
        handlingSetSelectedApprovalFlow(cloneDeep(targetSelectedFlow));
      } else {
        handlingSetSelectedApprovalFlow(cloneDeep(approvalFlowList[0]));
      }
    }
  }, [approvalFlowList]);

  const getFlowList = useCallback(async () => {
    if (templateInfo.SK) {
      try {
        setIsLoading(true);
        const template_id = templateInfo.SK;
        dispatch({ type: TYPES.SET_API_IS_LOADING, payload: true });
        let flow_type = "";
        const approvalFlows = await apiGetAprrovalList({
          template_id,
          flow_type,
        });
        dispatch({
          type: TYPES.SET_FLOW_LIST,
          payload: approvalFlows,
        });
        if (approvalFlows.length > 0) {
          setApprovalFlowList(approvalFlows);
        } else {
          setApprovalFlowList([cloneDeep(defaultApprovalFlow)]);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          ModalController.show({
            message: error.message || "内部サーバーエラー",
            visibleButton2: true,
          });
        }
      } finally {
        setIsLoading(false);
        dispatch({ type: TYPES.SET_API_IS_LOADING, payload: false });
      }
    }
  }, [templateInfo]);

  const renderSelectApprovalFlow = useMemo(() => {
    return (
      <SelectLabel
        label="承認フロー"
        sx={[styles.selectLabel, { mr: 2 }]}
        value={selectedApprovalFlow.flow_id}
        isIgnoreExtractRuleLabel={false}
        isIgnoreExtractRuleSelect={true}
        onChange={handlingSelectFlow}
      >
        {renderOptionListFlow}
      </SelectLabel>
    );
  }, [selectedApprovalFlow]);

  const renderSelectTemplate = useMemo(() => {
    return (
      <SelectLabel
        label="テンプレート"
        sx={[styles.selectLabel, { mr: 2 }]}
        value={selectedTemplate.SK}
        isIgnoreExtractRuleLabel={false}
        isIgnoreExtractRuleSelect={true}
        onChange={handlingSelectTemplate}
      >
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          renderOptionListTemplate
        )}
      </SelectLabel>
    );
  }, [selectedTemplate, isLoading]);

  useEffect(() => {
    getFlowList();
  }, [getFlowList]);

  const handlingCreateNewFlow = () => {
    if (!apiLoading) {
      dispatch({
        type: TYPES.SET_ON_CLICK_CREAT_NEW_FLOW,
        payload: cloneDeep(defaultApprovalFlow),
      });
      dispatch({
        type: TYPES.SET_STEP_LIST,
        payload: [cloneDeep(stepDetailDefault)],
      });
      setSelectedApprovalFlow(cloneDeep(defaultApprovalFlow));
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        {renderSelectTemplate}
      </Grid>
      <Grid item xs={4}>
        {renderSelectApprovalFlow}
      </Grid>
      <Grid sx={{ alignItems: "end", display: "flex" }} item xs={4}>
        <Button sx={styles.searchBtnCustom} onClick={handlingCreateNewFlow}>
          新規作成
        </Button>
      </Grid>
    </Grid>
  );
};

const styles = {
  card: { mb: 2 },
  title: { mt: 2, mb: 1 },
  selectLabel: { width: "100% !important" },
  button: { width: 150, ml: 1, height: 56 },
  formContent: {
    my: 2,
    mr: 2,
    p: 2,
    border: 2,
    borderRadius: 1,
    borderColor: Colors.MAIN_GREEN,
    position: "relative",
  },
  clearButton: { position: "absolute", right: 0, top: 0 },
  clearIcon: {
    color: "#fff",
    backgroundColor: Colors.TEXT,
    borderRadius: 50,
  },
  searchBtnCustom: {
    marginBottom: "10px",
  },
} as const;
